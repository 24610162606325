import { IFeatureToggles } from "redux/modules/setup/setup.types";
import { ISettings } from "redux/application.types";

const getIfDefined = (variable: boolean | undefined, defaultValue: boolean) =>
    typeof variable === "undefined" ? defaultValue : variable;

const EMAIL_ALLOWED_TIME_DEFAULT = 30;

/**
 * Filter ISettings to get app feature toggles only providing default values
 */
export const getFeaturesFromSettings = (settings: ISettings): IFeatureToggles => {
    const {
        blockedQueryStateEnabled,
        callFunctionalityEnabled,
        chatFunctionalityEnabled,
        chatSkillsEnabled,
        departmentRestrictedBrandsEnabled,
        departmentRestrictedSetupsEnabled,
        departmentRestrictedTransfersEnabled,
        emailAllowedTimeMinutes,
        emailFunctionalityEnabled,
        emailSkillsEnabled,
        proactiveChatEnabled,
    } = settings;
    return {
        blockedQueryStateEnabled: getIfDefined(blockedQueryStateEnabled, true),
        callFunctionalityEnabled: getIfDefined(callFunctionalityEnabled, true),
        chatFunctionalityEnabled: getIfDefined(chatFunctionalityEnabled, true),
        chatSkillsEnabled: getIfDefined(chatSkillsEnabled, true),
        departmentRestrictedBrandsEnabled: getIfDefined(departmentRestrictedBrandsEnabled, false),
        departmentRestrictedSetupsEnabled: getIfDefined(departmentRestrictedSetupsEnabled, false),
        departmentRestrictedTransfersEnabled: getIfDefined(departmentRestrictedTransfersEnabled, true),
        emailAllowedTimeMinutes:
            typeof emailAllowedTimeMinutes === "undefined" ? EMAIL_ALLOWED_TIME_DEFAULT : emailAllowedTimeMinutes,
        emailFunctionalityEnabled: getIfDefined(emailFunctionalityEnabled, true),
        emailSkillsEnabled: getIfDefined(emailSkillsEnabled, true),
        proactiveChatEnabled: getIfDefined(proactiveChatEnabled, true),
    };
};
