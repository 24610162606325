import { IChatLocation, Url } from "@edgetier/types";
import { AxiosError } from "axios";
import useSimpleQuery from "queries/use-simple-query";
import { UseQueryOptions } from "react-query";

/**
 * A query to get a chat's location from the backend.
 * @param chatId  The ID of the chat.
 * @param options An optional react query configuration object.
 */
export const useChatLocation = (chatId: number, options: UseQueryOptions<IChatLocation, AxiosError> = {}) => {
    const url = Url.ChatLocation.replace(":chatId", chatId.toString());
    return useSimpleQuery<IChatLocation>(url, options);
};
