import * as Yup from "yup";
import Axios, { AxiosRequestConfig } from "axios";
import axios from "utilities/axios";
import InputError from "constants/input-error";
import NoteField from "components-for/notes/note-field";
import SelectSkill from "components/select-skill";
import SubmitFormKeyboardShortcut from "components-for/shortcuts/submit-form-keyboard-shortcut";
import urlJoin from "url-join";
import useCreateNote from "components-for/notes/use-create-note";
import useSkills from "utilities/use-skills";
import { Button } from "@edgetier/components";
import { doNothing, keyById } from "@edgetier/utilities";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { FieldError } from "@edgetier/components";
import { Form, Formik } from "formik";
import { Fragment, FunctionComponent, useEffect, useMemo, useRef } from "react";
import { getFeatureToggles } from "redux/modules/setup/setup-selectors";
import { IApplicationState } from "redux/types";
import { IFormValues, IProps } from "./change-skills.types";
import { IShowServerError } from "shared/modal/server-error-modal";
import { KeyboardShortcutScope } from "services/keyboard-shortcut-service";
import { loadingBlockerOperations } from "redux/modules/loading-blocker";
import { ServerErrorModal } from "shared/modal";
import { toastOperations } from "redux/modules/toast";
import { UNCHANGED_ERROR } from "./change-skills.constant";
import { Url } from "@edgetier/types";
import { useDispatch, useSelector } from "react-redux";

/**
 * The backend assigns skills to emails and chats. It's possible it may get this wrong so agents have the ability to
 * change it if so. This component used to allow agents to assign multiple skills but now it only allows one.
 * @param props.interactionDetailId Chat or email identifier.
 * @param props.onChange            A function that will run after the skills change.
 * @param props.queryId             The ID of the query that the interaction is a part of.
 * @param props.skillsIds           The potential skills
 */
const ChangeSkills: FunctionComponent<IProps> = ({ interactionDetailId, onChange, queryId, skillIds }) => {
    const cancelTokenSource = useRef(Axios.CancelToken.source());
    useEffect(() => cancelTokenSource.current.cancel, []);
    const dispatch = useDispatch();

    const { departmentRestrictedTransfersEnabled } = useSelector(({ setup }: IApplicationState) =>
        getFeatureToggles(setup)
    );

    const { mutateAsync: createNote } = useCreateNote(
        { interactionDetailId, queryId },
        // Override default toast error because the onSubmit will show any errors in a modal.
        { onError: doNothing }
    );

    const { data: skills } = useSkills({ unrestricted: departmentRestrictedTransfersEnabled });

    const onSubmit = async (showServerError: IShowServerError, { note, skillId }: IFormValues) => {
        dispatch(loadingBlockerOperations.showLoadingBlocker(true));
        try {
            // Create the note if one was made.
            if (note.trim().length > 0) await createNote(note);

            // PUT request with the new skill ID.
            const skillIds = [skillId];
            const configuration: AxiosRequestConfig = { cancelToken: cancelTokenSource.current.token };
            const url = urlJoin(Url.Interactions, interactionDetailId.toString());
            const { data } = await axios.put<{ keepInteraction: boolean }>(
                url,
                { canReturnToQueue: true, skillIds },
                configuration
            );

            // Pass the skills back to the parent component.
            const skillLookup = keyById(skills ?? [], ({ skillId }) => skillId);
            const newSkills = skillIds.map((skillId) => skillLookup[skillId]);
            onChange(newSkills, data.keepInteraction);
            dispatch(toastOperations.showSuccessToast("Success", "Skill has been updated."));
        } catch (serverError) {
            if (Axios.isAxiosError(serverError)) {
                showServerError(serverError);
            }
        } finally {
            dispatch(loadingBlockerOperations.hideLoadingBlocker(true));
        }
    };

    const skillId = useMemo(() => skillIds[0], [skillIds]);

    return (
        <Fragment>
            <p>
                If the skill below is incorrect, please reassign it and the query will be passed to an agent with the
                appropriate skills setup.
            </p>

            <ServerErrorModal>
                {(showServerError) => (
                    <Formik<IFormValues>
                        initialValues={{ note: "", skillId }}
                        onSubmit={onSubmit.bind(null, showServerError)}
                        validationSchema={Yup.object({
                            note: Yup.string().trim(),
                            skillId: Yup.number()
                                .transform((value) => (isNaN(value) ? undefined : value))
                                .required(InputError.Required)
                                .test("changed", UNCHANGED_ERROR, (value: number) => skillId !== value),
                        })}
                    >
                        <Form>
                            <div className="field">
                                <label htmlFor="skillId">Skill</label>
                                <SelectSkill
                                    isSingleSelect
                                    name="skillId"
                                    parameters={{ unrestricted: departmentRestrictedTransfersEnabled }}
                                />
                                <FieldError name="skillId" />
                            </div>

                            <NoteField placeHolder="Reason for changing skill or details about the interaction&hellip;" />

                            <SubmitFormKeyboardShortcut scope={KeyboardShortcutScope.Modal} setScopeWhileActive />
                            <Button icon={faCheck} styleName="positive" type="submit">
                                Change Skill
                            </Button>
                        </Form>
                    </Formik>
                )}
            </ServerErrorModal>
        </Fragment>
    );
};

export default ChangeSkills;
