import { DEFAULT_LANGUAGE_ID } from "@edgetier/types";

import { IProps } from "./field-translation.types";

/**
 * Get the translation for a label or placeholder for a field.
 * @param props.languageId        Customer's language.
 * @param props.translationLookup Label lookup by language ID.
 * @returns                       Customer's or default translation.
 */
const FieldTranslation = ({ children, languageId, translationLookup }: IProps) =>
    children(
        languageId in translationLookup
            ? translationLookup[languageId]
            : DEFAULT_LANGUAGE_ID in translationLookup
            ? translationLookup[DEFAULT_LANGUAGE_ID]
            : Object.values(translationLookup)[0]
    );

export default FieldTranslation;
