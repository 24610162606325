import { FunctionComponent, memo } from "react";
import { IProps } from "./location-details.types";
import { DetailsFieldTitle, DetailsFieldValue, DetailsSection, DetailsSectionTitle } from "@edgetier/client-components";
import { useChatLocation } from "./location-details.utilitites";
import "./location-details.scss";

/**
 * Display details about the location of the customer during the chat.
 * @param props.chatId The ID of the chat.
 */
const LocationDetails: FunctionComponent<IProps> = ({ chatId }) => {
    const { data, isLoading, isError } = useChatLocation(chatId);
    return (
        <DetailsSection>
            <DetailsSectionTitle title="Location" />
            <table>
                <tbody>
                    <tr>
                        <td>
                            <DetailsFieldTitle>Country</DetailsFieldTitle>
                        </td>
                        <td>
                            <DetailsFieldValue
                                isNull={typeof data?.country !== "string"}
                                isLoading={isLoading}
                                isError={isError}
                            >
                                {data?.country}
                            </DetailsFieldValue>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <DetailsFieldTitle>Region</DetailsFieldTitle>
                        </td>
                        <td>
                            <DetailsFieldValue
                                isLoading={isLoading}
                                isError={isError}
                                isNull={typeof data?.countrySubdivision !== "string"}
                            >
                                {data?.countrySubdivision}
                            </DetailsFieldValue>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <DetailsFieldTitle>City</DetailsFieldTitle>
                        </td>
                        <td>
                            <DetailsFieldValue
                                isLoading={isLoading}
                                isNull={typeof data?.city !== "string"}
                                isError={isError}
                            >
                                {data?.city}
                            </DetailsFieldValue>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <DetailsFieldTitle>Latitude &amp; Longitude</DetailsFieldTitle>
                        </td>
                        <td className="location-details__maps-link">
                            <DetailsFieldValue
                                isLoading={isLoading}
                                isError={isError}
                                isNull={typeof data?.latitude !== "number" || typeof data?.longitude !== "number"}
                            >
                                <a
                                    href={`https://www.google.com/maps/search/?api=1&query=${data?.latitude},${data?.longitude}`}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {`${data?.latitude},${data?.longitude}`}
                                </a>
                            </DetailsFieldValue>
                        </td>
                    </tr>
                </tbody>
            </table>
        </DetailsSection>
    );
};

export default memo(LocationDetails);
