import React from "react";
import { ampersandList, LoadingSkeleton } from "@edgetier/client-components";
import { IProps } from "./select-preview.types";
import { LOADING_SKELETON_TEST_ID, MAXIMUM_TOOLTIP_ITEMS } from "./select-preview.constants";
import { memo, useRef } from "react";
import { Tooltip } from "@edgetier/components";
import { useIsOverflowed } from "@edgetier/utilities";
import "./select-preview.scss";

/**
 * Summarise the selected items for when the component is closed. A tooltip is displayed
 * @param props.description                       Description of the items in the select list.
 * @param props.getLabel                          Method to get an item's label.
 * @param props.getValue                          Method to get an item's value.
 * @param props.isFilter                          If the select is being used as a filter.
 * @param props.isLoading                         If the data is loading.
 * @param props.isSingleSelect                    If the select is single select is not.
 * @param props.items                             Array of items to select.
 * @param props.previewPlaceholder                Custom content passed to select as placeholder.
 * @param props.selectedItems                     Currently selected items.
 * @param props.useAllItemsSelectedPreviewMessage Where the select preview should say "All items selected" in preview.
 * @returns                         Text summary.
 */
const SelectPreview = <IItem extends {}, IValue extends {}>({
    description,
    isEmptyLabel,
    getLabel,
    getValue,
    isFilter = true,
    isLoading,
    isSingleSelect,
    items,
    previewPlaceholder,
    selectedItems,
    useAllItemsSelectedPreviewMessage = true,
}: IProps<IItem, IValue>) => {
    const ref = useRef<HTMLDivElement>(null);
    const isOverflowed = useIsOverflowed(ref);
    const selectedCount = selectedItems.length;
    const allSelected = useAllItemsSelectedPreviewMessage && selectedCount === items.length;
    const isEmpty = selectedCount === 0;

    return (
        <Tooltip
            content={
                <div className="select-preview__tooltip">
                    {selectedItems.slice(0, MAXIMUM_TOOLTIP_ITEMS).map((item) => (
                        <div key={String(getValue(item))}>{getLabel(item)}</div>
                    ))}

                    {selectedItems.length > MAXIMUM_TOOLTIP_ITEMS && (
                        <div>
                            and {selectedItems.length - MAXIMUM_TOOLTIP_ITEMS} more {description}(s)&hellip;
                        </div>
                    )}
                </div>
            }
            delayEnter={250}
            disableTooltip={!isOverflowed || isEmpty}
            useArrow
        >
            <div className="select-preview" ref={ref}>
                {typeof previewPlaceholder !== "undefined" ? (
                    <>{previewPlaceholder}</>
                ) : (
                    <>
                        {isEmpty && (isSingleSelect || !isFilter) ? (
                            <>{typeof isEmptyLabel === "undefined" ? `No ${description} selected` : isEmptyLabel}</>
                        ) : !isSingleSelect && (isEmpty || (!isLoading && allSelected)) ? (
                            <>
                                All {description}
                                {description[description.length - 1] !== "s" && "s"}
                            </>
                        ) : isLoading ? (
                            <LoadingSkeleton width={50} containerTestId={LOADING_SKELETON_TEST_ID} />
                        ) : (
                            <>{ampersandList(selectedItems.map(getLabel))}</>
                        )}
                    </>
                )}
            </div>
        </Tooltip>
    );
};

export default memo(SelectPreview) as typeof SelectPreview;
