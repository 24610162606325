import { ITranslation, ITranslations } from "@edgetier/types";

// Translation to return when none are available.
export const DEFAULT_TRANSLATION = {
    fromLanguageId: -1,
    isEdited: false,
    isFirst: false,
    isOriginal: false,
    toLanguageId: -1,
    translation: "Unknown",
    translationId: null,
};

/**
 * Find a translation for a given language or, if missing, take the first one.
 * @param props.translations Dictionary of translations.
 * @param props.languageId   The translation language to find.
 * @returns                  ITranslation object for the selected language.
 */
export function getTranslation(translations: ITranslations, languageId: number): ITranslation {
    return languageId in translations
        ? translations[languageId]
        : Object.keys(translations).length > 0
        ? Object.values(translations)[0]
        : DEFAULT_TRANSLATION;
}
