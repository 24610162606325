import compareAsc from "date-fns/compareAsc";
import { FunctionComponent, useState, useRef, useMemo, useCallback, memo, useEffect } from "react";

import InteractionEvents from "components/interaction-events";
import getInteractionEventDateTime from "utilities/get-interaction-event-date-time";
import { ServerErrorModal, ErrorModal } from "shared/modal";

import ChatEvent from "./chat-event";
import ChatEventsFilters from "./chat-events-filters";
import { IProps } from "./chat-events.types";
import { filterEvents } from "./chat-events.utilities";
import "./chat-events.scss";

/**
 * List all events that happened in order in a chat. The user can either see just the messages, or all events, using
 * radio buttons to switch.
 * @param props.events Chat events.
 * @param props.preferredLanguageId Default language to show if there are multiple translations available.
 * @param props.secondaryLanguageId Alternative translation available, if any.
 * @returns                         Timeline of all chat events or just messages.
 */
const ChatEvents: FunctionComponent<IProps> = ({
    events,
    interactionUrl,
    isChatCompleted,
    preferredLanguageId,
    secondaryLanguageId,
}) => {
    const [showAllEvents, setShowAllEvents] = useState(false);
    const listRef = useRef<HTMLDivElement>(null);

    // Sort events by date just in case they aren't.
    const sortedEvents = useMemo(
        () =>
            events
                .slice()
                .sort((eventOne, eventTwo) =>
                    compareAsc(getInteractionEventDateTime(eventOne), getInteractionEventDateTime(eventTwo))
                ),
        [events]
    );

    const visibleEvents = useMemo(() => filterEvents(sortedEvents, showAllEvents), [showAllEvents, sortedEvents]);

    // Scroll to the bottom when a new message arrives.
    useEffect(() => {
        if (listRef.current !== null) {
            if (typeof listRef.current.scroll === "function") {
                listRef.current.scroll({ top: listRef.current.scrollHeight });
            } else {
                listRef.current.scrollTop = listRef.current.scrollHeight;
            }
        }
    }, [visibleEvents]);

    /**
     * Show all events or just messages and reset the scroll position to the top.
     * @param allEvents Desired state.
     */
    const toggleAllEvents = useCallback((allEvents: boolean) => {
        setShowAllEvents(allEvents);
        if (listRef.current) {
            listRef.current.scrollTop = 0;
        }
    }, []);

    return (
        <div className="chat-events">
            <ServerErrorModal>
                {(showServerError) => (
                    <ErrorModal>
                        {(showError) => (
                            <InteractionEvents ref={listRef} showTimeline={showAllEvents}>
                                {visibleEvents.map((event) => (
                                    <ChatEvent
                                        event={event}
                                        interactionUrl={interactionUrl}
                                        isChatCompleted={isChatCompleted}
                                        key={`${event.timestamp}-${event.chatEventType}`}
                                        onServerError={showServerError}
                                        preferredLanguageId={preferredLanguageId}
                                        secondaryLanguageId={secondaryLanguageId}
                                        showError={showError}
                                    />
                                ))}
                            </InteractionEvents>
                        )}
                    </ErrorModal>
                )}
            </ServerErrorModal>

            <ChatEventsFilters allEvents={showAllEvents} onSubmit={toggleAllEvents} />
        </div>
    );
};

export default memo(ChatEvents);
