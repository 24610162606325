import LoadingBlockerType from "./loading-blocker-type";

const createAction = <T extends { type: LoadingBlockerType }>(action: T) => action;

/**
 * Hide the loading blocker.
 * @returns A hide action.
 */
const hideLoadingBlocker = () => createAction({ type: LoadingBlockerType.Hide });

/**
 * Show the loading blocker.
 * @returns A show action.
 */
const showLoadingBlocker = () => createAction({ type: LoadingBlockerType.Show });

export default { hideLoadingBlocker, showLoadingBlocker };
