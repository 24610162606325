import React from "react";

import ModalContext from "../context";
import { IModalContext } from "../context/context.types";

import { IProps } from "./button.types";

/**
 * Button to appear inside a modal. This component just extends a normal button by automatically closing the modal when
 * clicked.
 */
export default class Button extends React.PureComponent<IProps> {
    /**
     * Close the modal and call the click handler.
     * @param hideModal Method to close the modal.
     */
    onClick = (hideModal: IModalContext["hideModal"]): void => {
        hideModal();
        this.props.onClick();
    };

    /**
     * Render a button.
     * @returns Button component.
     */
    render(): React.ReactNode {
        const { children, onClick, ...otherProps } = this.props;
        return (
            <ModalContext.Consumer>
                {({ hideModal }) => (
                    <div {...otherProps} onClick={this.onClick.bind(this, hideModal)}>
                        {children}
                    </div>
                )}
            </ModalContext.Consumer>
        );
    }
}
