import { createContext } from "react";
import { doNothing } from "@edgetier/utilities";

import { IAttachmentsContext } from "./attachments-context.types";

export const defaultContext: IAttachmentsContext = {
    canDelete: false,
    deletedAttachments: [],
    deleteAttachment: doNothing,
    showDeleted: false,
};

const AttachmentsContext = createContext<IAttachmentsContext>(defaultContext);
export default AttachmentsContext;
