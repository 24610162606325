import { IChatState } from "redux/modules/chat/chat.types";

import actions from "./../chat-actions";

/**
 * Update the global chat settings with any changes.
 * @param state  Chat state before changes.
 * @param action Action with a payload of chat root level settings.
 * @returns      Chat State with updated settings values.
 */
export default function (state: IChatState, action: ReturnType<typeof actions.updateChatSettings>): IChatState {
    return {
        ...state,
        ...action.payload,
    };
}
