import { memo, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useFormikContext } from "formik";

import { IProps } from "./form-url-monitor.types";

/**
 * Monitor the URL for any query string changes and update the form accordingly.
 * @param props.getFilterParameters A function that parses the filter parameters from the URL path.
 */
const FormUrlMonitor = <T extends Object>({ getFilterParameters }: IProps<T>) => {
    const { search } = useLocation();
    const { setFieldValue } = useFormikContext<T>();

    useEffect(() => {
        const templateFilterParameters = getFilterParameters(search);
        Object.entries(templateFilterParameters).forEach(([key, value]) => {
            setFieldValue(key, value);
        });
    }, [getFilterParameters, search, setFieldValue]);

    return null;
};

export default memo(FormUrlMonitor);
