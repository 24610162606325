import { ISelectFormComponent, IFormComponent } from "@edgetier/types";

import FormField from "./form-field";

/**
 * Select fields include single/multi-option selects, radio buttons, multiple checkboxes etc.
 */
export default class SelectFormField extends FormField<ISelectFormComponent, number[]> {
    /**
     * decorate configuration with parentFormField name if parentFormFieldId !== null
     */
    static decorateConfiguration(
        configuration: ISelectFormComponent["configuration"],
        formComponents: IFormComponent[]
    ): ISelectFormComponent["configuration"] {
        const { parentFormFieldId, ...rest } = configuration;
        if (parentFormFieldId === null) return configuration;

        const field = formComponents.find((field) => field.formFieldId === parentFormFieldId);
        return field
            ? {
                  ...rest,
                  parentFormFieldId,
                  parentFormField: field.fieldName,
              }
            : configuration;
    }

    /**
     * Clean the value before submitting it.
     * @returns The value.
     */
    formatValue(value: number[]) {
        return !Array.isArray(value) || value.length === 0 ? null : value;
    }

    /**
     * Initialise the field with either a sensible default or a default provided by the backend.
     * @returns Default value if provided or a value of false.
     */
    getDefaultValue() {
        return typeof this.configuration.defaultSelectOptionId === "number"
            ? [this.configuration.defaultSelectOptionId]
            : [];
    }

    /**
     * Check for a blank value. This is used to display a "required" error.
     * @param value Current field value.
     * @returns     True if the value is not blank.
     */
    isNotBlank(value: number[]) {
        return Array.isArray(value) && value.length > 0;
    }

    /**
     * Validate the input. Nothing needs to be done here as if anything is selected, the input is valid.
     * @returns True.
     */
    isValid() {
        return true;
    }
}
