import { createContext } from "react";
import { doNothing } from "@edgetier/utilities";

import { IEllipsisMenuContext } from "./ellipsis-menu-context.types";

export const defaultContext: IEllipsisMenuContext = {
    close: doNothing,
    isLockedOpen: false,
    isOpen: false,
    lockOpen: doNothing,
    unlock: doNothing,
};

const EllipsisMenuContext = createContext(defaultContext);
export default EllipsisMenuContext;
