import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

import InteractionEvent from "components/interaction-events/interaction-event";

import { IProps } from "./unknown-event.types";
import "./unknown-event.scss";

/**
 * Render an event the frontend doesn't know about. This will happen when the backend adds more that don't yet exist on
 * the frontend.
 * @param props.event
 */
const UnknownEvent = ({ event }: IProps) => <InteractionEvent event={event} icon={faQuestionCircle} />;

export default UnknownEvent;
