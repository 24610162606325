import ProactiveChatType from "./proactive-chat-type";
import { IProactiveChatData } from "./proactive-chat.types";

const createAction = <T extends { type: ProactiveChatType }>(action: T) => action;

/**
 * Enable or disable proactive chat
 * @param payload State to set the proactive chat availability.
 * @returns       Toggle proactive chat action.
 */
const toggleProactiveChat = (payload: boolean) =>
    createAction({
        type: ProactiveChatType.Toggle,
        payload,
    });

/**
 * Store the proactive chat
 * @param payload The proactive chat data from the backend.
 * @returns       Store proactive chat data action.
 */
const storeProactiveChatData = (payload: IProactiveChatData) =>
    createAction({ type: ProactiveChatType.Store, payload });

export default { toggleProactiveChat, storeProactiveChatData };
