import { ChatEventType, IChatEvent, IMessage } from "@edgetier/types";

/**
 * Get messages from chat events.
 * @param events Array of chat events.
 * @returns      Chat messages.
 */
export function filterMessages(events: IChatEvent[]) {
    return events.filter((event): event is IMessage => event.chatEventTypeId === ChatEventType.Message);
}
