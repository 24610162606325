import { IEmailAssignment, Url } from "@edgetier/types";
import { AxiosError } from "axios";
import { useQuery, UseQueryOptions } from "react-query";
import { getAllPaginatedItems } from "utilities/get-all-paginated-items";

/**
 * Request email assignments from the backend.
 * @param parameters Parameters to send with the request.
 * @returns An array of email assignments.
 */
export const getEmailAssignments = async (url: string, parameters: Object) => {
    const data = await getAllPaginatedItems<IEmailAssignment>(url, { params: parameters });
    return data;
};

/**
 * A query to get email assignments from the backend.
 * @param parameters The parameters to send with the request.
 * @param options    An optional react query configuration object.
 */
export const useEmailAssignments = <T = IEmailAssignment[]>(
    emailId: number,
    parameters: Object = {},
    options: UseQueryOptions<IEmailAssignment[], AxiosError, T> = {}
) => {
    const url = Url.EmailsAssignments.replace(":emailId", emailId.toString());
    return useQuery<IEmailAssignment[], AxiosError, T>(
        [url, parameters],
        () => getEmailAssignments(url, parameters),
        options
    );
};
