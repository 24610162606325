import { Page, PageContent, PageHeader } from "@edgetier/client-components";
import "./error-fallback.scss";

/**
 * Show the user a message when the app crashes.
 */
const ErrorFallback = () => {
    return (
        <Page>
            <PageHeader title="Error" />
            <PageContent>
                <div className="error-fallback">
                    Unfortunately, Arthur has crashed. Please refresh the page to try again or, if the problem persists,
                    email info+error@edgetier.com.
                </div>
            </PageContent>
        </Page>
    );
};

export default ErrorFallback;
