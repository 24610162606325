import { DetailsFieldValue } from "@edgetier/client-components";

import RawText from "components/raw-text";
import { ToggleTranslationSection } from "shared/toggle-translation";

import BlankField from "../blank-field";

import { IProps } from "./text-field-value.types";
import "./text-field-value.scss";

/**
 * Display the contents of a text or text-like field (emails, textareas etc.). The field could be blank, a string, or a
 * translations object.
 * @param props.children Text or translations object.
 * @returns              Field value.
 */
const TextFieldValue = ({ children }: IProps) => (
    <div className="text-field-value">
        {children === null ? (
            <BlankField />
        ) : typeof children === "string" ? (
            <DetailsFieldValue copyText={children}>
                <RawText>{children}</RawText>
            </DetailsFieldValue>
        ) : (
            <ToggleTranslationSection translations={children}>
                {({ translation }) =>
                    translation === null ? (
                        <BlankField />
                    ) : (
                        <DetailsFieldValue copyText={translation}>
                            <RawText>{translation}</RawText>
                        </DetailsFieldValue>
                    )
                }
            </ToggleTranslationSection>
        )}
    </div>
);

export default TextFieldValue;
