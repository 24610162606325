import Axios, { AxiosRequestConfig } from "axios";
import urlJoin from "url-join";
import { Url } from "@edgetier/types";

import axios from "utilities/axios";

import { IObjectSummary } from "./interaction-decorator.types";

/**
 * Request booking details for one interaction and apply the new data by calling an updater function.
 * @param interaction       An interaction that is missing booking details.
 */
export async function requestAgentInformation<T extends IObjectSummary = IObjectSummary>(
    interaction: T,
    axiosConfig: AxiosRequestConfig,
    updater: (interaction: T, update: object) => void,
    errorHandler: (error: any) => void
): Promise<void> {
    try {
        // Determine which route to use depending on what type ID was passed.
        const url =
            typeof interaction.interactionDetailId === "number"
                ? urlJoin(Url.Interactions, interaction.interactionDetailId.toString(), Url.AgentInformation)
                : urlJoin(Url.BookingDetails, interaction.bookingId!);

        // Request the booking details and text template variables.
        const { data } = await axios.get(url, axiosConfig);
        updater(interaction, {
            bookingDetails: data.bookingDetails,
            textTemplateVariables: data.textTemplateVariables,
        });
    } catch (serverError) {
        errorHandler(serverError);

        if (!Axios.isCancel(serverError) && Axios.isAxiosError(serverError)) {
            // Updating bookingDetails with `null` should be recognised as a non missing value.
            updater(interaction, { bookingDetails: null });
        }
    }
}
