import { IEmailIn, IEmailOut, ICall } from "components/interaction-search/interaction-search.types";
import InteractionType from "constants/interaction-type";
import ConversationType from "constants/conversation-type";

import { InteractionLabels } from "./interaction-label.constants";
import { IProps } from "./interaction-label.types";
import "./interaction-label.scss";

/**
 * Label an interaction search result as either a chat, call, inbound email, or outbound email.
 * @param props Props containing details of an interaction.
 * @returns     Appropriate label.
 */
const InteractionLabel = ({ interaction }: IProps) => {
    /**
     * Get the appropriate label for the interaction depending on its type.
     */
    const getInteractionLabel = () => {
        switch (interaction.interactionTypeId) {
            case InteractionType.Chat:
                return InteractionLabels.Chat;
            case InteractionType.Email:
                return getEmailLabel(interaction);
            case InteractionType.Call:
                return getCallLabel(interaction);
        }
    };

    /**
     * Get the appropriate label depending on if the interaction is an inbound or outbound email.
     * @param interaction An email interaction.
     */
    const getEmailLabel = (interaction: IEmailIn | IEmailOut) => {
        const { EmailIn, EmailOut } = InteractionLabels;
        return "emailId" in interaction ? EmailIn : EmailOut;
    };

    /**
     * Get the appropriate label depending on if the interaction is an inbound, outbound or internal call.
     * @param interaction.conversationTypeId The id for the call conversation type.
     * @param interaction.inbound            A boolean indicating if the call is inbound.
     */
    const getCallLabel = ({ conversationTypeId, inbound }: ICall) => {
        const { CallIn, CallInternal, CallOut } = InteractionLabels;
        if (conversationTypeId === ConversationType.Internal) {
            return CallInternal;
        }
        return inbound ? CallIn : CallOut;
    };

    return <div className="interaction-search__interaction-label">{getInteractionLabel()}</div>;
};

export default InteractionLabel;
