import classNames from "classnames";
import React from "react";
import { faCog } from "@fortawesome/free-solid-svg-icons/faCog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import DropDownMenuProvider from "../drop-down-menu-provider";

import { IProps, IState } from "./drop-down-menu.types";
import "./drop-down-menu.scss";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-solid-svg-icons";

// CSS class added to the element when the menu is open.
export const OPEN_CLASS = "drop-down-menu--open";
export const DISABLED_CLASS = "drop-down-menu--disabled";

/**
 * Drop-down menu that will close when the user clicks outside it.
 */
export default class DropDownMenu extends React.PureComponent<IProps, IState> {
    element = React.createRef<HTMLDivElement>();
    state = { isOpen: false };
    static defaultProps = { disabled: false, icon: faCog, useIcon: true };

    /**
     * Assign a callback for any clicks outside of the component.
     */
    componentDidMount() {
        document.addEventListener("mousedown", this.onClickOutside);
    }

    /**
     * Remove the listener listening for clicks outside of the component.
     */
    componentWillUnmount() {
        document.removeEventListener("mousedown", this.onClickOutside);
    }

    /**
     * Toggle the menu on click.
     */
    onClick = (): void => {
        if (!this.props.disabled) {
            this.setState({ isOpen: !this.state.isOpen }, this.props.onClick);
        }
    };

    /**
     * If the user clicks anywhere outside this component it should close.
     * @param mouseEvent Any click event.
     */
    onClickOutside = (mouseEvent: MouseEvent): void => {
        const element = this.element.current;
        const target = mouseEvent.target;
        if (element === null || !(target instanceof Element)) {
            return;
        }
        this.setState(({ isOpen }) => ({
            isOpen: isOpen && !element.contains(target) ? false : isOpen,
        }));
    };

    /**
     * Render a drop-down containin the children passed into it.
     */
    render(): JSX.Element {
        const className = classNames("drop-down-menu", {
            [OPEN_CLASS]: this.state.isOpen,
            [DISABLED_CLASS]: this.props.disabled,
        });
        return (
            <DropDownMenuProvider isOpen={this.state.isOpen} toggleIsOpen={this.onClick}>
                <div className={className} ref={this.element}>
                    <div onClick={this.onClick}>
                        {this.props.useIcon && <FontAwesomeIcon title="drop down menu icon" icon={this.props.icon} />}
                        <span className="drop-down-menu__icon">
                            <FontAwesomeIcon icon={this.state.isOpen ? faChevronUp : faChevronDown} size="xs" />
                        </span>
                    </div>
                    {this.props.children}
                </div>
            </DropDownMenuProvider>
        );
    }
}
