import { INumericFormComponent } from "@edgetier/types";

import FormField from "./form-field";

/**
 * Numeric fields include NPS ratings, star ratings, typed number inputs etc.
 */
export default class NumericFormField extends FormField<INumericFormComponent, number | string> {
    /**
     * Clean the value before submitting it.
     * @returns The value.
     */
    formatValue(value: number | string) {
        return typeof value !== "number" ? null : value;
    }

    /**
     * Initialise the field with either a sensible default or a default provided by the backend. React requires empty
     * strings rather than null values for empty numeric fields.
     * @returns Default value if provided or an empty string.
     */
    getDefaultValue() {
        return this.configuration.defaultValue === null ? "" : this.configuration.defaultValue;
    }

    /**
     * Ensure that the value does not exceed the maximum.
     * @param value Current field value.
     * @returns     True if the maximum constraint is not defined or if the value is less than it.
     */
    isMaximumValueValid(value: number) {
        const { maximumValue } = this.configuration;
        return typeof maximumValue !== "number" || value <= maximumValue;
    }

    /**
     * Ensure that the value is not below the minimum.
     * @param value Current field value.
     * @returns     True if the minimum constraint is not defined or if the value is more than it.
     */
    isMinimumValueValid(value: number) {
        const { minimumValue } = this.configuration;
        return typeof minimumValue !== "number" || value >= minimumValue;
    }

    /**
     * Check for a blank value. This is used to display a "required" error.
     * @param value Current field value.
     * @returns     True if the value is not blank.
     */
    isNotBlank(value: number | string) {
        return typeof value === "number";
    }

    /**
     * Validate the field's value.
     * @param value Current field value.
     * @returns     True if the field if valid.
     */
    isValid(value: number | string) {
        return typeof value !== "number" || (this.isMinimumValueValid(value) && this.isMaximumValueValid(value));
    }
}
