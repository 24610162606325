import { IChatState } from "redux/modules/chat/chat.types";

import actions from "./../chat-actions";

/**
 * Update the details of an ongoing chat.
 * @param state  Application state before updating a given chat.
 * @param action Action containing a payload of the chat to update.
 * @returns      Application state after updating a given chat.
 */
export default function (state: IChatState, action: ReturnType<typeof actions.updateChat>): IChatState {
    const { data, chatToken } = action.payload;
    const chat = state.chats[chatToken];
    if (typeof chat === "undefined") {
        return state;
    }

    return { ...state, chats: { ...state.chats, [chatToken]: { ...chat, ...data } } };
}
