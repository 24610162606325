import React, { Fragment } from "react";
import { connect } from "react-redux";

import { IApplicationState } from "redux/types";
import { setupSelectors } from "redux/modules/setup";

import { IProps, IStateProps } from "./language-name.types";
import { UNKNOWN_LANGUAGE } from "./language-name.constants";

/**
 * Given a language ID, display it's name.
 * @param props.languageId Language to find.
 * @param props.languages  Array of all languages.
 * @returns                Language name.
 */
export const LanguageName = ({ languageId, languages }: IProps) => {
    const language = (languages || []).find((item) => item.languageId === languageId);
    return <Fragment>{typeof language === "undefined" ? UNKNOWN_LANGUAGE : language.language}</Fragment>;
};

/**
 * @param state Application state.
 * @returns     Component's state props.
 */
export function mapStateToProps({ setup }: IApplicationState): IStateProps {
    return { languages: setupSelectors.getLanguages(setup) };
}

export default connect(mapStateToProps)(LanguageName);
