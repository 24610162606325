import { IChatState } from "redux/modules/chat/chat.types";

import actions from "./../chat-actions";

/**
 * Store a customer URL. Agents need to see what page a customer is on to help them handle a chat. The backend will emit
 * updates as the customer navigates to different pages.
 * @param state  Application state before storing the new URL.
 * @param action Action containing a payload of the new URL details.
 * @returns      Application state before after the new URL.
 */
export default function (state: IChatState, action: ReturnType<typeof actions.storeCustomerUrl>): IChatState {
    const { chatToken, customerUrl } = action.payload;
    const chat = state.chats[chatToken];
    if (typeof chat === "undefined") {
        return state;
    }

    // Append the new customer URL to the array of URLs the customer has already visited.
    const parsedCustomerUrl = { ...customerUrl, dateTime: new Date(customerUrl.dateTime) };
    const customerUrls = [...chat.customerUrls, parsedCustomerUrl];
    const chats = { ...state.chats, [chatToken]: { ...chat, customerUrls } };
    return { ...state, chats };
}
