import { useMemo } from "react";

import formatEnumOptions from "utilities/format-enum-options";
import SplitButton from "components/split-button";

import { IProps, IEnumOption } from "./split-button-enum.types";

/**
 * Display a split button containing any enum values.
 * @param props.options         The enum that will be used to populate the split button.
 * @param props.defaultOption   The option that will be shown in the button.
 * @param props.excludedOptions Options that shouldn't be shown in the dropdown.
 */
const SplitButtonEnum = ({ options, defaultOption, excludedOptions = [], icons, ...otherProps }: IProps) => {
    const formattedOptions = useMemo(() => {
        const formattedOptions = formatEnumOptions(options);
        // If there are icons available for the options then add these to the object of each option.
        return typeof icons === "undefined"
            ? formattedOptions
            : formattedOptions.map((option) => {
                  return { ...option, icon: icons[option.value] };
              });
    }, [options, icons]);

    const getOptionIcon = ({ icon }: IEnumOption) => icon!;

    return (
        <SplitButton<IEnumOption>
            options={formattedOptions.filter(({ value }) => !excludedOptions.includes(value))}
            defaultOption={
                defaultOption ? formattedOptions.find((option) => option.value === defaultOption) : undefined
            }
            getOptionLabel={({ label }) => label}
            getOptionIcon={typeof icons === "undefined" ? undefined : getOptionIcon}
            {...otherProps}
        />
    );
};

export default SplitButtonEnum;
