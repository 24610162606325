import { IFormComponent, ISourcedSelectFormComponent, ISourcedSelectOption } from "@edgetier/types";
import FormField from "./form-field";

/**
 * Sourced select fields are select menus that only show options when the user types in a search.
 */
export default class SourcedSelectFormField extends FormField<ISourcedSelectFormComponent, any> {
    /**
     * decorate configuration with parentFormField name if parentFormFieldId !== null
     */
    static decorateConfiguration(
        configuration: ISourcedSelectFormComponent["configuration"],
        formComponents: IFormComponent[]
    ): ISourcedSelectFormComponent["configuration"] {
        const { parentFormFieldId, ...rest } = configuration;
        if (parentFormFieldId === null) return configuration;

        const field = formComponents.find((field) => field.formFieldId === parentFormFieldId);
        return field
            ? {
                  ...rest,
                  parentFormFieldId,
                  parentFormField: field.fieldName,
              }
            : configuration;
    }

    /**
     * Clean the value before submitting it.
     * @returns The value.
     */
    formatValue(options: ISourcedSelectOption | ISourcedSelectOption[]) {
        const optionsArray = Array.isArray(options) ? options : [options];
        return optionsArray.map(({ value }) => value);
    }

    /**
     * Initialise with an empty array because a sourced select will have no options to being with.
     * @returns Default value if provided or a value of false.
     */
    getDefaultValue() {
        return [];
    }

    /**
     * Check for a blank value. This is used to display a "required" error.
     * @param value Current field value.
     * @returns     True if the value is not blank.
     */
    isNotBlank(value: ISourcedSelectOption | ISourcedSelectOption[] | null) {
        return Array.isArray(value) ? value.length > 0 : value !== null;
    }

    /**
     * Validate the input. Nothing needs to be done here as if anything is selected, the input is valid.
     * @returns True.
     */
    isValid() {
        return true;
    }
}
