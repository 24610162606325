import React from "react";

/**
 * Warning that an agent has a deferred query when trying to sign out.
 * @returns Warning message.
 */
const DeferredQueryWarningSingular = () => (
    <p>You have a deferred query. If you do not complete it, it will eventually be assigned to another agent.</p>
);

export default DeferredQueryWarningSingular;
