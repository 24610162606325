import { createTranslations, unreachableCaseError } from "@edgetier/utilities";

import AgentActionType from "constants/agent-action-type";
import ParagraphType from "constants/paragraph-type";
import convertParagraphs from "utilities/convert-paragraphs";
import { IAction, IParagraph } from "redux/application.types";

/**
 * Format an array of actions suggested by the backend.
 * @param actions Proposed actions.
 * @returns        Formatted actions.
 */
export default function formatProposedActions(actions: IAction[]): IAction[] {
    // Return an array in case the proposed actions are the incorrect format.
    if (!Array.isArray(actions)) {
        return [];
    }

    // Only accept known actions.
    const validActionTypeIds = Object.values(AgentActionType).filter((value) => typeof value === "number");

    return actions
        .filter(({ type }) => validActionTypeIds.includes(type))
        .map((action) => ({ ...action, enabled: true }))
        .map((action) => {
            switch (action.type) {
                case AgentActionType.SendEmail:
                    /* eslint-disable no-case-declarations */
                    // Attachments may not be provided.
                    const attachments = Array.isArray(action.data.attachments) ? action.data.attachments : [];
                    const recipient =
                        Array.isArray(action.data.recipient) && action.data.recipient.length === 0
                            ? null
                            : action.data.recipient;

                    // Future proofing for when the backend formats the proposed actions properly itself.
                    if (!Array.isArray(action.data.body)) {
                        return {
                            ...action,
                            final: { ...(action.data as any), attachments, recipient },
                            proposed: action.data,
                        };
                    }

                    // Extract non-thread paragraphs as the email body.
                    const body = createTranslations(false, {
                        [action.data.agentLanguageId]: convertParagraphs(
                            action.data.body.filter(({ paragraphType }) => paragraphType !== ParagraphType.ThreadText)
                        ),
                    });

                    // Format the subject line's translations.
                    const subject = createTranslations(false, { [action.data.agentLanguageId]: action.data.subject });

                    // Get the thread paragraph if there is a thread.
                    const isThread = ({ paragraphType }: IParagraph) => paragraphType === ParagraphType.ThreadText;
                    const thread = action.data.body.some(isThread)
                        ? action.data.body
                              .filter(isThread)
                              .map(({ text }) => text)
                              .join("\n\n")
                        : null;

                    // Copy the data to another property that can be edited by agents.
                    const final = {
                        ...action.data,
                        attachments,
                        body,
                        recipient,
                        subject,
                        thread,
                    };

                    return { ...action, final, proposed: action.data };
                /* eslint-enable no-case-declarations */

                case AgentActionType.NoActionRequired:
                    return { ...action, final: action.data, proposed: action.data };

                default:
                    throw unreachableCaseError(action);
            }
        });
}
