// Format that the backend may return.
interface IEventResponse<T> {
    readonly data: T;
    readonly error: boolean;
}

/**
 * Wrap Socket IO's emit so it can be used with promises. The backend may return a standard response format to
 * communicate if an error occurred or any data that needs to be returned. At the time of writing this function, not all
 * events do return this format so if an event returns nothing it is also assumed to be successful.
 *
 * @param socket Socket instance.
 * @param name   Event name.
 * @returns      Promise that will resolve when a socket event triggers a callback.
 *
 * @example
 * // Assume a successful event that returns { error: false, data: { chatToken: 'abc' } };
 * try {
 *     const { chatToken } = await emitPromise(socket, 'start_chat');
 * } catch (socketError) {
 *     console.error(socketError);
 * }
 *
 * @example
 * // Assume an unsuccessful event that returns { error: true, data: { message: 'Chat has finished' } };
 * try {
 *     const { chatToken } = await emitPromise(socket, 'start_chat');
 * } catch (socketError) {
 *     // This will log the error message.
 *     console.error(socketError.message);
 * }
 */
export function emitPromise<T>(socket: { emit: Function }, name: string, ...parameters: any[]) {
    return new Promise<T | void>((resolve, reject) => {
        socket.emit(name, ...parameters, function (response: IEventResponse<T> | null | undefined) {
            if (typeof response === "undefined" || response === null || typeof response.error === "undefined") {
                resolve();
            } else {
                return response.error === true ? reject(response.data) : resolve(response.data);
            }
        });
    });
}
