import React from "react";
import { doNothing } from "@edgetier/utilities";

import { IToggleTranslationContext } from "./toggle-translation-context.types";

export const defaultContext: IToggleTranslationContext = {
    languages: [],
    languageId: 0,
    languageIds: [],
    isTranslated: false,
    preferredLanguageId: 0,
    setLanguageId: doNothing,
    setLanguageIds: doNothing,
    showServerError: doNothing,
};

const ToggleTranslationContext = React.createContext<IToggleTranslationContext>(defaultContext);
export default ToggleTranslationContext;
