import {
    endOfDay,
    endOfMonth,
    endOfYear,
    startOfDay,
    startOfMonth,
    startOfToday,
    startOfYear,
    subDays,
    subMonths,
    subWeeks,
    subYears,
} from "date-fns";
import {
    startOfWeekWithOptions,
    endOfWeekWithOptions,
    differenceInCalendarDays,
    differenceInCalendarMonths,
    differenceInCalendarWeeksWithOptions,
} from "date-fns/fp";

import { TimeUnit, IRelativeDate } from "./date-filter.types";

const WEEK_OPTIONS: { weekStartsOn: 1 } = { weekStartsOn: 1 };
const startOfWeek = startOfWeekWithOptions(WEEK_OPTIONS);
const endOfWeek = endOfWeekWithOptions(WEEK_OPTIONS);
const differenceInCalendarWeeks = differenceInCalendarWeeksWithOptions(WEEK_OPTIONS);

const subDateFunctionMap: {
    [key: string]: [
        (date: Date | number, amount: number) => Date,
        (date: Date | number) => Date,
        (date: Date | number) => Date
    ];
} = {
    [TimeUnit.Days]: [subDays, startOfDay, endOfDay],
    [TimeUnit.Weeks]: [subWeeks, startOfWeek, endOfWeek],
    [TimeUnit.Months]: [subMonths, startOfMonth, endOfMonth],
    [TimeUnit.Years]: [subYears, startOfYear, endOfYear],
};

export function applyRelativeDate(date: Date, unit: number, period: TimeUnit, isStart: boolean) {
    if (subDateFunctionMap[period]) {
        const [subFunction, startFunction, endFunction] = subDateFunctionMap[period];

        return subFunction(isStart ? startFunction(date) : endFunction(date), unit);
    }
    return date;
}

/**
 * For END dates only, this helper will either apply the current perriod if unit is 0 (or less)
 * or it will subtract a given number of a given time unit from the provided date.
 * The time will always set to the end of the day.
 * @param   date    A date to apply relative period to, often today
 * @param   unit    any integer or zero
 * @param   period  one of days, weeks, months, years, see TimeUnit enum
 * @return          New date with time at end of day
 */
export function relativeToDate(date: Date, unit: number, period: TimeUnit) {
    return endOfDay(applyRelativeDate(date, unit, period, false));
}

/**
 * For start dates only, this helper will either apply the current perriod if unit is 0 (or less)
 * or it will subtract a given number of a given time unit from the provided date.
 * The time will always set to the start of the day.
 * @param   date    A date to apply relative period to, often today
 * @param   unit    any integer or zero
 * @param   period  one of days, weeks, months, years, see TimeUnit enum
 * @return          New date with time at start of day
 */
export function relativeFromDate(date: Date, unit: number, period: TimeUnit) {
    return startOfDay(applyRelativeDate(date, unit, period, true));
}

/**
 * Generate a full relative dates payload from two relative dates
 * @param   from  The starting relative time period
 * @param   to    The ending relative time period
 * @return        A full relative dates payload
 */
export function makeRelativeDates(from: IRelativeDate, to: IRelativeDate) {
    return {
        from,
        to,
    };
}

/**
 * Generate a single relative date object
 * from a given date, to today using a provided time unit
 * @param   date        A JS Date in the future or past
 * @param   timeUnitId  A Time unit (days, weeks, months, years, See TimeUnit)
 * @param   isStart     used only to set the 'time' property to the beginning or end of the day
 * @return              A relative date, either the start or end of a relative date range
 */
export function makeRelativeDate(date: Date, timeUnitId: IRelativeDate["timeUnitId"], isStart: boolean = true) {
    const TODAY = startOfToday();
    let value = 0;
    switch (timeUnitId) {
        case TimeUnit.Days:
            value = differenceInCalendarDays(date, TODAY);
            break;
        case TimeUnit.Weeks:
            value = differenceInCalendarWeeks(date, TODAY);
            break;
        case TimeUnit.Months:
            value = differenceInCalendarMonths(date, TODAY);
    }

    return {
        value,
        time: isStart ? "00:00" : "23:59",
        timeUnitId,
    };
}

/**
 * Constructs some known relative dates in advance for use as resets, defaults and shortcuts
 * @param   date  Optional date to use as 'today'
 * @return        map of known ranges relative to 'today'
 */
export const makePresets = (date?: Date) => {
    const TODAY = date ?? startOfToday();

    return {
        today: makeRelativeDates(
            makeRelativeDate(startOfDay(TODAY), TimeUnit.Days),
            makeRelativeDate(endOfDay(TODAY), TimeUnit.Days, false)
        ),
        thisWeek: makeRelativeDates(
            makeRelativeDate(startOfWeek(TODAY), TimeUnit.Weeks),
            makeRelativeDate(endOfWeek(TODAY), TimeUnit.Weeks, false)
        ),
        thisMonth: makeRelativeDates(
            makeRelativeDate(startOfMonth(TODAY), TimeUnit.Months),
            makeRelativeDate(endOfMonth(TODAY), TimeUnit.Months, false)
        ),
        thisYear: makeRelativeDates(
            makeRelativeDate(startOfYear(TODAY), TimeUnit.Years),
            makeRelativeDate(endOfYear(TODAY), TimeUnit.Years, false)
        ),
    };
};
