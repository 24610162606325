import { FunctionComponent, memo, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { trimOrNull } from "@edgetier/utilities";
import { SpinnerUntil } from "@edgetier/components";
import { useDispatch } from "react-redux";

import FullscreenQueryHistoryThread from "components-for/query-history/fullscreen-query-history-thread";
import { toastOperations } from "redux/modules/toast";

import { IProps } from "./query-history-thread-page.types";

/**
 * A page for displaying a query history thread based on the url search parameters provided.
 * The URL when this page is rendered should be of the form /query-history?queryId=12&interactionId=34
 */
const QueryHistoryThreadPage: FunctionComponent<IProps> = () => {
    const dispatch = useDispatch();
    const { search: searchParameters } = useLocation();
    const [interactionId, setInteractionId] = useState<string | null>(null);
    const [queryId, setQueryId] = useState<string | null>(null);

    useEffect(() => {
        const urlParameters = new URLSearchParams(searchParameters);
        const queryId = trimOrNull(urlParameters.get("queryId"));
        const interactionId = trimOrNull(urlParameters.get("interactionId"));

        setQueryId(queryId);
        setInteractionId(interactionId);

        if (typeof queryId !== "string" || typeof interactionId !== "string") {
            dispatch(toastOperations.showErrorToast("Invalid URL", "The interaction could not be requested"));
        }
    }, [dispatch, searchParameters]);

    return (
        <SpinnerUntil<{ interactionId: string; queryId: string }>
            data={{ interactionId, queryId }}
            isReady={interactionId !== null && queryId !== null}
        >
            {({ interactionId, queryId }) => (
                <FullscreenQueryHistoryThread interactionId={parseInt(interactionId)} queryId={parseInt(queryId)} />
            )}
        </SpinnerUntil>
    );
};

export default memo(QueryHistoryThreadPage);
