import { AxiosRequestConfig } from "axios";
import urlJoin from "url-join";
import { Url } from "@edgetier/types";

import { QueryState } from "constants/query-state";

import axios from "./axios";

/**
 * Function to send a request to update the queryStateId of a query.
 * @param queryId       The id of the query that is being updated.
 * @param queryStateId  The new query state id that is being applied to the query.
 * @param configuration Axios configuration argument.
 */
const setQueryStateId = async (queryId: number, queryStateId: QueryState, configuration: AxiosRequestConfig = {}) => {
    return axios.put(urlJoin(Url.Query, queryId.toString()), { queryStateId }, configuration);
};

export default setQueryStateId;
