import { IApplicationState } from "redux/types";

/**
 * Determine if a user is currently signed in. This is used to stop unnecessary polling and notifications after an
 * agent has signed out.
 * @param state The application store's state.
 * @returns     Whether or not the user is currently signed into the application.
 */
export default function (state: IApplicationState): boolean {
    return typeof state.authentication.userEmail !== "undefined";
}
