import { IChatState } from "redux/modules/chat/chat.types";

import actions from "./../chat-actions";

/**
 * Activate one of the agent's chats.
 * @param state  Application state before activating a given chat.
 * @param action Action containing a payload of the chat to activate.
 * @returns      Chat state with newly activated chat.
 */
export default function (state: IChatState, { payload }: ReturnType<typeof actions.activateChat>): IChatState {
    const chat = state.chats[payload];
    if (typeof chat === "undefined") {
        return state;
    }
    return {
        ...state,
        activeChat: payload,
        chats: {
            ...state.chats,
            [payload]: { ...chat, unreadMessages: 0 },
        },
    };
}
