import React from "react";
import { Radio, SubmitOnChange } from "@edgetier/components";
import { Formik, Form } from "formik";

import { IProps } from "./chat-events-filters.types";
import "./chat-events-filters.scss";

/**
 * Filter to show either all chat events or just messages.
 * @param props.onSubmit Function to call when changing the selection.
 * @returns              Form with two radio buttons.
 */
const ChatEventsFilter = ({ allEvents, onSubmit }: IProps) => (
    <div className="chat-events-filters">
        <Formik
            initialValues={{ allEvents: String(allEvents) }}
            onSubmit={({ allEvents }) => onSubmit(allEvents === "true")}
        >
            <Form>
                <Radio name="allEvents" option="false" label="Messages only" />
                <Radio name="allEvents" option="true" label="All events" />
                <SubmitOnChange delay={0} />
            </Form>
        </Formik>
    </div>
);

export default React.memo(ChatEventsFilter);
