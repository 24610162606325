import { FunctionComponent, memo } from "react";
import { IProps } from "./user-state-service.types";
import { SpinnerUntil } from "@edgetier/components";
import { useUserStateCheck } from "./use-user-state-check/use-user-state-check";
import { useUserStateUpdate } from "./use-user-state-update/use-user-state-update";

/**
 * A component with some hooks to manage user state. The children of this component won't render until the user state
 * is ready.
 */
const UserStateService: FunctionComponent<IProps> = ({ children }) => {
    useUserStateUpdate();
    const isCheckDone = useUserStateCheck();

    return (
        <SpinnerUntil data={{}} isReady={isCheckDone}>
            <>{children}</>
        </SpinnerUntil>
    );
};

export default memo(UserStateService);
