/**
 * The selected values could be one, none, or multiple items since the component can be a single or multiple item
 * select. This function just normalises the values so they're always an array.
 * @param values Selected values.
 * @returns      Array of selected values.
 *
 * @example
 * // Returns ['a']
 * transformValues(['a']);
 *
 * @example
 * // Returns []
 * transformValues(null);
 *
 * // Returns ['a']
 * transformValues('a');
 */
export const transformValues = <IValue extends {}>(values: IValue | IValue[] | null) => {
    if (Array.isArray(values)) {
        return values;
    } else if (values === null || typeof values === "undefined") {
        return [];
    } else {
        return [values];
    }
};
