import { Url } from "@edgetier/types";
import { AxiosInstance, AxiosRequestConfig } from "axios";
import urlJoin from "url-join";

export const DownloadFormats = {
    text: {
        MIME_TYPE: "text/plain",
        FILE_EXT: "txt",
    },
};
export const format = "text";

export const createDownloadChatTranscript = (axios: AxiosInstance) => async (
    chatIdentifier: string,
    config: AxiosRequestConfig = {}
) => {
    const { MIME_TYPE, FILE_EXT } = DownloadFormats[format];
    const response = await axios.get(urlJoin(Url.Chat, chatIdentifier, Url.ChatTranscript), {
        ...config,
        headers: { Accept: MIME_TYPE },
    });
    // Blob data handling adapted from source at:
    // http://www.alexhadik.com/blog/2016/7/7/l8ztp8kr5lbctf5qns4l8t3646npqh
    const blob = new Blob([response.data], { type: MIME_TYPE });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("style", "display: none;");
    link.download = `support-transcript.${FILE_EXT}`;
    link.href = url;
    document.body.appendChild(link);
    link.click();
    //release the reference to the file by revoking the Object URL
    window.URL.revokeObjectURL(url);
};
