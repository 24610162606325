import hotkeys, { KeyHandler } from "hotkeys-js";

import KeyboardShortcutScope from "./keyboard-shortcut-scope";

type IShortcuts = { [key in KeyboardShortcutScope]: { keys: string }[] };

// Enables hotkeys to be used in input, select and textarea elements.
hotkeys.filter = () => true;

/**
 * Create the object which will keep track of all the active shortcuts.
 */
const createShortcutsObject = (): IShortcuts => {
    const partialResult = Object.values(KeyboardShortcutScope).reduce<Partial<IShortcuts>>(
        (items, scope) => ({
            ...items,
            [scope]: [],
        }),
        {}
    );

    return partialResult as IShortcuts;
};

/**
 * A service to add keyboard shortcuts.
 */
const KeyboardShortcuts = (() => {
    // An object containing all the defined shortcuts.
    let shortcuts = createShortcutsObject();
    let lastActiveScope = KeyboardShortcutScope.Default;

    /**
     * Add a new keyboard shortcut.
     * @param shortcut Keys for the shortcut in moustrap format.
     * @param callback The function that will run when the shortcut is executed.
     * @param scope    An optional scope in which the shortcut will be active.
     */
    const addKeyboardShortcut = (
        keys: string,
        callback: KeyHandler,
        scope: KeyboardShortcutScope = KeyboardShortcutScope.Default
    ) => {
        if (!shortcuts[scope].some(({ keys: storedKeys }) => storedKeys === keys)) {
            hotkeys(keys, scope, callback);
            shortcuts[scope].push({ keys });
        } else {
            hotkeys.unbind(keys, scope);
            hotkeys(keys, scope, callback);
        }
    };

    /**
     * Set the scope for keyboard shortcuts. Only shortcuts with this scope will be active.
     * @param scope The scope being set.
     */
    const setScope = (scope: KeyboardShortcutScope) => {
        lastActiveScope = getScope();
        hotkeys.setScope(scope);
    };

    /**
     * Get the current scope of the keyboard shortcuts.
     */
    const getScope = () => {
        return hotkeys.getScope() as KeyboardShortcutScope;
    };

    /**
     * Reset the scope to whatever the previous scope was.
     */
    const resetScopeToLastActive = () => {
        setScope(lastActiveScope);
    };

    /**
     * Remove a shortcut so that it can no longer be used.
     * @param shortcutToRemove The shortcut being removed.
     * @param scope            Optional scope which, if included, will only delete the shortcut from that scope.
     */
    const removeKeyboardShortcut = (shortcutToRemove: string, scope: KeyboardShortcutScope = getScope()) => {
        if (shortcuts[scope].some(({ keys }) => keys === shortcutToRemove)) {
            hotkeys.unbind(shortcutToRemove, scope);
            shortcuts = { ...shortcuts, [scope]: shortcuts[scope].filter(({ keys }) => keys !== shortcutToRemove) };
        }
    };

    /**
     * Return all of the active shortcuts.
     */
    const getActiveShortcuts = () => {
        return shortcuts;
    };

    return {
        addKeyboardShortcut,
        getActiveShortcuts,
        removeKeyboardShortcut,
        resetScopeToLastActive,
        setScope,
    };
})();

export default KeyboardShortcuts;
