export enum CallEventType {
    Create = 1,
    SubmitForm = 2,
    CustomerConnect = 3,
    CustomerDisconnect = 4,
    AgentConnect = 5,
    AgentDisconnect = 6,
    TransferRequest = 7,
    TransferSuccess = 8,
    TransferFailure = 9,
    Finish = 10,
    LanguageChange = 11,
    TransferCancel = 12,
    AgentRejectInvite = 13,
    AgentInvite = 14,
    AgentInviteTimeout = 15,
    VoicemailConnect = 16,
    VoicemailDisconnect = 17,
    OnHoldOn = 18,
    OnHoldOff = 19,
    UserConnect = 20,
    UserDisconnect = 21,
    IvrConnect = 22,
    IvrDisconnect = 23,
    EnterQueue = 24,
    CallbackRequest = 25,
}

export default CallEventType;
