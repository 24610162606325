import React, { FunctionComponent, memo, useEffect } from "react";
import ReactModal from "react-modal";

import { ModalContext } from "../modal-context";

import { OVERLAY_CLASS, PARENT_SELECTOR, ROOT_ID, STACKING_LAYERS_ID } from "./modal.constants";
import { IProps } from "./modal.types";
import "./modal.scss";

/**
 * A component that will display its children in a modal.
 * @param props.children       The content of the modal.
 * @param props.size           A prop determining the size of the modal.
 * @param props.modalUtilities Utilities for interacting with the modal.
 */
const Modal: FunctionComponent<IProps> = ({ children, size, ...modalUtilities }) => {
    const { canClose, closeModal, isOpen } = modalUtilities;

    useEffect(() => {
        if (document.getElementById("root")) {
            ReactModal.setAppElement("#root");
        }
    }, []);

    return (
        <>
            {isOpen ? (
                <ReactModal
                    ariaHideApp={false}
                    className="modal__outer"
                    isOpen={isOpen}
                    onRequestClose={closeModal}
                    overlayClassName={OVERLAY_CLASS}
                    parentSelector={() => {
                        return (
                            document.querySelector(STACKING_LAYERS_ID) ??
                            document.querySelector(PARENT_SELECTOR) ??
                            document.querySelector(ROOT_ID) ??
                            document.body
                        );
                    }}
                    shouldCloseOnEsc={canClose}
                    shouldCloseOnOverlayClick={canClose}
                >
                    <div className={typeof size === "string" ? `modal modal-${size}` : "modal"}>
                        {/* Wrap the children in a context provider so that all modal content can use the modal utilities. */}
                        <ModalContext.Provider value={modalUtilities}>{children}</ModalContext.Provider>
                    </div>
                </ReactModal>
            ) : null}
        </>
    );
};

export default memo(Modal);
