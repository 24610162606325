import { faStar as faStarEmpty } from "@fortawesome/free-regular-svg-icons/faStar";
import { faStar as faStarFilled } from "@fortawesome/free-solid-svg-icons/faStar";
import { ConditionalIcon } from "@edgetier/client-components";

import { IProps } from "./star-value.types";
import "./star-value.scss";

/**
 * Render several stars and highlight them based on the user's input.
 * @param props.maximumValue The number of stars that appeared to the user.
 * @param props.value        What value the user selected.
 * @returns                  Stars styled to match the user's selection.
 */
const StarValue = ({ maximumValue, value }: IProps) => (
    <div className="form-submission-star-field__value">
        {Array(maximumValue)
            .fill(0)
            .map((_, index) => (
                <ConditionalIcon
                    key={index}
                    classNameFalse="form-submission-star-field__value--empty"
                    classNameTrue="form-submission-star-field__value--filled"
                    condition={index < value}
                    iconFalse={faStarEmpty}
                    iconTrue={faStarFilled}
                />
            ))}
    </div>
);

export default StarValue;
