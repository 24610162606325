import { IReportingHistoricData } from "redux/modules/reporting/reporting.types";

import ReportingType from "./reporting-type";

const createAction = <T extends { type: ReportingType }>(action: T) => action;

/**
 * This action is called when the historic reporting data has been returned by the backend.
 * @param payload Historic reporting data.
 * @returns       An action to store the queue reporting data.
 */
const storeHistoric = (payload: IReportingHistoricData) => createAction({ type: ReportingType.StoreHistoric, payload });

export default { storeHistoric };
