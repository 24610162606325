import { doNothing } from "@edgetier/utilities";
import { createContext } from "react";
import { ISelectContext } from "./select-context.types";

export const defaultContext: ISelectContext = {
    close: doNothing,
    lockOpen: doNothing,
    unlock: doNothing,
};

const SelectContext = createContext(defaultContext);
export default SelectContext;
