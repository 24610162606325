import { DetailsFieldTitle, DetailsFieldValue } from "@edgetier/client-components";

import BlankField from "../blank-field";

import { IProps } from "./form-submission-numeric-field.types";
import "./form-submission-numeric-field.scss";

const FormSubmissionNumericField = ({ field }: IProps) => (
    <tr>
        <td>
            <DetailsFieldTitle>{field.formFieldTranslation}</DetailsFieldTitle>
        </td>

        <td>
            {typeof field.value === "number" ? (
                <DetailsFieldValue copyText={field.value.toString()}>{field.value}</DetailsFieldValue>
            ) : (
                <BlankField />
            )}
        </td>
    </tr>
);

export default FormSubmissionNumericField;
