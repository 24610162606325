import compareDesc from "date-fns/compareDesc";
import { useMemo, useState } from "react";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { SpinnerUntil, useDelay, Button } from "@edgetier/components";
import { Accordion, DetailsSection, DetailsSectionTitle } from "@edgetier/client-components";

import { ICustomerUrl } from "redux/application.types";

import CustomerUrl from "./customer-url";
import { IProps } from "./customer-urls.types";

import "./customer-urls.scss";

const PAGE_SIZE = 5;

/**
 * Render a link to the customer's URL and a breakdown of the query parameters.
 * @param props.customerUrls Array of one or more URLs that the customer visited before or during the chat.
 * @returns                  Customer URL link and URL parameter details.
 */
const CustomerUrls = ({ customerUrls, delay = 0 }: IProps) => {
    const isReady = useDelay(delay);
    const [limit, setLimit] = useState(PAGE_SIZE);
    const urls = useMemo(() => {
        if (customerUrls) {
            return customerUrls
                .slice()
                .sort((one, two) => compareDesc(one.dateTime, two.dateTime))
                .slice(0, limit);
        }
        return customerUrls;
    }, [customerUrls, limit]);
    const allUrlsCount = customerUrls?.length ?? 0;

    return (
        <div className="customer-urls">
            <DetailsSection>
                <DetailsSectionTitle title={allUrlsCount > 1 ? "Customer URLs" : "Customer URL"} />

                <SpinnerUntil<ICustomerUrl[]> data={urls} isReady={isReady && Array.isArray(urls)}>
                    {(data) => (
                        <Accordion>
                            {data.map((customerUrl, index) => (
                                <CustomerUrl customerUrl={customerUrl} key={allUrlsCount - index} />
                            ))}
                            {customerUrls && urls && customerUrls.length > urls.length && (
                                <Button
                                    className="button button--neutral button--small button--outline button--no-modal"
                                    outline
                                    icon={faEllipsisH}
                                    onClick={() => setLimit((limit) => limit + PAGE_SIZE)}
                                >
                                    Show more
                                </Button>
                            )}
                        </Accordion>
                    )}
                </SpinnerUntil>
            </DetailsSection>
        </div>
    );
};

export default CustomerUrls;
