import { useContext, useEffect } from "react";
import { EllipsisMenuContext } from "~/index";
import { IProps } from "./ellipsis-menu-monitor.types";

/**
 * Call the onMenuChange callback when the menu opens or closes.
 * @param props.onMenuChange A callback that is called when the menu opens or closes.
 */
const EllipsisMenuMonitor = ({ onMenuChange }: IProps) => {
    const { isOpen } = useContext(EllipsisMenuContext);

    useEffect(() => {
        if (isOpen) {
            onMenuChange(true);
        }

        // Runs when the menu closes.
        return () => {
            onMenuChange(false);
        };
    }, [isOpen, onMenuChange]);

    return null;
};

export default EllipsisMenuMonitor;
