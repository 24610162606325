import { FunctionComponent, memo } from "react";

import useChatObserver from "hooks-for/query-history/use-chat-observer";

import ChatEvents from "./chat-events";
import { IProps } from "./chat.types";
import "./chat.scss";

/**
 * Display a chat in the interaction search results. By default it is displayed in the agent's language if the chat was
 * translated.
 * @param props Props containing chat messages.
 * @returns     Chat display.
 */
const Chat: FunctionComponent<IProps> = ({
    chatId,
    events: initialEvents,
    interactionUrl,
    isCompleted,
    preferredLanguageId,
    secondaryLanguageId,
}) => {
    const events = useChatObserver(chatId, initialEvents);
    return (
        <div className="interaction-search__chat">
            <ChatEvents
                events={events}
                interactionUrl={interactionUrl}
                isChatCompleted={isCompleted}
                preferredLanguageId={preferredLanguageId}
                secondaryLanguageId={secondaryLanguageId}
            />
        </div>
    );
};

export default memo(Chat);
