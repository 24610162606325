import classNames from "classnames";

import { SplitTimestamp } from "@edgetier/client-components";
import QueryHistoryTimelineItemWarning from "./query-history-timeline-item-warning";
import { IProps } from "./query-history-timeline-item.types";
import "./query-history-timeline-item.scss";

/**
 * Display a interaction, external interaction, or note.
 * @param props.children    Internal or external interaciton.
 * @param props.warning     Optional warning text.
 * @param props.subject     Subject content displayed
 * @param props.body        Body content displayed
 * @param props.date        Date of the timeline event.
 * @param props.icon        Icon to display beside the item.
 * @returns                 Preview of the external or internal interaction.
 */
const QueryHistoryTimelineItem = ({ children, subject, body, date, icon, warning = null, classnames }: IProps) => {
    const queryTimelineItemClasses = classNames("query-history-timeline-item", classnames);
    return (
        <div className={queryTimelineItemClasses}>
            {typeof warning === "string" && <QueryHistoryTimelineItemWarning warning={warning} />}
            <div className="query-history-timeline-item__header">
                {typeof icon !== undefined && <div className="query-history-timeline-item__icon">{icon}</div>}
                {typeof subject === "string" ? (
                    <div className="query-history-timeline-item__header__subject">{subject}</div>
                ) : (
                    <strong className="query-history-timeline-item__type">{children}</strong>
                )}
                {typeof date !== "undefined" && <SplitTimestamp date={date} />}
            </div>
            {typeof body === "string" && <div className="query-history-timeline-item__body">{body}</div>}
        </div>
    );
};

export default QueryHistoryTimelineItem;
