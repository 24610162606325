import React, { FunctionComponent, memo } from "react";
import ReactSelect from "react-select";

import SelectMenuList from "./select-menu-list";
import SelectMultiValue from "./select-multi-value";
import SelectOption from "./select-option";
import SelectValueContainer from "./select-value-container";
import { IProps } from "./select.types";
import "./select.scss";

/**
 * Customer wrapper around React Select that provides a better multi-select display. If the input being rendered is a
 * single select (the default), then no custom components will be used.
 * @param props.components  Any component overrides.
 * @param props.description Description of the items being selected.
 * @param props.selectProps All other props to pass to React Select.
 * @returns                 Customised React Select.
 */
const Select: FunctionComponent<IProps> = ({ components, description = "options", ...selectProps }) => {
    return (
        <ReactSelect
            className="react-select-container"
            classNamePrefix="react-select"
            components={
                selectProps.isMulti
                    ? {
                          MenuList: SelectMenuList,
                          MultiValue: SelectMultiValue,
                          Option: SelectOption,
                          ValueContainer: (props) => <SelectValueContainer description={description} {...props} />,
                          ...components,
                      }
                    : components
            }
            hideSelectedOptions={selectProps.isMulti !== true}
            {...selectProps}
        />
    );
};

export default memo(Select);
