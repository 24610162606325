import { IChatState } from "redux/modules/chat/chat.types";

import actions from "./../chat-actions";

/**
 * Invite an agent to join a chat.
 * @param state  Application state before adding the waiting chat.
 * @param action Incoming chat details.
 */
export default function (state: IChatState, action: ReturnType<typeof actions.storeInvite>): IChatState {
    return {
        ...state,
        invites: {
            ...state.invites,
            [action.payload.chatToken]: action.payload,
        },
    };
}
