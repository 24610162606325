import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import View from "constants/view";
import KeyboardShortcuts, { KeyboardShortcutScope } from "services/keyboard-shortcut-service";

/**
 * Watch for a location change and update the keyboard shortcut scope when appropriate.
 */
const useShortcutScope = () => {
    const currentLocation = useLocation();
    useEffect(() => {
        const currentView = currentLocation.pathname;
        switch (currentView) {
            case View.Chat:
                KeyboardShortcuts.setScope(KeyboardShortcutScope.Chat);
                break;
            case View.CreateEmail:
            case View.Query:
                KeyboardShortcuts.setScope(KeyboardShortcutScope.Email);
                break;
            default:
                KeyboardShortcuts.setScope(KeyboardShortcutScope.Default);
        }
    }, [currentLocation]);
};

export default useShortcutScope;
