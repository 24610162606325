import ParagraphType from "constants/paragraph-type";
import { IParagraph } from "redux/application.types";

/**
 * Takes an array of plain text and returns an array of html elements
 * Operates on an array because real proposed actions contain single "paragraphs"
 *    containing more than one actual paragraph delimited by sequential newlines.
 * @param   paragraphs  array of plain text strings
 * @param   type        The element to use as a HTML wrapper
 * @return              <p>one</p><p>two</p>
 */
function wrapParagraphs(paragraphs: string[], type: "p" | "blockquote") {
    return paragraphs.map((text) => `<${type}>${text}</${type}>`);
}

/**
 * Turn a multiline block of text into multiple paragraphs for better editor experience
 * @param     text  Plain text
 * @return          Array of text split by two or more linebreaks
 */
function splitParagraphs(text: string) {
    return text.split(/(?:\r\n|\r|\n){2,}/g);
}

/**
 * Swap one or more plain text breaks for single HTML line breaks
 * @param   text  Plain text
 * @return        text with html <br /> tags
 */
function addLineBreaks(text: string) {
    return text.replace(/(?:\r\n|\r|\n)+/g, "<br />");
}

/**
 * Reads in plain text as plain text with the DOM's own html escape routine
 * @param   text  plain text with possible HTML special characters
 * @return        plain text with HTML encoded special characters
 */
function htmlEscape(text: string): string {
    const p = document.createElement("p");
    p.textContent = text;
    return p.innerHTML;
}

/**
 * Turn the backend's email paragraph format into HTML.
 * @param paragraphs Array of paragraphs.
 * @returns          HTML string.
 */
export default function convertParagraphs(paragraphs: IParagraph[]): string {
    return paragraphs
        .map(({ paragraphType, text }) => {
            switch (paragraphType) {
                case ParagraphType.QuotedText:
                    return wrapParagraphs([addLineBreaks(htmlEscape(text))], "blockquote").join("");
                case ParagraphType.DefaultText:
                default:
                    return wrapParagraphs(splitParagraphs(htmlEscape(text)).map(addLineBreaks), "p").join("");
            }
        })
        .join("");
}
