/**
 * If the user is using a mac, replace modifiers with the mac keyboard symbols.
 * @param shortcutKeys The keys of the shortcut.
 */
const replaceKeyNameWithSymbol = (shortcutKeys: string) => {
    return shortcutKeys
        .replace(new RegExp("command", "g"), "\u2318")
        .replace(new RegExp("alt", "g"), "\u2325")
        .replace(new RegExp("shift", "g"), "\u21E7")
        .replace(new RegExp("ctrl", "g"), "\u2303");
};

/**
 * Make the shortcut keys easier to read and only show the user the shortcut for their operating system.
 * @param shortcutKeys The keys used to trigger the shortcut.
 */
export const formatShortcutKeys = (shortcutKeys: string) => {
    const isMac = Array.isArray(window.navigator.platform.match(/^Mac/));
    const shortcuts = shortcutKeys.split(new RegExp(", ?")).map((shortcut) => shortcut.trim());

    // Filter out the shortcuts that don't belong to the user's platform.
    const platformSpecificShortcuts = shortcuts.filter((shortcut) => shortcut.includes("command") === isMac);
    const finalShortcuts = (platformSpecificShortcuts.length === 0 ? shortcuts : platformSpecificShortcuts)
        .join(", ")
        .replace(new RegExp(/\+/, "g"), " + ");

    return isMac ? replaceKeyNameWithSymbol(finalShortcuts) : finalShortcuts;
};
