import { ChatEventType } from "@edgetier/types";
import { faComment } from "@fortawesome/free-solid-svg-icons/faComment";
import { faCommentSlash } from "@fortawesome/free-solid-svg-icons/faCommentSlash";
import { faEdit } from "@fortawesome/free-solid-svg-icons/faEdit";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons/faExternalLinkAlt";
import { faFileAlt } from "@fortawesome/free-solid-svg-icons/faFileAlt";
import { faStopwatch } from "@fortawesome/free-solid-svg-icons/faStopwatch";

import { IInteractionEventDisplay } from "components/interaction-events/interaction-event/interaction-event.types";

import {
    AGENT_INVITE_EVENT,
    AGENT_INVITE_NEGATIVE_EVENT,
    CHANGE_SKILL_EVENT,
    CREATE_EVENT,
    ENTER_QUEUE_EVENT,
    FINISH_EVENT,
    LANGUAGE_CHANGE_EVENT,
    SUBMIT_FORM_EVENT,
    TRANSFER_NEGATIVE_EVENT,
    TRANSFER_REQUEST_EVENT,
    TRANSFER_SUCCESS_EVENT,
    USER_CONNECT_EVENT,
    USER_DISCONNECT_EVENT,
} from "../../../../interaction.constants";

const INACTIVITY_TIMER_EVENT: IInteractionEventDisplay = { icon: faStopwatch, styleName: "neutral" };
const TRANSCRIPT_EVENT: IInteractionEventDisplay = { icon: faFileAlt };

// The icon and colour to use for each event type.
export const CHAT_EVENT_DISPLAY: { [key in ChatEventType]: IInteractionEventDisplay } = {
    [ChatEventType.Create]: CREATE_EVENT,
    [ChatEventType.EnterQueue]: ENTER_QUEUE_EVENT,
    [ChatEventType.Finish]: FINISH_EVENT,
    [ChatEventType.AgentConnect]: USER_CONNECT_EVENT,
    [ChatEventType.CustomerConnect]: USER_CONNECT_EVENT,
    [ChatEventType.AgentInvite]: AGENT_INVITE_EVENT,
    [ChatEventType.AgentRejectInvite]: AGENT_INVITE_NEGATIVE_EVENT,
    [ChatEventType.AgentInviteTimeout]: AGENT_INVITE_NEGATIVE_EVENT,
    [ChatEventType.AgentDisconnect]: USER_DISCONNECT_EVENT,
    [ChatEventType.CustomerDisconnect]: USER_DISCONNECT_EVENT,
    [ChatEventType.SubmitForm]: SUBMIT_FORM_EVENT,
    [ChatEventType.LanguageChange]: LANGUAGE_CHANGE_EVENT,
    [ChatEventType.TransferCancel]: TRANSFER_NEGATIVE_EVENT,
    [ChatEventType.TransferFailure]: TRANSFER_NEGATIVE_EVENT,
    [ChatEventType.TransferRequest]: TRANSFER_REQUEST_EVENT,
    [ChatEventType.TransferSuccess]: TRANSFER_SUCCESS_EVENT,
    [ChatEventType.ChangeSkill]: CHANGE_SKILL_EVENT,

    //Events unique to chats
    [ChatEventType.AgentRequestTranscript]: TRANSCRIPT_EVENT,
    [ChatEventType.CustomerRequestTranscript]: TRANSCRIPT_EVENT,
    [ChatEventType.IncreaseInactivityTimer]: INACTIVITY_TIMER_EVENT,
    [ChatEventType.ResetInactivityTimer]: INACTIVITY_TIMER_EVENT,
    [ChatEventType.StartInactivityTimer]: INACTIVITY_TIMER_EVENT,
    [ChatEventType.StopInactivityTimer]: INACTIVITY_TIMER_EVENT,
    [ChatEventType.ChangeBookingId]: { icon: faEdit },
    [ChatEventType.CustomerEndChat]: { icon: faCommentSlash },
    [ChatEventType.CustomerUrlChange]: { icon: faExternalLinkAlt },
    [ChatEventType.ProposedMessage]: { icon: faComment, styleName: "neutral" },

    // This one is treated differently in the component, but needs a definition to keep typescript happy.
    [ChatEventType.Message]: { icon: faComment },
};
