import { FormFieldType } from "@edgetier/types";
import TimestampFormField from "./form-fields/timestamp-form-field";
import BooleanFormField from "./form-fields/boolean-form-field";
import FormField from "./form-fields/form-field";
import NumericFormField from "./form-fields/numeric-form-field";
import SelectFormField from "./form-fields/select-form-field";
import TextFormField from "./form-fields/text-form-field";
import SourcedSelectFormField from "./form-fields/sourced-select-form-field";

type IFieldMapping = {
    [key in FormFieldType]: { new (...args: any[]): FormField<any, any> };
};

export const FIELD_MAPPING: IFieldMapping = {
    [FormFieldType.Integer]: NumericFormField,
    [FormFieldType.Float]: NumericFormField,
    [FormFieldType.Text]: TextFormField,
    [FormFieldType.Textarea]: TextFormField,
    [FormFieldType.Timestamp]: TimestampFormField,
    [FormFieldType.Date]: TimestampFormField,
    [FormFieldType.Time]: TimestampFormField,
    [FormFieldType.DateTime]: TimestampFormField,
    [FormFieldType.Email]: TextFormField,
    [FormFieldType.Select]: SelectFormField,
    [FormFieldType.SourcedSelect]: SourcedSelectFormField,
    [FormFieldType.Star]: NumericFormField,
    [FormFieldType.Radios]: SelectFormField,
    [FormFieldType.NetPromoterScore]: NumericFormField,
    [FormFieldType.Buttons]: SelectFormField,
    [FormFieldType.Checkboxes]: SelectFormField,
    [FormFieldType.Boolean]: BooleanFormField,
};

// String value for when the user does not complete a non-required field. The string value is used in cases where, for
// example, a chat message contains a form and the user's response is sent as a message for their own reference.
export const EMPTY_FIELD_STRING_VALUE = "N/A";
