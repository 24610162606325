import { RefObject, useLayoutEffect, useState } from "react";
import useResizeObserver from "@react-hook/resize-observer";

/**
 * Check if the element's width is overflowing.
 * @param element The element being checked.
 * @returns True if the width is overflowing, false otherwise.
 */
const isWidthOverflowed = (element: Element) => {
    return element.scrollWidth > element.clientWidth;
};

/**
 * Check if the element's height is overflowing.
 * @param element The element being checked.
 * @returns true if the height is overflowing, false otherwise.
 */
const isHeightOverflowed = (element: Element) => {
    return element.scrollHeight > element.clientHeight;
};

/**
 * Check is the content of an element is overflowed.
 * @param ref              The ref of the element being checked.
 * @param dimensionToCheck Which dimension should be checked for overflow. Defaults to width.
 * @returns A boolean indicating if the content of the element is overflowed.
 */
export const useIsOverflowed = (ref: RefObject<HTMLElement>, dimensionToCheck: "width" | "height" = "width") => {
    const [isOverflowed, setIsOverflowed] = useState(false);
    const checkIsOverflowed = dimensionToCheck === "width" ? isWidthOverflowed : isHeightOverflowed;

    // Check title width when the title initially renders.
    useLayoutEffect(() => {
        const element = ref.current;
        if (element !== null) {
            setIsOverflowed(checkIsOverflowed(element));
        }
    }, [ref]);

    // Check width every time the element changes size.
    useResizeObserver(ref, ({ target }) => {
        setIsOverflowed(checkIsOverflowed(target));
    });

    return isOverflowed;
};
