import { faCheck } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import { Button } from "@edgetier/components";

import { DropDownMenu, DropDownMenuItem } from "components/drop-down-menu";
import ShortcutTooltip from "components-for/shortcuts/shortcut-tooltip";

import { IProps } from "./split-button.types";
import "./split-button.scss";

/**
 * A button and a dropdown menu, the default option can be selected by clicking the button
 * and all other options are selected through the dropdown.
 * @param props.options        Options to show in the dropdown.
 * @param props.defaultOption  Default option which will be shown in the button.
 * @param props.onOptionSelect Function that runs when an option is selected.
 * @param props.getOptionLabel Function to get the label of an option.
 * @param props.getOptionIcon  Function to get the icon of an option.
 */
const SplitButton = <T extends {}>({
    options,
    defaultOption = options[0],
    onOptionSelect,
    getOptionLabel,
    shouldButtonDoAction = true,
    disabled,
    getOptionIcon,
    shortcutKeys,
    type = "button",
    ...buttonProps
}: IProps<T>) => {
    /**
     * Get the label associated with a particular option.
     * @param option The option, which the label is needed for.
     */
    const getLabel = (option: T) => {
        const label = getOptionLabel ? getOptionLabel(option) : option.toString();
        return type === "submit" ? `Submit as ${label}` : label;
    };

    /**
     * Get the icon associated with a particular option.
     * @param option The option whose icon is being retrieved.
     */
    const getIcon = (option: T) => {
        return getOptionIcon ? getOptionIcon(option) : faCheck;
    };

    // Boolean to control if the dropdown should be shown based on how many options are available.
    const hasDropdownOptions = options.length > 1;

    return (
        <div
            className={classNames("button--split", {
                "button--split--submit": type === "submit",
                "button--split__drop-down-only": !shouldButtonDoAction,
                "button--split__no-dropdown-options": !hasDropdownOptions,
                "button--split--negative": buttonProps.styleName === "negative",
            })}
        >
            <div className="button--split__container">
                <ShortcutTooltip isDisabled={typeof shortcutKeys === "undefined"} shortcutKeys={shortcutKeys ?? ""}>
                    <Button
                        onClick={() => shouldButtonDoAction && onOptionSelect(defaultOption)}
                        disabled={disabled || !shouldButtonDoAction}
                        icon={getIcon(defaultOption)}
                        type="button"
                        {...buttonProps}
                    >
                        {getLabel(defaultOption)}
                    </Button>
                </ShortcutTooltip>

                {hasDropdownOptions && (
                    <DropDownMenu useIcon={false} disabled={disabled}>
                        <ul>
                            {options
                                .filter((option) => option !== defaultOption)
                                .map((option, index) => (
                                    <DropDownMenuItem
                                        key={index}
                                        isDisabled={disabled || false}
                                        icon={getIcon(option)}
                                        onClick={() => onOptionSelect(option)}
                                    >
                                        <button type="button">{getLabel(option)}</button>
                                    </DropDownMenuItem>
                                ))}
                        </ul>
                    </DropDownMenu>
                )}
            </div>
        </div>
    );
};

export default SplitButton;
