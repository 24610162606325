// Chat & Email
export const Focus = "ctrl+m";

//Email
export const NoActionRequired = "command+shift+enter, ctrl+shift+enter";
export const SelectProposedActionsOption = (index: number) => `ctrl+alt+${index}`;
export const SendEmail = "command+enter, ctrl+enter, command+e, ctrl+e";

//Chat
export const EndChat = "command+e, ctrl+e";
export const SwitchChatDown = "ctrl+alt+down";
export const SwitchChatUp = "ctrl+alt+up";
