import View from "constants/view";
import { NavigationLink } from "@edgetier/client-components";
import { faEnvelopeOpen } from "@fortawesome/pro-solid-svg-icons";

/**
 * Link to the blank email view.
 * @returns A link to the page.
 */
const CreateEmailLink = () => <NavigationLink label="New Email" icon={faEnvelopeOpen} page={View.CreateEmail} />;

export default CreateEmailLink;
