import Axios from "axios";
import urlJoin from "url-join";
import { AnyAction } from "redux";
import { StatusCodes } from "http-status-codes";
import { ThunkAction } from "redux-thunk";
import { Url } from "@edgetier/types";

import axios from "utilities/axios";
import { IApplicationState } from "redux/types";
import { IQueryEmail } from "redux/modules/email/email.types";
import { ISettings } from "redux/application.types";
import { setupSelectors } from "redux/modules/setup";
import { toastOperations } from "redux/modules/toast";

import actions from "./email-actions";
import { getDeferralUser } from "./email-utilities";

type IEmailThunk<T = any> = ThunkAction<T, IApplicationState, void, AnyAction>;

// Convert minutes to milliseconds.
export const MINUTE_TO_MILLISECONDS = 60000;

// How long before an email expiry check will start.
export const EXPIRY_IDLE_CHECK_PERIOD = 0.9;

// How long after the idle check until the warning displays.
export const EXPIRY_WARNING = (1 - EXPIRY_IDLE_CHECK_PERIOD) / 2;

let emailPollTimeout: number;
let expiryTimeout: number;
let queryCancelTokenSource = Axios.CancelToken.source();

/**
 * Remove all timers and anything else associated with an inbound email.
 */
function clearTimers(): IEmailThunk {
    return () => {
        window.clearTimeout(emailPollTimeout);
        window.clearTimeout(expiryTimeout);
        return actions.clearTimers();
    };
}

/**
 * Turn off email and cancel any requests or anything else that's going on.
 */
function disableEmail(): IEmailThunk {
    return (dispatch) => {
        dispatch(actions.toggleEmail(false));
        dispatch(emailOperations.clearTimers());
    };
}

/**
 * Agents have a certain amount of time to finish a query before it is assigned to someone else. When nearing the expiry
 * time, if they are clearly working on the email or they ask for more time when warned, a request is made here to the
 * backend asking for a time extension. If successful, the expiry timers restart so they have another period of time to
 * work on it. On a failed request, the query is discarded and a new one must be requested.
 * @param emailId Email being extended.
 */
function extendQueryTime(emailId: number): IEmailThunk {
    return async (dispatch, getState) => {
        try {
            // Stop any warnings appearing.
            window.clearTimeout(expiryTimeout);
            dispatch(actions.updateEmail("query", { isExpiring: false, isExpiringSoon: false, isExpired: false }));

            // Ask the backend if the agent can have more time with the query.
            const configuration = { cancelToken: queryCancelTokenSource.token };
            await axios.put(urlJoin(Url.Emails, emailId.toString()), {}, configuration);

            // Start timing again.
            dispatch(emailOperations.trackQueryExpiry());
        } catch (serverError) {
            dispatch(
                toastOperations.showErrorToast(
                    "Query Expired",
                    "An attempt was made to extend your time on a query but it is not possible."
                )
            );

            // The agent can't work on this query any more.
            dispatch(emailOperations.removeQueryEmail());

            // Request another email if necessary.
            if (getState().email.enabled) {
                dispatch(emailOperations.requestQuery());
            }
        }
    };
}

/**
 * Remove an email and clear all timers etc. associated with it.
 */
function removeQueryEmail(): IEmailThunk {
    return (dispatch) => {
        dispatch(clearTimers());
        dispatch(actions.removeEmail("query"));
    };
}

/**
 * Request an email from the backend for the current user to handle.
 */
function requestQuery(): IEmailThunk {
    return async (dispatch, getState) => {
        try {
            // Get the next email suited to the agent's skills and languages.
            queryCancelTokenSource = Axios.CancelToken.source();
            const configuration = { cancelToken: queryCancelTokenSource.token };
            const { data: email } = await axios.get<IQueryEmail>(Url.EmailsNext, configuration);
            const { data: settings } = await axios.get<ISettings>(Url.Settings, configuration);

            // If the email was deferred, get the user.
            const deferralUser = await getDeferralUser(email.deferredByUserId, configuration);

            // Store the query. At this point it will appear on the user's screen but further details will be requested
            // elsewhere while they read it.
            dispatch(actions.storeEmail("query", { ...email, deferralUser, settings }));

            // Agents only have a certain time to complete emails. They are warned if close to expiry.
            const emailAllowedTimeMinutes = setupSelectors.getEmailAllowedTimeMinutes(getState().setup);
            if (typeof emailAllowedTimeMinutes === "number") {
                dispatch(emailOperations.trackQueryExpiry());
            }
        } catch (serverError) {
            if (Axios.isAxiosError(serverError)) {
                // The backend returns a 404 "not found" result if there are no emails in the queue.
                const isQueueEmpty =
                    typeof serverError.response !== "undefined" &&
                    serverError.response.status === StatusCodes.NOT_FOUND;
                if (isQueueEmpty) {
                    dispatch(actions.toggleQueueEmpty(isQueueEmpty));

                    // Poll a while later for another email if the queue is empty.
                    const headers = serverError.response!.headers || {};
                    const retryAfterSeconds = "retry-after" in headers ? headers["retry-after"] : null;
                    const retryAfterMilliseconds = parseInt(retryAfterSeconds, 10) * 1000;
                    if (!isNaN(retryAfterMilliseconds)) {
                        const requestQueryAgain = () => dispatch(emailOperations.requestQuery());
                        emailPollTimeout = window.setTimeout(requestQueryAgain, retryAfterMilliseconds);
                    }
                } else {
                    dispatch(toastOperations.showServerErrorToast("Email Request Failed", serverError));
                }
            }
        }
    };
}

/**
 * Agents only have a certain amount of time to handle an email. As the deadline gets close, they are warned that the
 * query will expire soon and then eventually they'll get a message saying it's gone.
 */
function trackQueryExpiry(): IEmailThunk {
    return (dispatch, getState) => {
        const emailAllowedTimeMinutes = setupSelectors.getEmailAllowedTimeMinutes(getState().setup);
        if (typeof emailAllowedTimeMinutes !== "number") {
            return;
        }

        const reassignmentMilliseconds = emailAllowedTimeMinutes * MINUTE_TO_MILLISECONDS;

        /**
         * Set the expiring soon state. This will cause user events to be tracked to see if the user is actually idle.
         */
        function setExpiringSoon() {
            dispatch(actions.updateEmail("query", { isExpiringSoon: true }));
            expiryTimeout = window.setTimeout(setExpiring, reassignmentMilliseconds * EXPIRY_WARNING);
        }

        /**
         * Set the expiring state. The user now has a certain period of time to extend the query or they'll lose it.
         */
        function setExpiring() {
            dispatch(actions.updateEmail("query", { isExpiringSoon: false, isExpiring: true }));
            const expiredMilliseconds = reassignmentMilliseconds * (1 - (EXPIRY_WARNING + EXPIRY_IDLE_CHECK_PERIOD));
            expiryTimeout = window.setTimeout(setExpired, expiredMilliseconds);
        }

        /**
         * Set the expired state. The agent can no longer work on this query.
         */
        function setExpired() {
            dispatch(actions.updateEmail("query", { isExpiring: false, isExpired: true }));
        }

        expiryTimeout = window.setTimeout(setExpiringSoon, reassignmentMilliseconds * EXPIRY_IDLE_CHECK_PERIOD);
    };
}

const emailOperations = {
    ...actions,
    clearTimers,
    disableEmail,
    extendQueryTime,
    removeQueryEmail,
    requestQuery,
    trackQueryExpiry,
};
export default emailOperations;
