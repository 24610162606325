import Axios from "axios";
import { useEffect, useState } from "react";

import CustomerUrls from "components/customer-urls";
import requestCustomerUrls from "utilities/request-customer-urls";
import { ICustomerUrl } from "redux/application.types";

import { IProps } from "./customer-urls-loader.types";
import "./customer-urls-loader.scss";

/**
 * Request and display the URLs a customer was on before and during a chat.
 * @param props.chatId Identifier for the chat.
 * @returns            Customer URL history or spinner.
 */
const CustomerUrlsLoader = ({ chatId }: IProps) => {
    const [customerUrls, setCustomerUrls] = useState<ICustomerUrl[] | null>(null);

    // Download customer URLs.
    useEffect(
        function () {
            const cancelTokenSource = Axios.CancelToken.source();

            /**
             * Request the customer URLs for the given chat and store them in state.
             * @param cancelTokenSource Axios cancel token for cancelling the request on unmount.
             */
            async function fetchData() {
                try {
                    const configuration = { cancelToken: cancelTokenSource.token };
                    const customerUrls = await requestCustomerUrls(chatId, configuration);
                    setCustomerUrls(customerUrls);
                } catch (serverError) {
                    !Axios.isCancel(serverError) && setCustomerUrls([]);
                }
            }

            fetchData();

            return cancelTokenSource.cancel;
        },
        [chatId]
    );

    return <CustomerUrls customerUrls={customerUrls} />;
};

export default CustomerUrlsLoader;
