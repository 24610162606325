import React from "react";

import { IProps } from "./chat-warning-multiple.types";

/**
 * Warning that an agent has multiple active chats when trying to sign out.
 * @param props Props containing how many active chats there are.
 * @returns     Warning message.
 */
const ChatWarningMultple = ({ chatCount }: IProps) => (
    <p>You have {chatCount} ongoing chats. Signing out will return those customers to the the queue.</p>
);

export default ChatWarningMultple;
