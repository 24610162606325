import { useQuery, UseQueryOptions } from "react-query";
import { Url } from "@edgetier/types";
import { AxiosError } from "axios";
import { IUser, IUserState, IUserStateRequestParameters } from "redux/application.types";
import { getAllPaginatedItems } from "utilities/get-all-paginated-items";

/**
 * Request user states. The backend has some defaults in upper case that are converted to title case.
 * @param url             The URL to request.
 * @param queryParameters Any query parameters to pass to the request.
 * @returns An array of user states.
 */
const requestUserStates = async (url: string, queryParameters: IUserStateRequestParameters = {}) => {
    const data = await getAllPaginatedItems<IUserState>(url, { params: queryParameters });
    return data.map(({ userState, ...others }: IUserState) => ({
        ...others,
        userState: others.isUserEditable
            ? userState
            : userState[0].toUpperCase().concat(userState.substring(1).toLowerCase()),
    }));
};

/**
 * Hook to get the current user's state.
 * @param user            The current user.
 * @param queryParameters Any query parameters to pass to the request.
 * @param options         Any options to pass to the query.
 * @returns The user's state or undefined.
 */
export const useCurrentUserState = (
    user: IUser | undefined,
    queryParameters: IUserStateRequestParameters = {},
    options: Omit<
        UseQueryOptions<IUserState[], AxiosError, IUserState | undefined>,
        "queryKey" | "queryFn" | "select"
    > = {}
) => {
    const url = Url.UserStates;
    return useQuery<IUserState[], AxiosError, IUserState | undefined>(
        [url, queryParameters, user?.userId ?? null, user?.userStateId ?? null],
        () => requestUserStates(url, queryParameters),
        {
            enabled: typeof user !== "undefined",
            select: (userStates) => userStates.find(({ userStateId }) => userStateId === user?.userStateId),
            ...options,
        }
    );
};

/**
 * Hook to get user states.
 * @param queryParameters Any query parameters to pass to the request.
 * @param options         Any options to pass to the query.
 * @returns An array of user states.
 */
const useUserStates = <T = IUserState[]>(
    queryParameters: IUserStateRequestParameters = {},
    options: Omit<UseQueryOptions<IUserState[], AxiosError, T>, "queryKey" | "queryFn"> = {}
) => {
    const url = Url.UserStates;
    return useQuery<IUserState[], AxiosError, T>(
        [url, queryParameters],
        () => requestUserStates(url, queryParameters),
        options
    );
};

export default useUserStates;
