import urlJoin from "url-join";
import { parseISO } from "date-fns";
import { Url } from "@edgetier/types";

import axios from "utilities/axios";
import { IBackendNote, INote } from "redux/application.types";

/**
 * Either PATCH an existing note or POST a new one.
 * @param props.interactionDetailId Optional identifier to tie the note to a specific call, chat, or email.
 * @param props.queryId             Query where the note will be appended.
 * @param props.note                Optional existing note if it is being edited. Otherwise a new one will be created.
 * @returns                         The newly updated note.
 */
export const patchOrPostNote = (interactionDetailId: number | null, queryId: number, note: INote | undefined) => async (
    submittedNote: string
) => {
    const noteText = submittedNote.trim();
    // Check if a note was passed which will mean a PATCH should be made instead of a POST.
    if (typeof note?.noteId === "number") {
        const url = urlJoin(Url.Notes, String(note.noteId));
        await axios.patch(url, { note: noteText });
        return { ...note, note: noteText };
    } else {
        const postData = { interactionDetailId, queryId, note: noteText };
        const { data } = await axios.post<IBackendNote>(Url.Notes, postData);
        return { ...data, dateTime: parseISO(data.dateTime) };
    }
};
