import { FunctionComponent, memo } from "react";

import EmailList from "./email-list";
import { IProps } from "./email-participants.types";
import "./email-participants.scss";

/**
 * Display where the email's been sent from, who it's sent to, and anyone cc'd or bcc'd.
 * @param props.fromEmailAddress The email address of the person who sent the email.
 * @param props.recipients       An object containing the recipients of the email.
 */
const EmailParticipants: FunctionComponent<IProps> = ({ fromEmailAddress, recipients }) => {
    const { to, cc, bcc } = recipients;

    return (
        <div className="email-participants">
            {typeof fromEmailAddress !== "undefined" && (
                <div className="email-participants__sender">
                    <span className="email-participants__type">from:</span>
                    <span className="email-participants__emails">{fromEmailAddress}</span>
                </div>
            )}
            <div className="email-participants__recipients">
                {to && <EmailList emails={to} title="to" />}
                {cc && <EmailList emails={cc} title="cc" />}
                {bcc && <EmailList emails={bcc} title="bcc" />}
            </div>
        </div>
    );
};

export default memo(EmailParticipants);
