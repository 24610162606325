import React from "react";

import NetPromoterScoreItem from "./net-promoter-score-item";
import { IProps } from "./net-promoter-score-value.types";
import "./net-promoter-score-value.scss";

/**
 * Display a series of numbers representing an NPS input presented to a user on a form.
 * @param props.value        The user's rating.
 * @param props.maximumValue The highest rating the user could have given.
 * @param props.minimumValue The lowest rating the user could have given.
 * @returns           Ten numbers with the user's selection highlighted.
 */
const NetPromoterScoreValue = ({ maximumValue, minimumValue, value }: IProps) => (
    <div className="net-promoter-score-value">
        {Array(maximumValue - minimumValue + 1)
            .fill(0)
            .map((_, index) => (
                <NetPromoterScoreItem key={index} index={index + minimumValue} value={value} />
            ))}
    </div>
);

export default NetPromoterScoreValue;
