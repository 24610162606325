/**
 * Take enum or other names given by the backend and make them nicer to read.
 * @param value Backend enum name.
 * @returns     Nicer enum name.
 *
 * @example
 *
 * // Returns "Team Lead".
 * formatEnumName("team_lead")
 */
export function formatUnderscoreName(value: string): string {
    // Don't crash on empty string.
    if (value.trim().length === 0) {
        return value.trim();
    }

    const words = value.split("_").map((word) => word[0].toUpperCase().concat(word.substring(1).toLowerCase()));
    return words.join(" ");
}
