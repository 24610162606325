import InteractionEvent from "components/interaction-events/interaction-event";
import UnknownEvent from "components/interaction-events/unknown-event";
import { IProps } from "./call-event.types";
import { CALL_EVENT_DISPLAY } from "./call-event.constants";

/**
 * Render a call event. Each will have a specific icon, colour, name and date.
 * @param props.event Call event.
 */
const CallEvent = ({ event }: IProps) => {
    const callEventDisplay = CALL_EVENT_DISPLAY[event.callEventTypeId];
    return typeof callEventDisplay === "undefined" ? (
        <UnknownEvent event={event} />
    ) : (
        <InteractionEvent event={event} {...callEventDisplay} />
    );
};

export default CallEvent;
