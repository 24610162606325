import { ParsedQs } from "qs";

/**
 * Parse an array from a URL. URLs are usually parsed with Qs but that has no way of knowing if something is an array if
 * there's only one value.
 * @param value           Parameter string from a URL.
 * @param defaultDateTime Default value if the parameter is not provided.
 * @returns               Parameter as an array or default value.
 */
export const parseUrlArray = <Result extends string = string>(
    value: string | ParsedQs | string[] | ParsedQs[] | undefined,
    defaultValue?: string[]
) => {
    if (typeof value === "string") {
        return [value] as Result[];
    } else if (isArrayOfStrings(value)) {
        return value as Result[];
    } else {
        return (defaultValue ?? []) as Result[];
    }
};

/**
 * Check if something is an array of strings. This is mostly for TypeScript.
 * @param value Any value.
 * @returns     True if the value is an a string array.
 *
 * @example
 * // Returns true:
 * isArrayOfStrings(["one", "two"])
 *
 * // Returns fale:
 * isArrayOfStrings([1, 2])
 */
const isArrayOfStrings = (value: any): value is string[] => {
    return Array.isArray(value) && value.every((item) => typeof item === "string");
};
