import { getTranslation } from "@edgetier/utilities";
import ToggleTranslationContext from "../toggle-translation-context";
import { IProps } from "./toggle-translation-section.types";

/**
 * Display a section of text that can be translated for example, an email body, chat message, subject line of an email
 * etc.
 */
const ToggleTranslationSection = ({ children, translations }: IProps) => (
    <ToggleTranslationContext.Consumer>
        {({ languageId }) => children(getTranslation(translations, languageId))}
    </ToggleTranslationContext.Consumer>
);

export default ToggleTranslationSection;
