import Qs from "qs";
import { FormikValues } from "formik";

/**
 * Take either the default values or values from the URL.
 * @param defaultValues Default values set passed to the component.
 * @param search        URL search parameters.
 * @returns             Combined default and URL parameters.
 */
export const getInitialValues = (defaultValues: FormikValues, search: string) => {
    const parameters = Qs.parse(search, { ignoreQueryPrefix: true });

    // Only take known parameters from the URL (i.e. those in the initial values).
    const validParameters = Object.entries(parameters)
        .filter(([key]) => key in defaultValues)
        .reduce((data, [key, value]) => ({ ...data, [key]: value }), {});

    return { ...defaultValues, ...validParameters };
};
