import { useFormikContext } from "formik";
import { memo, useCallback, useEffect } from "react";

import { IProps } from "./cache-form.types";

/**
 * Store the form's values in the cache when the values change.
 * @param props.cacheValues The function to store the values in the cache.
 */
const CacheForm = <T extends {}>({ cacheValues }: IProps<T>) => {
    const { values } = useFormikContext<T>();

    const cacheValuesCallback = useCallback(() => {
        cacheValues(values);
    }, [cacheValues, values]);

    useEffect(() => cacheValuesCallback(), [cacheValuesCallback]);

    return null;
};

export default memo(CacheForm);
