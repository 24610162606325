import React, { FunctionComponent, memo } from "react";

import EmailParticipants from "../email-participants";

import { IProps } from "./outbound-email-participants.types";

/**
 * Display where the outbound email's been sent from, and who its been sent to.
 * @param props.fromEmailAddress The email address of the person who sent the email.
 * @param props.toEmailAddress   The email address of the person who received the email.
 */
const OutboundEmailParticipants: FunctionComponent<IProps> = ({ fromEmailAddress, toEmailAddress }) => {
    const recipients = { to: [toEmailAddress] };
    return <EmailParticipants fromEmailAddress={fromEmailAddress} recipients={recipients} />;
};

export default memo(OutboundEmailParticipants);
