import React, { FunctionComponent, memo } from "react";
import { Button } from "@edgetier/components";
import { faEdit } from "@fortawesome/free-solid-svg-icons";

import NoteModal from "components-for/notes/note-modal";

import QueryHistoryNoteDeleteButton from "./query-history-note-delete-button";
import { IProps } from "./query-history-note-controls.types";
import "./query-history-note-controls.scss";

/**
 * Buttons to edit or delete a note.
 * @param props.queryId Query to which the note belongs.
 * @param props.noteId  Identifier for the note to edit or delete.
 * @returns             Edit and delete buttons.
 */
const QueryHistoryNoteControls: FunctionComponent<IProps> = ({ queryId, noteId }) => {
    return (
        <div className="query-history-note-controls">
            <NoteModal interactionDetailId={null} queryId={queryId} noteId={noteId}>
                {({ open }) => (
                    <Button className="button--no-modal" icon={faEdit} onClick={open}>
                        Edit
                    </Button>
                )}
            </NoteModal>

            <QueryHistoryNoteDeleteButton queryId={queryId} noteId={noteId} />
        </div>
    );
};

export default memo(QueryHistoryNoteControls);
