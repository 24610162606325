import { IInteractionSearchItem } from "components/interaction-search/interaction-search.types";
import { IActiveInteraction } from "components/interaction/interaction-details/interaction-details.types";

/**
 * Determine if the interaction from timeline matches with the interaction
 * that's being displayed
 * @param activeInteraction     Current interaction being displayed
 * @param interaction           Interaction from timeline
 */
export const isInteractionCurrentlyVisible = (
    activeInteraction: IActiveInteraction,
    interaction: IInteractionSearchItem
) => {
    if (activeInteraction !== null) {
        if ("chatId" in activeInteraction && "chatId" in interaction) {
            return activeInteraction.chatId === interaction.chatId;
        }

        if ("emailId" in activeInteraction && "emailId" in interaction) {
            if (activeInteraction.emailId !== null) {
                return activeInteraction.emailId === interaction.emailId;
            }
        }

        if ("callId" in activeInteraction && "callId" in interaction) {
            return activeInteraction.callId === interaction.callId;
        }

        if ("outboundEmailActionId" in activeInteraction && "outboundEmailActionId" in interaction) {
            return activeInteraction.outboundEmailActionId === interaction.outboundEmailActionId;
        }
    }
    return false;
};
