import { IActionsUnion } from "../../types";

import actions from "./authentication-actions";
import AuthenticationType from "./authentication-type";
import { IAuthenticationState } from "./authentication.types";

type IAction = IActionsUnion<typeof actions>;

// The token is initially not checked until a request is made to the backend when the application loads.
const initialState = {};

export default function (state: IAuthenticationState = initialState, action: IAction): IAuthenticationState {
    switch (action.type) {
        case AuthenticationType.SignIn:
            return { ...state, roleId: action.payload.roleId, userEmail: action.payload.userEmail };
        case AuthenticationType.SignOut:
            return initialState;
        default:
            return state;
    }
}
