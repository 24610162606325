import React, { Fragment } from "react";
import { faClock } from "@fortawesome/free-solid-svg-icons/faClock";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import View from "constants/view";

import NavigationLink from "../navigation-link";

/**
 * Link to the deferred queries screen.
 * @returns A link to the page.
 */
const DeferredQueriesLink = () => (
    <NavigationLink view={View.DeferredQueries}>
        <Fragment>
            <FontAwesomeIcon icon={faClock} />
            Deferred Queries
        </Fragment>
    </NavigationLink>
);

export default DeferredQueriesLink;
