import { capitalize, words } from "lodash";

/**
 *
 * @param value Field name.
 */
export const formatField = (value: string): string => {
    return words(value)
        .map(function (word: string): string {
            if (word.toLowerCase() === "gps") {
                return "GPS";
            }
            return capitalize(word);
        })
        .join(" ");
};
