import React from "react";

import DropDownMenuContext from "../drop-down-menu-context";

import { IProps } from "./drop-down-menu-provider.types";

const DropDownMenuProvider = ({ children, ...props }: IProps) => {
    return <DropDownMenuContext.Provider value={props}>{children}</DropDownMenuContext.Provider>;
};

export default DropDownMenuProvider;
