import React from "react";
import { DetailsFieldTitle } from "@edgetier/client-components";

import BlankField from "../blank-field";

import StarValue from "./star-value";
import { IProps } from "./form-submission-star-field.types";
import "./form-submission-star-field.scss";

/**
 * Display the users selection on a star input. This is a form input that allows users to rate something using stars.
 * The number of stars is configured by the client.
 * @param props.field Star field.
 * @returns           Star submission value.
 */
const FormSubmissionStarField = ({ field: { formField, formFieldTranslation, value } }: IProps) => (
    <tr className="form-submission-star-field">
        <td colSpan={2}>
            <DetailsFieldTitle>{formFieldTranslation}</DetailsFieldTitle>

            {typeof value === "number" ? (
                <StarValue maximumValue={formField.configuration.maximumValue} value={value} />
            ) : (
                <BlankField />
            )}
        </td>
    </tr>
);

export default FormSubmissionStarField;
