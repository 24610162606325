import { doNothing } from "@edgetier/utilities";
import { createContext } from "react";
import { IInteractionSearchResultsContext } from "./interaction-search-results-context.types";

const defaultContext: IInteractionSearchResultsContext = {
    updateInteractions: doNothing,
    interactions: null,
};

export const InteractionSearchResultsContext = createContext(defaultContext);
