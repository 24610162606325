// https://irisreading.com/what-is-the-average-reading-speed/
const AVERAGE_READING_SPEED_SECONDS = 3.33;

/**
 * Calculate the duration in miliseonds it would take the average person to read the text based on the length of the
 * text and average reading speed.
 * @param text   The text being read.
 * @param offset An optional offset to increase the reading time.
 * @returns The duration it takes the average person to read the text in milliseconds.
 */
export const getTimeToRead = (text: string, offset: number = 0) => {
    // Average reading speed is 200 wpm (3.33 wps). lengthInWords / 3.33 = seconds it takes to read.
    const lengthInWords = text.split(" ").length;
    const secondsToRead = lengthInWords / AVERAGE_READING_SPEED_SECONDS;
    return secondsToRead * 1000 + offset;
};
