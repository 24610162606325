import { DetailsFieldTitle } from "@edgetier/client-components";

import BlankField from "../blank-field";

import NetPromoterScoreValue from "./net-promoter-score-value";
import { IProps } from "./form-submission-net-promoter-score-field.types";
import "./form-submission-net-promoter-score-field.scss";

/**
 * Display a NPS form field submission. An NPS asks users to rate their experience of something on a ten-point scale.
 * @param props.field NPS form field submission.
 * @returns           Field title and NPS selection value.
 */
const FormSubmissionNetPromoterScoreField = ({ field: { formField, formFieldTranslation, value } }: IProps) => (
    <tr className="form-submission-net-promoter-score-field">
        <td colSpan={2}>
            <DetailsFieldTitle>{formFieldTranslation}</DetailsFieldTitle>

            {typeof value === "number" ? (
                <NetPromoterScoreValue
                    value={value}
                    maximumValue={formField.configuration.maximumValue}
                    minimumValue={formField.configuration.minimumValue}
                />
            ) : (
                <BlankField />
            )}
        </td>
    </tr>
);

export default FormSubmissionNetPromoterScoreField;
