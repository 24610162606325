import { AxiosRequestConfig } from "axios";
import { IPaginated } from "@edgetier/types";
import { parseISO } from "date-fns";

import {
    IBackendInteractionSearchItem,
    IInteractionSearchItem,
} from "components/interaction-search/interaction-search.types";

import axios from "./axios";
import { getAllPaginatedItems } from "./get-all-paginated-items";

/**
 * Format the interactions returned from the backend to something the frontend can use.
 * @param interactions The interactions returned from the backend.
 * @returns Interactions with agentFinishTime, dateTime and interactionId parameters added.
 */
export const formatInteractions = (interactions: IBackendInteractionSearchItem[]): IInteractionSearchItem[] => {
    return interactions.map(({ agentFinishTime, date, ...rest }) => ({
        ...rest,
        agentFinishTime: typeof agentFinishTime === "string" ? parseISO(agentFinishTime) : agentFinishTime,
        dateTime: typeof date === "string" ? parseISO(date) : date,
        interactionId:
            "chatId" in rest
                ? `chat-${rest.chatId}`
                : "callId" in rest
                ? `call-${rest.callId}`
                : "emailId" in rest
                ? `inbound-email-${rest.emailId}`
                : `outbound-email-${rest.outboundEmailActionId}`,
    }));
};

/**
 * Request/search interactions. The backend's response doesn't uniquely identify the results with a single property in
 * any way, so a unique ID is created here for each item. Dates also converted.
 * @param configuration Axios configuration argument.
 * @returns             Promise of paginated search results.
 */
export default async function requestInteractions(
    url: string,
    configuration: AxiosRequestConfig = {}
): Promise<IPaginated<IInteractionSearchItem>> {
    const { data } = await axios.get<IPaginated<IBackendInteractionSearchItem>>(url, configuration);
    return {
        ...data,
        items: formatInteractions(data.items),
    };
}

/**
 * Request/search interactions. The backend's response doesn't uniquely identify the results with a single property in
 * any way, so a unique ID is created here for each item. Dates also converted. Returns all items without pagination.
 * @param configuration Axios configuration argument.
 * @returns             A promise of all interactions.
 */
export async function requestAllInteractions(url: string, configuration: AxiosRequestConfig = {}) {
    const data = await getAllPaginatedItems<IBackendInteractionSearchItem>(url, configuration);
    return formatInteractions(data);
}
