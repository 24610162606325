import { AnyAction } from "redux";
import { hideLoading, showLoading } from "react-redux-loading-bar";
import { ThunkAction } from "redux-thunk";

import { IApplicationState } from "redux/types";

import actions from "./loading-blocker-actions";

type ILoadingBlockThunk = ThunkAction<any, IApplicationState, void, AnyAction>;

/**
 * Hide the loading blocker.
 * @param hideLoadingBar Whether or not to hide the loading bar as well.
 * @returns              Hide loading blocker thunk action.
 */
function hideLoadingBlocker(hideLoadingBar: boolean = false): ILoadingBlockThunk {
    return (dispatch) => {
        if (hideLoadingBar) {
            dispatch(hideLoading());
        }
        dispatch(actions.hideLoadingBlocker());
    };
}

/**
 * Show the loading blocker.
 * @param showLoadingBar Whether or not to show the loading bar as well.
 * @returns              Show loading blocker thunk action.
 */
function showLoadingBlocker(showLoadingBar: boolean = false): ILoadingBlockThunk {
    return (dispatch) => {
        if (showLoadingBar) {
            dispatch(showLoading());
        }
        dispatch(actions.showLoadingBlocker());
    };
}

export default { hideLoadingBlocker, showLoadingBlocker };
