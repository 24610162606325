import { ISetupData } from "redux/modules/setup/setup.types";

import SetupType from "./setup-type";

const createAction = <T extends { type: SetupType }>(action: T) => action;

/**
 * Store the setup data.
 * @param payload The setup data from the backend.
 * @returns       Store setup data action.
 */
const storeSetupData = (payload: ISetupData) => createAction({ type: SetupType.Store, payload });

export default { storeSetupData };
