const icon = require("images/ico-96.png");
const TITLE = "Arthur";

/**
 * Ask the user to allow desktop notifications.
 */
export function requestPermission(): void {
    if (
        typeof Notification !== "undefined" &&
        Notification.permission !== "denied" &&
        Notification.permission !== "granted"
    ) {
        Notification.requestPermission();
    }
}

/**
 * Show a notification for a given period of time.
 * @param body         Notification text.
 * @param milliseconds How long the notification should display before closing.
 */
export function showNotification(body: string, milliseconds: number = 15000): void {
    if (typeof Notification !== "function" || Notification.permission !== "granted") {
        return;
    }

    // Show the notification and set a timer to close it.
    const notification = new Notification(TITLE, { body, icon, requireInteraction: true });
    const timeout = window.setTimeout(notification.close.bind(notification), milliseconds);

    // Return the user to the application when the notification is clicked.
    notification.onclick = function (): void {
        window.clearTimeout(timeout);
        window.focus();
        this.close();
    };
}
