import { createContext } from "react";
import { doNothing } from "@edgetier/utilities";

import { ICategoryNavigationContext } from "./category-navigation-context.types";

export const defaultContext: ICategoryNavigationContext = {
    close: doNothing,
};

const CategoryNavigationContext = createContext(defaultContext);
export default CategoryNavigationContext;
