import { FunctionComponent, memo } from "react";
import { formatDistance } from "date-fns";
import InteractionIcon from "components/interaction-icon";
import InteractionLabel from "components/interaction-label";
import HandlingType from "constants/handling-type";
import { TimelineItem } from "components/timeline";
import QueryHistoryTimelineItem from "../query-history-timeline-item";
import { getItemId } from "components-for/query-history/query-history-timeline/query-history-timeline.utilities";
import { isInteractionCurrentlyVisible } from "./query-history-timeline-interaction.utilities";

import { IProps } from "./query-history-timeline-interaction.types";
import "./query-history-timeline-interaction.scss";

/**
 * Display an interaction in a history timeline.
 * @param props.activeInteraction    Current interaction being displayed.
 * @param props.focusItem       The initially selected item.
 * @param props.interaction     Interaction to display.
 * @param props.showPreview     Whether or not to show a preview of each note or interaciton.
 * @param props.warningDateTime Any interactions after this date will have a warning beside them.
 * @returns                     Details of an interaction.
 */
const QueryHistoryTimelineInteraction: FunctionComponent<IProps> = ({
    activeInteraction,
    focusItem,
    interaction,
    showPreview,
    warningDateTime,
}) => (
    <TimelineItem
        autoFocus={
            typeof focusItem !== "undefined" &&
            "interactionId" in focusItem &&
            focusItem.interactionId === interaction.interactionId
        }
        date={interaction.dateTime}
        key={getItemId(interaction)}
        guideOnly={showPreview}
        icon={
            <InteractionIcon
                isCall={"callId" in interaction}
                isChat={"chatId" in interaction}
                isEmailIn={"emailId" in interaction}
                isAutomated={interaction.handlingTypeId === HandlingType.System}
            />
        }
        value={interaction}
    >
        {showPreview && (
            <QueryHistoryTimelineItem
                subject={interaction.subjectPreview}
                body={interaction.bodyPreview}
                classnames={
                    isInteractionCurrentlyVisible(activeInteraction, interaction)
                        ? "query-history-timeline-item--current"
                        : ""
                }
                warning={
                    warningDateTime instanceof Date && interaction.dateTime > warningDateTime
                        ? `${formatDistance(warningDateTime, interaction.dateTime)} after email`
                        : null
                }
            >
                <InteractionLabel interaction={interaction} />
            </QueryHistoryTimelineItem>
        )}
    </TimelineItem>
);

export default memo(QueryHistoryTimelineInteraction);
