import { Fragment, FunctionComponent, memo } from "react";
import { IProps } from "./chat-variable-fields.types";
import useInteractionVariables from "hooks-for/variables/use-interaction-variables";
import useVariables from "hooks-for/variables/use-variables";
import { DetailsFieldTitle, LoadingCell } from "@edgetier/client-components";
import { TVariable } from "@edgetier/types";
import { Tooltip } from "@edgetier/components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import ChatVariableFieldValues from "./chat-variable-field-values";
import "./chat-variable-fields.scss";

/**
 * Request and display interaction variables for a chat
 * @param props.interactionDetailId     Interaction detail ID
 */
const ChatVariableFields: FunctionComponent<IProps> = ({ interactionDetailId }) => {
    const { data: interactionVariables, isLoading: isInteractionVariablesLoading } = useInteractionVariables(
        interactionDetailId! ?? "",
        {},
        { enabled: typeof interactionDetailId === "number" }
    );
    const { data: variables, isLoading: isVariablesLoading } = useVariables(
        {},
        { enabled: typeof interactionDetailId === "number" }
    );

    // Index the variables response for easy access
    const indexVariables: Record<number, TVariable> = (variables ?? []).reduce(
        (variables, currentVariable) => ({ ...variables, [currentVariable.variableId]: currentVariable }),
        {}
    );

    return (
        <Fragment>
            {isInteractionVariablesLoading || isVariablesLoading ? (
                <Fragment>
                    {Array(3)
                        .fill(0)
                        .map((_, index) => (
                            <tr key={`loading-index-${index}`}>
                                <td>
                                    <LoadingCell />
                                </td>
                                <td>
                                    <LoadingCell />
                                </td>
                            </tr>
                        ))}
                </Fragment>
            ) : (
                <Fragment>
                    {(interactionVariables ?? []).map((variable) => {
                        const selectedVariable = indexVariables[variable.variableId];
                        return (
                            <Fragment key={variable.interactionVariableId}>
                                {typeof selectedVariable !== "undefined" && (
                                    <tr className="chat-variable-fields">
                                        <td>
                                            <DetailsFieldTitle>
                                                <div className="chat-variable-fields__title-group">
                                                    <span>{selectedVariable.title}</span>
                                                    {typeof selectedVariable.description === "string" &&
                                                        selectedVariable.description.length > 0 && (
                                                            <Tooltip content={selectedVariable.description}>
                                                                <div className="chat-variable-fields__title-group__info">
                                                                    <FontAwesomeIcon icon={faInfoCircle} />
                                                                </div>
                                                            </Tooltip>
                                                        )}
                                                </div>
                                            </DetailsFieldTitle>
                                        </td>
                                        <td>
                                            <ChatVariableFieldValues
                                                value={variable.value.toString()}
                                                variableType={selectedVariable.variableTypeId}
                                            />
                                        </td>
                                    </tr>
                                )}
                            </Fragment>
                        );
                    })}
                </Fragment>
            )}
        </Fragment>
    );
};

export default memo(ChatVariableFields);
