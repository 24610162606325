import Axios, { AxiosRequestConfig } from "axios";

import {
    IObjectSummary,
    IFilter,
    IActiveInteractionRequests,
    IChangeListener,
    IMakeRequest,
} from "./interaction-decorator.types";

/**
 * create a decorator responsible for fetching aditional data to supplement an interaction payload
 *
 * Provides hooks for:
 *  - registration (setup)
 *  - update (cancels pending requests for removed interactions and initiates any new interations)
 *  - cancelAll (teardown, cancel all requests)
 */
export class InteractionDecorator<T extends IObjectSummary = IObjectSummary> {
    activeRequests: IActiveInteractionRequests = {};
    listeners: IChangeListener<T>[] = [];

    /**
     * provide a filter and a makeRequest function to be conditionally called every time update is called.
     * There is no removeListener because a class instance should be discarded when no longer needed.
     * @param filter       a filter function that takes an interaction (of any type) and returns true if the request is required
     * @param makeRequest  a request function which should handle making any requests, error handling and applying and changes,
     *                     i.e. function may be async but should not return anything
     */
    registerListener(filter: IFilter<T>, makeRequest: IMakeRequest<T>): void {
        this.listeners.push([filter, makeRequest]);
    }

    /**
     * Call update every time there may be new or newly removed interactions
     * For each listener the appropriate filter will be applied prior to applying the 'makeRequest' function
     * @param     oldInteractions       Interactions that were present in the last update.
     * @param     newInteractions       All interactions in the latest update including some from the previous.
     */
    update(oldInteractions: T[], newInteractions: T[]): void {
        const obsoleteInterations = findObsoleteInteractions(oldInteractions, newInteractions);

        this.listeners.forEach(([filter, makeRequest]) => {
            const undecoratedInteractions = findUndecoratedInteractions<T>(oldInteractions, newInteractions, filter);
            requestDecorationForInteractions<T>(undecoratedInteractions, makeRequest, this.getInteractionConfiguration);
            cancelObsoleteRequests(obsoleteInterations, this.activeRequests);
        });
    }

    /**
     * Cancels all active requests which may throw a cancellation error on active requests
     */
    cancelAll(): void {
        Object.values(this.activeRequests).forEach((cancelTokenSource) => cancelTokenSource.cancel());
    }

    /**
     * Create an Axios configuration with a cancel token, stores a cancelToken if one does not exist.
     * @param key          Interaction key to look up existing cancel tokens.
     * @returns            Axios configuration with a cancel token.
     */
    getInteractionConfiguration = (key: string): AxiosRequestConfig => {
        if (!this.activeRequests[key]) {
            this.activeRequests[key] = Axios.CancelToken.source();
        }

        return { cancelToken: this.activeRequests[key].token };
    };
}

/**
 * From a provided filter on an interaction, determine if there are any new interactions that match the filter criteria.
 * @param     oldInteractions       Interactions that were present in the last update.
 * @param     newInteractions       All interactions in the latest update including some from the previous.
 * @param     filter                A predicate function that determines if an interaction is missing specific data.
 * @returns                         An interaction array containing only new interactions mathing the predicate.
 */
function findUndecoratedInteractions<T extends IObjectSummary>(
    oldInteractions: T[],
    newInteractions: T[],
    filter: IFilter<T>
) {
    // Emails/chats that are already known to be missing information. These arrived in the last update so requests
    // for all extra information should already be taking place.
    // NOTE: It is likely that the filter does not need to be applied here.
    const oldKeys = oldInteractions.filter(filter).map(({ key }) => key);

    // Find newly started chats that have missing information.
    return newInteractions.filter(filter).filter(({ key }) => !oldKeys.includes(key));
}

/**
 * Determine what interactions are no longer present but may have ongoing requests that need to be cancelled.
 * @param     oldInteractions       Interactions that were present in the last update.
 * @param     newInteractions       All interactions in the latest update including some from the previous.
 * @returns                         An interaction array containing old interactions that are no longer present.
 */
function findObsoleteInteractions<T extends IObjectSummary>(oldInteractions: T[], newInteractions: T[]) {
    const newInteractionKeys = newInteractions.map(({ key }) => key);
    return oldInteractions.filter(({ key }) => !newInteractionKeys.includes(key));
}

/**
 * Remove requests belonging to emails and chats that have been removed. For example, a chat may end before the
 * booking details request has been fulfilled. In reality, this is extremely unlikely so it's here as a precaution.
 * @param obsoleteInteractions    Emails and chats before an update.
 * @param activeRequests          Emails and chats after an update.
 */
function cancelObsoleteRequests<T extends IObjectSummary>(
    obsoleteInteractions: T[],
    activeRequests: IActiveInteractionRequests
): void {
    // Execute each chat's cancel token and then remove its reference since it's no longer needed.
    obsoleteInteractions.forEach(({ key }) => {
        const cancelTokenSource = activeRequests[key];
        if (typeof cancelTokenSource !== "undefined") {
            cancelTokenSource.cancel();
            delete activeRequests[key];
        }
    });
}

/**
 * Some newly fetched interations may be missing properties that should be fetched asynchonously
 * This fetches one property for each interaction passed in
 *
 * @param missingInteractions Emails or chats that are missing properties.
 * @param makeRequest         Function to retrieve one piece of data that is needed.
 * @param getConfig           Retrieve configuration (or produce new one).
 */
function requestDecorationForInteractions<T extends IObjectSummary>(
    missingInteractions: T[],
    makeRequest: IMakeRequest<T>,
    getConfig: InteractionDecorator["getInteractionConfiguration"]
): void {
    // Request information for any new chats or emails.
    if (missingInteractions.length > 0) {
        // Create cancel tokens for each interaction.
        missingInteractions.forEach((interaction) => {
            makeRequest(interaction, getConfig(interaction.key));
        });
    }
}
