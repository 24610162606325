import { sanitize, addHook } from "dompurify";

/**
 * Add target="_blank" and rel="noopener noreferrer" to open links in a new tab and prevent
 * https://www.owasp.org/index.php/Reverse_Tabnabbing
 */
addHook("afterSanitizeAttributes", (node) => {
    if (node.nodeName.toLowerCase() === "a") {
        node.setAttribute("target", "_blank");
        node.setAttribute("rel", "noopener noreferrer");
    }
});

/**
 * Prepare the HTML string for use in the iFrame by sanitising and setting links to open in a new tab.
 * @param htmlString
 * @returns Sanitised HTML with all anchor tags set to open in new tabs.
 */
export const prepareHtml = (htmlString: string) => {
    const sanitisedHtml = sanitize(htmlString);
    return sanitisedHtml;
};
