import { FunctionComponent, memo, useEffect } from "react";
import { setGlobalContainer } from "react-laag";
import { IProps } from "./stacking-layers.types";
import "./stacking-layers.scss";

/**
 * A div that react-laag and react-modal can use to display elements over all other elements in the application.
 */
const StackingLayers: FunctionComponent<IProps> = () => {
    useEffect(() => {
        setGlobalContainer("stacking-layers");
    }, []);
    return <div id="stacking-layers"></div>;
};

export default memo(StackingLayers);
