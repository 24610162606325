import ChatEvent from "redux/modules/chat/chat-event";
import { getSocket } from "redux/modules/chat/socket";

import { getUserId } from "./local-storage";

/**
 * Enable chat by sending an event to the backend.
 * @returns Promise to return ongoing chats.
 */
export async function enableChat(): Promise<void> {
    const socket = await getSocket();
    const userId = getUserId();
    if (userId === null) {
        return Promise.reject();
    }
    return new Promise<void>((resolve) => socket.emit(ChatEvent.SignIn, { userId }, resolve));
}

/**
 * When an agent's socket disconnects and reconnects again, the backend will need to know their new SID.
 */
export async function refreshSid(): Promise<void> {
    const socket = await getSocket();
    const userId = getUserId();
    if (userId === null) {
        return Promise.reject();
    }
    return new Promise<void>((resolve) => socket.emit(ChatEvent.RefreshSid, { userId }, resolve));
}

/**
 * Disable chat by sending an event to the backend.
 * @returns Promise that signOut event happened.
 */
export async function disableChat(): Promise<void> {
    const socket = await getSocket();
    const userId = getUserId();
    if (userId === null) {
        return Promise.reject();
    }
    return new Promise<void>((resolve) => socket.emit(ChatEvent.SignOut, { userId }, resolve));
}
