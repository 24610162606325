import urlJoin from "url-join";
import { AxiosRequestConfig } from "axios";
import { Url } from "@edgetier/types";
import { emitPromise } from "@edgetier/utilities";
import requestCustomerUrls from "utilities/request-customer-urls";
import InteractionType from "constants/interaction-type";
import { getSocket } from "redux/modules/chat/socket";
import ChatEvent from "redux/modules/chat/chat-event";
import { chatOperations } from "redux/modules/chat";
import { IBackendChat } from "redux/modules/chat/chat.types";
import { ISettings } from "redux/application.types";
import requestCurrentUser from "./request-current-user";
import axios from "./axios";

const ACCEPT_CHAT_TIMEOUT_SECONDS = 15;

/**
 * Either manually or automatically accept a chat depending on the client's settings. If translations are enabled, the
 * agent's language has to be determined based on their user settings. The customer's URL history is also requested so
 * the agent can see what pages the customer was or is on currently.
 * @param chatToken     Identifier for the chat being accepted.
 * @param languageId    Language of the customer.
 * @param enterChat     Action to call after the chat has been accepted.
 * @param configuration Axios configuration for cancelling requests.
 */
export default async function acceptChat(
    chatToken: string,
    languageId: number,
    enterChat: typeof chatOperations.enterChat,
    configuration: AxiosRequestConfig = {}
) {
    // Request the user's language details and system settings.
    const [{ data: user }, { data: settings }] = await Promise.all([
        requestCurrentUser(configuration),
        axios.get<ISettings>(Url.Settings),
    ]);

    // If the agent speaks the language of the customer, no translations are necessary. Otherwise, the backend
    // will perform automatic translations between the agent and customer.
    const agentSpeaksLanguage = user.languages
        .filter(({ interactionTypeId }) => interactionTypeId === InteractionType.Chat)
        .some((language) => language.languageId === languageId);

    const translatedToLanguageId =
        agentSpeaksLanguage || languageId === user.nativeLanguageId ? null : user.nativeLanguageId;

    // Tell the backend that the chat is being accepted and in what language. This is has an unlikely potential to
    // cause problems where the backend starts emitting proposed activities too early before the frontend has the full
    // chat details.
    const socket = await getSocket();
    // Set a timeout because if the emitPromise doesn't resolve the user gets stuck in a loading state.
    await Promise.race([
        emitPromise(socket, ChatEvent.Accept, { chatToken, translatedToLanguageId }),
        new Promise((_resolve, reject) =>
            setTimeout(() => {
                return reject(new Error("Could not accept invite"));
            }, ACCEPT_CHAT_TIMEOUT_SECONDS * 1000)
        ),
    ]);

    // Now get the chat itself once the languages have been updated.
    const { data: chat } = await axios.get<IBackendChat>(urlJoin(Url.Chat, chatToken), {
        params: { translate: translatedToLanguageId !== null },
    });

    // Request the URL(s) the customer has visited or is currently on.
    const customerUrls = await requestCustomerUrls(chat.chatId, configuration);

    // Activate the chat.
    enterChat({ chat, chatToken, customerUrls, settings, translatedToLanguageId });
}
