import storeCustomerUrl from "redux/modules/chat/reducers/store-customer-url";
import { IActionsUnion } from "redux/types";

import actions from "./chat-actions";
import activateChat from "./reducers/activate-chat";
import ChatType from "./chat-type";
import enterChat from "./reducers/enter-chat";
import removeChat from "./reducers/remove-chat";
import removeInvite from "./reducers/remove-invite";
import removeProposedActivity from "./reducers/remove-proposed-activity";
import resumeChats from "./reducers/resume-chats";
import storeProposedActivities from "./reducers/store-proposed-activities";
import setTransferTimeoutId from "./reducers/set-transfer-timeout-id";
import storeInvite from "./reducers/store-invite";
import storeMessage from "./reducers/store-message";
import toggleChat from "./reducers/toggle-chat";
import toggleConnected from "./reducers/toggle-connected";
import toggleProposedActivitiesOpen from "./reducers/toggle-proposed-activities-open";
import toggleTransferring from "./reducers/toggle-transferring";
import updateChat from "./reducers/update-chat";
import updateChatEmail from "./reducers/update-chat-email";
import updateMessage from "./reducers/update-message";
import updateProposedActivity from "./reducers/update-proposed-activity";
import updateTyping from "./reducers/update-typing";
import updateSettings from "./reducers/update-settings";
import { IChatState } from "./chat.types";
import storeWrapUpDraft from "./reducers/store-wrap-up-draft";
import forceDisable from "./reducers/set-has-been-force-disabled";
import toggleChatScreenOpen from "./reducers/toggle-chat-screen-open";

type IChatAction = IActionsUnion<typeof actions>;

// Initially there will be no messages and nobody will be typing.
export const initialState: IChatState = {
    activeChat: null,
    chats: {},
    enabled: false,
    hasBeenForceDisabled: false,
    invites: {},
    isChatScreenOpen: false,
    isConnected: true,
    maximumConcurrentChats: 0,
    proposedActivitiesOpen: true,
};

/**
 * Handle updates to the chat state such as sending/receiving messages, setting the typing state of the agent etc.
 * @param state  Chat state before handling the action.
 * @param action Action to update the chat state.
 * @returns      The chat state after applying the action.
 */
export default function (state: IChatState = initialState, action: IChatAction): IChatState {
    switch (action.type) {
        case ChatType.ActivateChat:
            return activateChat(state, action);

        case ChatType.EnterChat:
            return enterChat(state, action);

        case ChatType.RemoveChat:
            return removeChat(state, action);

        case ChatType.ResumeChats:
            return resumeChats(state, action);

        case ChatType.RemoveInvite:
            return removeInvite(state, action);

        case ChatType.RemoveProposedActivity:
            return removeProposedActivity(state, action);

        case ChatType.SetHasBeenForceDisabled:
            return forceDisable(state, action);

        case ChatType.SetTransferTimeoutId:
            return setTransferTimeoutId(state, action);

        case ChatType.StoreCustomerUrl:
            return storeCustomerUrl(state, action);

        case ChatType.StoreInvite:
            return storeInvite(state, action);

        case ChatType.StoreMessage:
            return storeMessage(state, action);

        case ChatType.StoreProposedActivities:
            return storeProposedActivities(state, action);

        case ChatType.StoreWrapUpDraft:
            return storeWrapUpDraft(state, action);

        case ChatType.ToggleChat:
            return toggleChat(state, action);

        case ChatType.ToggleChatScreenOpen:
            return toggleChatScreenOpen(state, action);

        case ChatType.ToggleConnected:
            return toggleConnected(state, action);

        case ChatType.ToggleProposedActivitiesOpen:
            return toggleProposedActivitiesOpen(state);

        case ChatType.ToggleTransferring:
            return toggleTransferring(state, action);

        case ChatType.UpdateChat:
            return updateChat(state, action);

        case ChatType.UpdateChatEmail:
            return updateChatEmail(state, action);

        case ChatType.UpdateMessage:
            return updateMessage(state, action);

        case ChatType.UpdateProposedActivity:
            return updateProposedActivity(state, action);

        case ChatType.UpdateTyping:
            return updateTyping(state, action);

        case ChatType.UpdateSettings:
            return updateSettings(state, action);

        default:
            return state;
    }
}
