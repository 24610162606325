import { TimeUnit } from "./time-unit";

/**
 * Convert seconds to another time unit of appropriate.
 * @param seconds Original seconds value.
 * @returns       Converted value.
 */
export const convertSeconds = (seconds: number | null): [number | null, TimeUnit] => {
    if (typeof seconds !== "number") {
        return [seconds, TimeUnit.Seconds];
    }

    // Convert to hours.
    if (seconds > 6000) {
        return [seconds / 3600, TimeUnit.Hours];
    }
    // Convert to minutes.
    if (seconds > 60) {
        return [seconds / 60, TimeUnit.Minutes];
    }
    // Keep as seconds.
    return [seconds, TimeUnit.Seconds];
};
