import { memo, useEffect, useRef, useState } from "react";
import { Button } from "@edgetier/components";
import { faEllipsisV, faPlus } from "@fortawesome/free-solid-svg-icons";

import NoteModal from "components-for/notes/note-modal";
import { IQueryHistoryItem } from "redux/application.types";
import { Timeline } from "components/timeline";

import QueryHistoryTimelineInteraction from "./query-history-timeline-interaction";
import QueryHistoryTimelineNote from "./query-history-timeline-note";
import { getItemId, getSortOrder } from "./query-history-timeline.utilities";
import { IProps } from "./query-history-timeline.types";
import { INTERACTION_PER_PAGE } from "./query-history-timeline.constants";
import "./query-history-timeline.scss";

/**
 * Show a timeline of notes, interactions, and external interactions.
 * @param props.activeInteraction    Current interaction being displayed.
 * @param props.initialValue         Item to select on load.
 * @param props.interactionDetailId  Optional identifier for adding notes.
 * @param props.interactions         Interactions from our database.
 * @param props.notes                All notes belonging to the query.
 * @param props.onItemsChanged       Optional method when an item is added or removed.
 * @param props.onSelect             Method to call when an item is clicked.
 * @param props.queryId              Identifier for the query these items belong to.
 * @param props.showPreview          Whether or not to show a preview of each note or interaction
 * @param props.submitOnChange       When true, keyboard navigation will select items.
 * @param props.warningDateTime      Any interactions after this date will have a warning beside them.
 * @returns                          Timeline of interactions, notes, and external interactions.
 */
const QueryHistoryTimeline = ({
    activeInteraction,
    initialValue,
    interactionDetailId,
    interactions,
    notes,
    onItemsChanged,
    onSelect,
    queryId,
    showPreview,
    submitOnChange = false,
    warningDateTime,
}: IProps) => {
    const previousItemsCount = useRef<number | null>(null);

    // Select interactions that have the same queryId
    const thisQueryInteractions = interactions.filter((interaction) => interaction.queryId === queryId);

    // The selected item is either explicitly stated or, if not, it will be the first item in the timeline.
    const sortIndexes = getSortOrder(thisQueryInteractions, notes);
    const combined = ([] as IQueryHistoryItem[]).concat(thisQueryInteractions, notes);

    const firstItem = combined.find((_, index) => index === sortIndexes[0]);
    const [focusItem, setFocusItem] = useState(initialValue ? initialValue : firstItem);
    const [totalDisplayedItems, setTotalDisplayedItems] = useState<number>(INTERACTION_PER_PAGE);

    // As items are added or removed, focus on the first item. This is mostly here so that new notes are displayed after
    // being created. And after deleting a note it should not be visible on the screen any more.
    useEffect(() => {
        if (typeof previousItemsCount.current === "number" && previousItemsCount.current !== combined.length) {
            setFocusItem(firstItem);
            if (typeof onItemsChanged === "function") {
                onItemsChanged(firstItem);
            }
        }
        previousItemsCount.current = combined.length;
    }, [combined.length, firstItem, onItemsChanged, onSelect]);

    const interactionsList = thisQueryInteractions.map((interaction) => (
        <QueryHistoryTimelineInteraction
            activeInteraction={activeInteraction}
            key={getItemId(interaction)}
            focusItem={focusItem}
            interaction={interaction}
            showPreview={showPreview}
            warningDateTime={warningDateTime}
        />
    ));

    const notesList = notes.map((note) => (
        <QueryHistoryTimelineNote
            key={getItemId(note)}
            focusItem={focusItem}
            interactionDetailId={interactionDetailId}
            note={note}
            showPreview={showPreview}
        />
    ));

    // Sort the items by date and display only a number of them
    const combinedLists = interactionsList.concat(notesList);
    const items = sortIndexes
        .map((index) => combinedLists[index])
        .slice(0, showPreview ? totalDisplayedItems : combinedLists.length);

    return (
        <div className="query-history-timeline">
            <NoteModal interactionDetailId={interactionDetailId} queryId={queryId}>
                {({ open }) => (
                    <Button className="button--no-modal" icon={faPlus} onClick={open} styleName="positive">
                        Add Note
                    </Button>
                )}
            </NoteModal>
            {showPreview && <div className="interaction__contact-history__title">This Query</div>}
            <div className="query-history-timeline__current">
                {items.length > 0 && typeof focusItem !== "undefined" ? (
                    <Timeline<IQueryHistoryItem>
                        getItemId={getItemId}
                        initialValue={focusItem}
                        onSelect={onSelect}
                        submitOnChange={submitOnChange}
                        hasMoreItems={showPreview ? totalDisplayedItems < combinedLists.length : false}
                    >
                        {items}
                    </Timeline>
                ) : (
                    <div className="query-history-timeline__current--empty">No previous interactions found</div>
                )}
                {showPreview && totalDisplayedItems < combinedLists.length && (
                    <Button
                        icon={faEllipsisV}
                        onClick={() => setTotalDisplayedItems(totalDisplayedItems + INTERACTION_PER_PAGE)}
                    >
                        Load More
                    </Button>
                )}
            </div>
        </div>
    );
};

export default memo(QueryHistoryTimeline);
