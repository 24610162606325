import { useQuery, UseQueryOptions } from "react-query";
import { TInteractionVariable, Url } from "@edgetier/types";
import { AxiosError } from "axios";
import { getAllPaginatedItems } from "utilities/get-all-paginated-items";

/**
 * Request the interaction variables from the backend.
 * @param url
 * @param queryParameters
 */
const requestInteractionVariables = async (url: string, queryParameters: {}) => {
    const data = await getAllPaginatedItems<TInteractionVariable>(url, { params: queryParameters });
    return data;
};

/**
 * Hook to get interaction variables from the backend.
 * @param props.interactionDetailId Detail Id for an interaction
 * @param props.queryParameters     Request parameters
 * @param props.options             Optional react-query options
 */
const useInteractionVariables = <T = TInteractionVariable[]>(
    interactionDetailId: number,
    queryParameters: {} = {},
    options: Omit<UseQueryOptions<TInteractionVariable[], AxiosError, T>, "queryKey" | "queryFn"> = {}
) => {
    const url = Url.InteractionVariables.replace(":interactionDetailId", interactionDetailId.toString());
    return useQuery<TInteractionVariable[], AxiosError, T>(
        [url, queryParameters],
        () => requestInteractionVariables(url, queryParameters),
        options
    );
};

export default useInteractionVariables;
