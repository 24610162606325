interface IOptions {
    readonly quote?: boolean;
    readonly sort?: boolean;
}

/**
 * Create a comma separated list with a separator like "and" or "or".
 *
 * @param conjunction And/or etc. used to split words.
 * @param quote       Wrap each item in quotes.
 * @param sort        Whether to alphabetically sort or not.
 * @returns           String containing items.
 */
function formatList(conjunction: string, values: string[], options: IOptions = {}) {
    if (values.length === 0) {
        return "";
    }

    const { quote, sort } = options;

    // Sort the values alphabetically.
    const sorted =
        sort === true
            ? values.slice().sort((one: string, two: string) => one.toLowerCase().localeCompare(two.toLowerCase()))
            : values;

    const quoteValues = quote === true ? sorted.map((item: string) => `"${item}"`) : sorted;

    if (quoteValues.length <= 1) {
        return quoteValues[0].toString();
    }

    const initial = quoteValues.slice(0, values.length - 1);
    return `${initial.join(", ")} ${conjunction} ${quoteValues[values.length - 1]}`;
}

export const andList = formatList.bind(null, "and");
export const orList = formatList.bind(null, "or");
export const ampersandList = formatList.bind(null, "&");
