import React from "react";
import { DetailsFieldTitle, DetailsFieldValue, SplitTimestamp } from "@edgetier/client-components";

import { IProps } from "./chat-and-call-dates.types";

/**
 * Render the dates associated with a chat or a call.
 * @param props Props containing the start and finish dates of the chat/call.
 * @returns     Date table rows.
 */
export default ({ agentFinishTime, date }: IProps) => (
    <React.Fragment>
        <tr>
            <td>
                <DetailsFieldTitle>Started</DetailsFieldTitle>
            </td>
            <td>
                <DetailsFieldValue copyText={date.toISOString()}>
                    <SplitTimestamp date={date} />
                </DetailsFieldValue>
            </td>
        </tr>
        {agentFinishTime instanceof Date && (
            <tr>
                <td>
                    <DetailsFieldTitle>Finished</DetailsFieldTitle>
                </td>
                <td>
                    <DetailsFieldValue copyText={agentFinishTime.toISOString()}>
                        <SplitTimestamp date={agentFinishTime} />
                    </DetailsFieldValue>
                </td>
            </tr>
        )}
    </React.Fragment>
);
