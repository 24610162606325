import View from "constants/view";
import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import ChatEvent from "redux/modules/chat/chat-event";
import { getSocket } from "redux/modules/chat/socket";
import { toastOperations } from "redux/modules/toast";

/**
 * Listen for the administrator sign out event and redirect the user to the login page with a toast message when the
 * event is received.
 */
export const useUserForceSignOutListener = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const signOutUser = useCallback(() => {
        history.push(View.Login);
        dispatch(
            toastOperations.showInfoToast("Signed Out", "You have been signed out by an administrator.", {
                removeOnHover: false,
                timeOut: 0,
            })
        );
    }, [dispatch, history]);

    useEffect(() => {
        const createSocketEventListener = async () => {
            const socket = await getSocket();
            socket.on(ChatEvent.AdministratorSignOut, signOutUser);
        };
        createSocketEventListener();

        return () => {
            (async () => {
                const socket = await getSocket();
                socket.off(ChatEvent.UserStateUpdate);
            })();
        };
    }, [signOutUser]);
};
