import React from "react";
import { Field, FieldProps } from "formik";
import Axios from "axios";

import { IProps, IState } from "./inner-field.types";

/**
 * Wrap a Formik field to allow toggling translations. Every time the translation is turned on, a request is made to
 * the backend with the current value to be translated. Before this is done, the original value is stored in this
 * component's state so that the user can return to the untranslated value if they like.
 */
export default class ToggleTranslationField extends React.PureComponent<IProps, IState> {
    // Initially the component is not loading, and takes the same translation state as the context.
    state: IState = {
        activeLanguageId: this.props.preferredLanguageId,
        isLoading: false,
        isTranslated: this.props.isTranslated,
    };

    /**
     * A request may need to be made to preform the translation if it is not already available.
     * @param _     Previous props (unused).
     * @param state Previous state before update.
     */
    async componentDidUpdate(previousProps: IProps): Promise<void> {
        const {
            onTranslationChange,
            preferredLanguageId,
            requestTranslation,
            secondaryLanguageId,
            setFieldValue,
            translations,
        } = this.props;
        const translationObjectFieldName = `${this.props.name}[${this.props.secondaryLanguageId}]`;

        if (!this.state.isTranslated && this.props.isTranslated && !this.state.isLoading) {
            try {
                this.setState({ isLoading: true });

                // Request the translation and update the field with its value.
                const translation = await requestTranslation(translations[preferredLanguageId].translation!);
                setFieldValue(translationObjectFieldName, {
                    fromLanguageId: preferredLanguageId,
                    toLanguageId: secondaryLanguageId,
                    isFirst: true,
                    isOriginal: false,
                    isEdited: true,
                    translation,
                });
                onTranslationChange && onTranslationChange(secondaryLanguageId);
            } catch (serverError) {
                if (Axios.isAxiosError(serverError)) {
                    this.props.showServerError(serverError);
                }
            } finally {
                // Remove the pending request to maybe enable the toggle button.
                this.setState({
                    activeLanguageId: secondaryLanguageId,
                    isLoading: false,
                    isTranslated: true,
                });
            }
        } else if (this.state.isTranslated && !this.props.isTranslated) {
            // Switch back to the preferred language and clear previous translation.
            setFieldValue(translationObjectFieldName, undefined);
            onTranslationChange && onTranslationChange(preferredLanguageId);
            return this.setState({
                activeLanguageId: this.props.preferredLanguageId,
                isLoading: false,
                isTranslated: false,
            });
        } else if (previousProps.preferredLanguageId !== this.props.preferredLanguageId) {
            // The content hasn't been translated but the agent has changed their language.
            this.setState({ activeLanguageId: this.props.preferredLanguageId });
        }
    }

    /**
     * Field input.
     * @returns Formik field component.
     */
    render(): React.ReactNode {
        const { children, isTranslated, ...fieldProps } = this.props;
        const isLoading = this.state.isTranslated === false && isTranslated === true;

        return (
            <Field {...fieldProps} name={`${this.props.name}[${this.state.activeLanguageId}].translation`}>
                {(fieldProps: FieldProps) => children({ isLoading, ...fieldProps })}
            </Field>
        );
    }
}
