import urlJoin from "url-join";
import { AxiosRequestConfig } from "axios";
import { Url } from "@edgetier/types";

import { IUser } from "redux/application.types";

import { getUserId } from "./local-storage";
import axios from "./axios";

/**
 * Request the user's own data.
 * @returns Promise of user data.
 */
export default function requestCurrentUser(configuration: AxiosRequestConfig = {}) {
    const userId = getUserId();
    if (userId === null) {
        return Promise.reject("Not Found");
    }
    return axios.get<IUser>(urlJoin(Url.Users, userId.toString()), configuration);
}
