import { TooltipWarning } from "@edgetier/client-components";

import { IProps } from "./query-history-timeline-item-warning.types";
import "./query-history-timeline-item-warning.scss";

/**
 * Warning about a query history item. This is to warn agents when, for example, a customer calls while waiting for a
 * response to an email. This can impact how an agent handles a query since it may already be solved or the details have
 * changed.
 * @param warning Warning message.
 * @returns       Warning message tooltip.
 */
const QueryHistoryTimelineItemWarning = ({ warning }: IProps) => (
    <div className="query-history-timeline-item-warning">
        <TooltipWarning warning={warning} />
    </div>
);

export default QueryHistoryTimelineItemWarning;
