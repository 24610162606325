import { faUsers } from "@fortawesome/free-solid-svg-icons/faUsers";
import View from "constants/view";
import { NavigationLink } from "@edgetier/client-components";

/**
 * Link to the users screen.
 * @returns A link to the page.
 */
const UsersLink = () => <NavigationLink label="Users" icon={faUsers} page={View.Users} />;

export default UsersLink;
