import React from "react";

/**
 * Private component to render the button
 * @param onSelect    Value-bound select function
 * @param children    The button label
 */
export default function ShortcutButton({ onSelect, children }: { onSelect: () => void; children: React.ReactNode }) {
    return (
        <button className="button button--positive button--small" type="button" onClick={onSelect}>
            {children}
        </button>
    );
}
