import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import InteractionEventName from "./interaction-event-name";
import InteractionEventTime from "./interaction-event-time";
import { IProps } from "./interaction-event.types";
import "./interaction-event.scss";

/**
 * Render the name, time and an icon for a call or chat event.
 * @param props.event     Call or chat event.
 * @param props.icon      Icon suiting the event type.
 * @param props.styleName Colour for the icon.
 * @returns               Event name, icon, and time.
 */
const InteractionEvent = ({ event, icon, styleName = "primary" }: IProps) => (
    <div className={classNames("interaction-event", `interaction-event--${styleName}`)}>
        <InteractionEventTime event={event} />
        <div className="interaction-event__icon">
            <FontAwesomeIcon icon={icon} />
        </div>
        <InteractionEventName event={event} />
    </div>
);

export default InteractionEvent;
