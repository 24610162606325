import React from "react";
import "./version.scss";

/**
 * Display the version of the application. This is passed in from package.json as an environment variable.
 * @returns The version number.
 */
const Version = () => {
    const version = process.env.REACT_APP_VERSION;
    return version ? <div className="login__version">v{version}</div> : null;
};

export default Version;
