import { ParsedQs } from "qs";

/**
 * Clear URL of any prefixed parameters
 * @param searchParameters  Parameters parsed
 * @param prefix            Prefix for clearing the parameters
 * @returns All parameters excluding the prefixed parameters
 */
export const clearUrlParameters = (searchParameters: ParsedQs | Record<string, any>, prefix?: string) => {
    // Return the search parameters passed if prefix is empty
    if (typeof prefix === "string" && prefix.trim() !== "") {
        const clearedSearchParameters = Object.entries(searchParameters).filter(
            ([searchEntry]) => !new RegExp(`^${prefix}\\..+`).test(searchEntry)
        );
        return Object.fromEntries(clearedSearchParameters);
    } else {
        return searchParameters;
    }
};
