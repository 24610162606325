import React from "react";

import { IProps } from "./details-section-title.types";
import "./details-section-title.scss";

/**
 * Title for a section of miscellaneous interaction details.
 * @param props.children Optional content to show beside the title.
 * @param props.title    Title text.
 * @returns              A title and maybe some other content.
 */
const DetailsSectionTitle = ({ children, title }: IProps) => (
    <div className="details-section-title">
        <h5>{title}</h5>
        {children}
    </div>
);

export default DetailsSectionTitle;
