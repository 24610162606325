import { IBooleanFormComponent } from "@edgetier/types";

import FormField from "./form-field";

/**
 * Boolean fields are displayed as a single checkbox.
 */
export default class BooleanFormField extends FormField<IBooleanFormComponent, boolean | null> {
    /**
     * Clean the value before submitting it. This isn't needed for boolean values.
     * @returns The value.
     */
    formatValue(value: boolean) {
        return value;
    }

    /**
     * Initialise the field with either a sensible default or a default provided by the backend.
     * @returns Default value if provided or a value of false.
     */
    getDefaultValue() {
        return this.configuration.defaultValue === null ? false : this.configuration.defaultValue;
    }

    /**
     * Check for a blank value. This is used to display a "required" error.
     * @param value Current field value.
     * @returns     True if the value is not blank.
     */
    isNotBlank() {
        return true;
    }

    /**
     * Checkboxes do not have to be clicked, so by default it is valid.
     * @returns True.
     */
    isValid() {
        return true;
    }
}
