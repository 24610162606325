import { DetailsFieldTitle, DetailsFieldValue } from "@edgetier/client-components";

import BlankField from "../blank-field";

import { IProps } from "./form-submission-select-multiple-field.types";
import "./form-submission-select-multiple-field.scss";

/**
 * Display a select field submission that allows multiple values e.g. checkboxes or drop-downs.
 * @param props.field Select, checkboxes or similar field submission.
 * @returns           Field title and list of selected values.
 */
const FormSubmissionSelectMultipleField = ({ field }: IProps) => (
    <tr className="form-submission-select-multiple-field">
        <td colSpan={2}>
            <DetailsFieldTitle>{field.formFieldTranslation}</DetailsFieldTitle>

            {!Array.isArray(field.value) || field.value.length === 0 ? (
                <BlankField />
            ) : (
                <DetailsFieldValue>
                    {field.value.length === 1 ? (
                        <div className="form-submission-select-multiple-field--single">{field.value[0]}</div>
                    ) : (
                        <ul>
                            {field.value.map((value) => (
                                <li key={value}>{value}</li>
                            ))}
                        </ul>
                    )}
                </DetailsFieldValue>
            )}
        </td>
    </tr>
);

export default FormSubmissionSelectMultipleField;
