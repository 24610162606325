import { createContext } from "react";
import { IModalUtilities } from "types";
import { doNothing } from "@edgetier/utilities";

const defaultContext: IModalUtilities = {
    canClose: true,
    closeModal: doNothing,
    isOpen: false,
    onCloseModal: doNothing,
    openModal: doNothing,
};

export const ModalContext = createContext(defaultContext);
