import React from "react";

import View from "constants/view";

import QueryLinkIndicators from "./query-link-indicators";
import { NavigationLink } from "@edgetier/client-components";
import { faEnvelope } from "@fortawesome/pro-solid-svg-icons";

/**
 * Link to the query screen.
 * @returns A link to the page.
 */
const QueryLink = () => (
    <NavigationLink label="Email" icon={faEnvelope} page={View.Query}>
        <QueryLinkIndicators />
    </NavigationLink>
);

export default QueryLink;
