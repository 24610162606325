import React, { FunctionComponent, memo } from "react";

import { IProps } from "./modal-content.types";

/**
 * The content of the modal.
 * @param props.children Modal components.
 */
const ModalContent: FunctionComponent<IProps> = ({ children }) => <div className="modal-content">{children}</div>;

export default memo(ModalContent);
