import React from "react";

import { IProps } from "./accordion-item.types";
import "./accordion-item.scss";

import AccordionItemProvider from "~/accordion/accordion-item-provider";
import AccordionItemContainer from "~/accordion/accordion-item-container";

/**
 * Container for an accordion item's header and content.
 * @param props.canOpen  If the accordion has content to show.
 * @param props.children Accordion item header and content.
 * @param props.isOpen   Default open state of the item.
 * @returns              Header and content of an accordion item.
 */
const AccordionItem = ({ canOpen = true, children, isOpen = false }: IProps) => (
    <AccordionItemProvider canOpen={canOpen} isOpen={isOpen}>
        <AccordionItemContainer>{children}</AccordionItemContainer>
    </AccordionItemProvider>
);

export default AccordionItem;
