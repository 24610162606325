import { IconDefinition } from "@fortawesome/free-regular-svg-icons";
import { faFile } from "@fortawesome/free-regular-svg-icons/faFile";
import { faFileAlt } from "@fortawesome/free-regular-svg-icons/faFileAlt";
import { faFileArchive } from "@fortawesome/free-regular-svg-icons/faFileArchive";
import { faFileAudio } from "@fortawesome/free-regular-svg-icons/faFileAudio";
import { faFileExcel } from "@fortawesome/free-regular-svg-icons/faFileExcel";
import { faFileImage } from "@fortawesome/free-regular-svg-icons/faFileImage";
import { faFilePdf } from "@fortawesome/free-regular-svg-icons/faFilePdf";
import { faFilePowerpoint } from "@fortawesome/free-regular-svg-icons/faFilePowerpoint";
import { faFileVideo } from "@fortawesome/free-regular-svg-icons/faFileVideo";
import { faFileWord } from "@fortawesome/free-regular-svg-icons/faFileWord";

interface IIcon {
    readonly icon: IconDefinition;
    readonly pattern: RegExp;
}

export const DEFAULT_ICON = faFile;

export const ICONS: IIcon[] = [
    { icon: faFileImage, pattern: /jpe?g|png|gif|bmp|webp/i },
    { icon: faFileArchive, pattern: /zip|tar/i },
    { icon: faFileVideo, pattern: /avi|wmv|flv|mpg|mp4|mov|mpeg/i },
    { icon: faFileAudio, pattern: /wav|mp3|ogg|wma|flac/ },
    { icon: faFilePdf, pattern: /pdf/i },
    { icon: faFileAlt, pattern: /txt/i },
    { icon: faFileWord, pattern: /docx?/i },
    { icon: faFileExcel, pattern: /xlsx?/i },
    { icon: faFilePowerpoint, pattern: /pptx?/i },
];
