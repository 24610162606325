/**
 * Group object of items by some property.
 * @param items  Array of objects.
 * @param getKey Function to get grouping property.
 * @returns      Dictionary from key to items.
 *
 * @example
 * // Returns {"a": [{ key: "a", value: 1 }], "b": [{ key: "b", value: 2 }]}
 * groupBy([{ key: "a", value: 1 }, { key: "b", value: 2 }], (({ key }) => key);
 */
export const groupBy = <Item extends {}>(items: Item[], getKey: (item: Item) => string) => {
    const grouped: { [index: string]: Item[] } = {};
    items.forEach((item) => {
        const key = getKey(item);
        if (Array.isArray(grouped[key])) {
            grouped[key].push(item);
        } else {
            grouped[key] = [item];
        }
    });
    return grouped;
};
