import urljoin from "url-join";
import { Url } from "@edgetier/types";

import { IInteractionSearchItem } from "components/interaction-search/interaction-search.types";
import { IInteraction } from "redux/application.types";

/**
 * Extract information from an interaction depending on the type of interaction that it is.
 * @param interaction The interaction from which the information is being extracted.
 * @returns An object containing the name of the ID, the ID value and the URL for requesting the interaction.
 */
const getInteractionInformation = (interaction: IInteractionSearchItem | IInteraction) => {
    if ("callId" in interaction && typeof interaction.callId === "number") {
        return { idName: "callId", idValue: interaction.callId, url: urljoin(Url.Call, interaction.callId.toString()) };
    }

    if ("chatId" in interaction && typeof interaction.chatId === "number") {
        return { idName: "chatId", idValue: interaction.chatId, url: urljoin(Url.Chat, interaction.chatId.toString()) };
    }

    if ("emailId" in interaction && typeof interaction.emailId === "number") {
        return {
            idName: "emailId",
            idValue: interaction.emailId,
            url: urljoin(Url.Emails, interaction.emailId.toString()),
        };
    }

    if ("outboundEmailActionId" in interaction && typeof interaction.outboundEmailActionId === "number") {
        return {
            idName: "outboundEmailActionId",
            idValue: interaction.outboundEmailActionId,
            url: urljoin(Url.OutboundEmailActions, interaction.outboundEmailActionId.toString()),
        };
    }

    throw new Error("Unknown interaction type");
};

export default getInteractionInformation;
