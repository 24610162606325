import { IActionsUnion } from "redux/types";
import { IModalState } from "redux/modules/modal/modal.types";

import actions from "./modal-actions";
import ModalType from "./modal-type";

type Action = IActionsUnion<typeof actions>;

// Show nothing by default.
const initialState: IModalState = { title: null, message: null };

/**
 * Show/hide a modal with customisable title and content.
 * @param state   Current modal state.
 * @param action  Show or hide action.
 * @returns       New modal state.
 */
export default function reducer(state: IModalState = initialState, action: Action): IModalState {
    switch (action.type) {
        case ModalType.Hide:
            return { title: null, message: null };
        case ModalType.Show:
            return { ...action.payload };
        default:
            return state;
    }
}
