import InteractionEvents from "components/interaction-events";
import { IProps } from "./call.types";
import CallEvent from "./call-event";
import "./call.scss";

/**
 * Display a call interaction in the Thread modal.
 * @param props.events Call events.
 */
const Call = ({ events }: IProps) => (
    <InteractionEvents>
        {events.map((event) => (
            <CallEvent event={event} key={`${event.dateTime}-${event.callEventType}`} />
        ))}
    </InteractionEvents>
);

export default Call;
