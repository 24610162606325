import SharedAttachments from "shared/attachments";
import { ErrorModal, ServerErrorModal } from "shared/modal";
import DeleteAttachmentButton from "components/delete-attachment-button";
import AttachmentTooltipContent from "components/attachment-tooltip-content";
import { IProps } from "./attachments.types";
import "./attachments.scss";

/**
 * Wrapper around attachments component from the shared library that just adds error handling.
 */
const Attachments = ({ attachments, getAttachment, limitHeight }: IProps) => (
    <ServerErrorModal>
        {(showServerError) => (
            <ErrorModal>
                {(showError) => (
                    <SharedAttachments
                        attachments={attachments}
                        getAttachment={getAttachment}
                        limitHeight={limitHeight}
                        onBlocked={() =>
                            showError("Attachments Blocked", "You must enable pop-ups to view attachments.")
                        }
                        onServerError={showServerError}
                    >
                        {(attachment) =>
                            attachment.deleted ? (
                                <AttachmentTooltipContent attachment={attachment} />
                            ) : (
                                <DeleteAttachmentButton attachment={attachment} />
                            )
                        }
                    </SharedAttachments>
                )}
            </ErrorModal>
        )}
    </ServerErrorModal>
);

export default Attachments;
