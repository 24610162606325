import { IProps } from "./raw-text.types";
import "./raw-text.scss";

/**
 * Display the contents of an email or chat with new lines preserved.
 * @param props.children Email or chat message content.
 * @returns              Text content.
 */
const RawText = ({ children }: IProps) => <p className="raw-text">{children}</p>;

export default RawText;
