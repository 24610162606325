import React from "react";
import Axios from "axios";
import { connect } from "react-redux";
import urlJoin from "url-join";
import { Url } from "@edgetier/types";

import axios from "utilities/axios";
import { IApplicationState } from "redux/types";
import { loadingBlockerOperations } from "redux/modules/loading-blocker";
import { emailOperations } from "redux/modules/email";
import { InteractionDecorator } from "services/utilities/interaction-decorator";
import { IQueryEmail, IBlankEmail } from "redux/modules/email/email.types";
import InteractionType from "constants/interaction-type";
import formatProposedActions from "utilities/format-proposed-actions";
import { showNotification } from "utilities/browser-notifications";

import { requestAgentInformation } from "../utilities/common-interaction-decorators";

import { IProps, IStateProps, IProposedActionsResponse, IEmailSummary } from "./email-service.types";

export class EmailService extends React.PureComponent<IProps> {
    cancelTokenSource = Axios.CancelToken.source();
    // Cancel token for email requests. These may be cancelled if the user disables email.
    emailCancelTokenSource = Axios.CancelToken.source();

    decorator = new InteractionDecorator<IEmailSummary>();

    componentDidMount(): void {
        // Maybe request an email if the agent has email enabled.
        this.requestEmail();

        this.decorator.registerListener(
            ({ hasAgentInformation }) => !hasAgentInformation,
            (email) =>
                requestAgentInformation<IEmailSummary>(
                    email,
                    this.decorator.getInteractionConfiguration(email.key),
                    this.updateEmail,
                    this.props.onServerError
                )
        );

        this.decorator.registerListener(({ id, interactionTypeId, hasProposedActions }) => {
            // Ensure email, valid id & that we don't already have proposedActions
            return interactionTypeId === InteractionType.Email && typeof id === "number" && !hasProposedActions;
        }, this.requestProposedActions);
    }

    componentDidUpdate(previousProps: IProps): void {
        this.decorator.update(previousProps.emails, this.props.emails);

        // Show a browser notification to the user if a new email arrives and they haven't seen it. We can't check if
        // props.isQueueEmpty === false and compare to previous props because it's true until the agent accepts the email.
        const newEmailAvailable = previousProps.isEmailAvailable === false && this.props.isEmailAvailable === true;
        if (previousProps.isQueueEmpty === true && newEmailAvailable) {
            showNotification("New email available", 5000);
        }

        // When a user enables email, an email should be requested. When they disable it, any requests for an email
        // should be cancelled.
        if (previousProps.isEmailEnabled === false && this.props.isEmailEnabled === true) {
            this.requestEmail();
        } else if (previousProps.isEmailEnabled === true && this.props.isEmailEnabled === false) {
            this.emailCancelTokenSource.cancel();
            this.emailCancelTokenSource = Axios.CancelToken.source();
        }

        // With email enabled, when the user completes one another should be requested.
        if (this.props.isEmailEnabled && previousProps.isEmailAvailable && !this.props.isEmailAvailable) {
            this.requestEmail();
        }
    }

    componentWillUnmount(): void {
        this.decorator.cancelAll();

        this.props.clearTimers();

        // Cancel all other requests.
        this.emailCancelTokenSource.cancel();
    }

    /**
     * Request proposed actions when a email arrives.
     * @param email      A summary of the email.
     */
    requestProposedActions = async (email: IEmailSummary): Promise<void> => {
        try {
            // Request and format proposed actions.
            const actionsUrl = urlJoin(Url.Emails, email.id!.toString(), Url.EmailsProposedActions);
            const configuration = this.decorator.getInteractionConfiguration(email.key);
            const { data: actionData } = await axios.get<IProposedActionsResponse>(actionsUrl, configuration);

            // Format and store proposed actions.
            this.updateEmail(email, {
                actions: formatProposedActions(actionData.actions),
                templateActions: formatProposedActions(actionData.templateActions),
                topicBookingDetails: actionData.topicBookingDetails,
            });
        } catch (serverError) {
            if (Axios.isAxiosError(serverError)) {
                this.props.onServerError(serverError);
            }
        }
    };

    /**
     * Update an email with some extra details.
     * @param email       Details of the email to update.
     * @param data        Partial email data.
     */
    updateEmail = ({ id }: IEmailSummary, data: Partial<IBlankEmail> | Partial<IQueryEmail>): void => {
        this.props.updateEmail(typeof id === "number" ? "query" : "blank", data);
    };

    /**
     * Request an email when the agent enables email and they don't have one already.
     */
    requestEmail = async (): Promise<void> => {
        if (this.props.isEmailEnabled && !this.props.isEmailAvailable) {
            this.props.requestQuery();
        }
    };

    render() {
        return null;
    }
}

/**
 * See if the setup data has been downloaded and if the user is signed in.
 * @param state Application state.
 * @returns     Setup data and sign in state.
 */
export function mapStateToProps({ email }: IApplicationState): IStateProps {
    // Format emails into summary format.
    const emails: IEmailSummary[] = Object.values(email.emails)
        .filter((item): item is IQueryEmail | IBlankEmail => item !== null)
        .map((item) => ({
            bookingId: item.bookingId,
            emailAddress: "senderEmail" in item ? item.senderEmail : null,
            hasAgentInformation:
                typeof item.bookingDetails !== "undefined" && typeof item.textTemplateVariables !== "undefined",
            hasProposedActions: typeof item.actions !== "undefined" && item.actions !== null,
            id: item.emailId,
            interactionDetailId: item.interactionDetailId ?? null,
            interactionTypeId: InteractionType.Email as InteractionType.Email,
            key: item.createdAt.toISOString(),
        }))
        .filter(({ bookingId, interactionDetailId }) => bookingId !== null || interactionDetailId !== null);

    return {
        emails,
        isEmailAvailable: email.emails.query !== null,
        isEmailEnabled: email.enabled,
        isQueueEmpty: email.isQueueEmpty,
    };
}

const mapDispatchToProps = {
    ...emailOperations,
    ...loadingBlockerOperations,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailService);
