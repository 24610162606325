import urlJoin from "url-join";
import { AxiosRequestConfig } from "axios";
import { Url, IPaginated } from "@edgetier/types";

import axios from "utilities/axios";
import { ICustomerUrl } from "redux/application.types";

/**
 * Request the URL history for a given chat. If the request fails an empty array is returned.
 * @param chatId        Identifier to request URLs.
 * @param configuration Axios configuration to cancel requests.
 * @returns             Axios promise to return URLs.
 */
export default async function requestCustomerUrls(chatId: number, configuration: AxiosRequestConfig = {}) {
    try {
        const url = urlJoin(Url.Chat, chatId.toString(), Url.CustomerUrls);
        const { data } = await axios.get<IPaginated<ICustomerUrl>>(url, configuration);
        return data.items.map((customerUrl) => ({ ...customerUrl, dateTime: new Date(customerUrl.dateTime) }));
    } catch {
        return [];
    }
}
