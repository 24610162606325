import axios from "./axios";
import { AxiosError } from "axios";
import { ISkill } from "redux/modules/setup/setup.types";
import { toastOperations } from "redux/modules/toast";
import { Url } from "@edgetier/types";
import { useDispatch } from "react-redux";
import { useQuery, UseQueryOptions } from "react-query";

export interface IGetSkillsQueryParameters {
    readonly unrestricted?: boolean;
}

/**
 * Get all skills.
 * @returns Promise of array of skills.
 */
const getSkills = async (url: string, parameters: IGetSkillsQueryParameters) => {
    const { data } = await axios.get<{ items: ISkill[] }>(url, { params: parameters });
    return data.items;
};

/**
 * Hook to get the skills using react query.
 * @param configuration React Query settings.
 */
const useSkills = <T = ISkill[]>(
    parameters: IGetSkillsQueryParameters = {},
    configuration: Omit<UseQueryOptions<ISkill[], AxiosError, T>, "queryKey" | "queryFn"> = {}
) => {
    const dispatch = useDispatch();

    return useQuery<ISkill[], AxiosError, T>([Url.Skills, parameters], () => getSkills(Url.Skills, parameters), {
        onError: (serverError) => dispatch(toastOperations.showServerErrorToast("Skills Request Failed", serverError)),
        staleTime: 1000 * 60 * 5,
        ...configuration,
    });
};

export default useSkills;
