import { formatEnumName } from "@edgetier/client-components";

/**
 * Take enum values and convert them into an array containing labels and values.
 * @param options The options that need formatting.
 */
export default function formatEnumOptions(
    options: { [index: string]: number } | { [index: number]: string },
    excludedOptions: number[] = []
) {
    const formattedOptions = Object.entries(options)
        .filter(([_, value]) => typeof value === "number" && !excludedOptions.includes(value))
        .filter(([_, value], index, items) => items.map((item) => item[1]).lastIndexOf(value) === index)
        .sort(([labelOne], [labelTwo]) => labelOne.toLowerCase().localeCompare(labelTwo.toLowerCase()))
        .map(([label, value]) => ({ label: formatEnumName(label), value }));
    return formattedOptions;
}
