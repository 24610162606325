import { kebabCase } from "lodash";

type ConditionalClass = { [index: string]: boolean };
type ClassDefinition = { [index: string]: ConditionalClass } | ConditionalClass;

/**
 * Combine a class with a base class, if given.
 * @param baseClass Optional base class.
 * @param className Class name to combine with the base class.
 * @returns         Combined class.
 */
function combineBaseClass(baseClass: string | null, className: string): string {
    return kebabCase(baseClass ? `${baseClass}-${className}` : className);
}

/**
 * Join and format a definition of conditional CSS classes.
 * @param classes Object of classes.
 * @returns       Classes combined into a single string.
 */
export default function makeClass(
    classes: ClassDefinition,
    includeBase: boolean = true,
    baseClass: string | null = null
): string {
    const topClass = Object.keys(classes)[0];
    const initial =
        Object.keys(classes).length === 1 && typeof topClass === "string" && includeBase ? kebabCase(topClass) : "";

    return Object.keys(classes)
        .reduce(function (previous: string, key: string): string {
            const value = classes[key];
            if (typeof value === "boolean") {
                if (value === true) {
                    return `${previous} ${combineBaseClass(baseClass, key)}`;
                }
            } else {
                return `${previous} ${makeClass(value, false, key)}`;
            }
            return previous;
        }, initial)
        .trim();
}
