import { ALLOWED_CHARACTERS } from "./react-form-field.constants";
import { KeyboardEvent } from "react";

/**
 * Only allow certain characters to be typed.
 * @param event Keyboard event.
 */
export const onKeyPress = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (!ALLOWED_CHARACTERS.includes(event.key)) {
        event.preventDefault();
    }
};
