import { IReportingQueueState } from "redux/modules/reporting/reporting.types";

import { IActionsUnion } from "../../types";

import actions from "./reporting-actions";
import ReportingType from "./reporting-type";

type IReportingAction = IActionsUnion<typeof actions>;

export default function (state: IReportingQueueState = {}, action: IReportingAction): IReportingQueueState {
    switch (action.type) {
        case ReportingType.StoreHistoric:
            return { ...state, reportingHistoricData: action.payload };
        default:
            return state;
    }
}
