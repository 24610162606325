import React, { Fragment, FunctionComponent, memo } from "react";
import { Button } from "@edgetier/components";
import { faTags } from "@fortawesome/free-solid-svg-icons/faTags";
import { useQueryClient } from "react-query";

import ChangeSkills from "components/interaction-options-menu/change-skills-option/change-skills";
import { Modal, ModalContainer } from "shared/modal";
import { IInteraction } from "redux/application.types";
import { ISkill } from "redux/modules/setup/setup.types";

import { IProps } from "./query-history-change-skill.types";
import "./query-history-change-skill.scss";

/**
 * Button to change the skill of a chat or email. The button should only be displayed if chat or email skills are
 * enabled. Also outbound emails don't allow a skill change.
 */
const QueryHistoryChangeSkill: FunctionComponent<IProps> = ({
    interactionDetailId,
    interactionUrl,
    queryId,
    skills,
}) => {
    const queryClient = useQueryClient();

    /**
     * Update the interaction's skill on-screen after changing it (or "them", technically multiple are supported by the
     * backend but it's effectively disabled as it will cause issues).
     * @param hideModal Method to close the modal.
     * @returns         Function accepting the new skills.
     */
    const onChangeSkill = (hideModal: VoidFunction) => (skills: ISkill[]) => {
        // If cached, update the interaction's skill(s).
        queryClient.setQueryData<IInteraction | undefined>(interactionUrl, (interaction) => {
            if (typeof interaction !== "undefined") {
                return { ...interaction, skills };
            }
        });

        hideModal();
    };

    return (
        <ModalContainer>
            {({ hideModal, showModal }) => (
                <Fragment>
                    <Modal>
                        <h2>Change Skill</h2>
                        <ChangeSkills
                            onChange={onChangeSkill(hideModal)}
                            interactionDetailId={interactionDetailId}
                            queryId={queryId}
                            skillIds={skills.map(({ skillId }) => skillId)}
                        />
                    </Modal>

                    <Button className="button--no-modal" icon={faTags} onClick={showModal}>
                        Change Skill
                    </Button>
                </Fragment>
            )}
        </ModalContainer>
    );
};

export default memo(QueryHistoryChangeSkill);
