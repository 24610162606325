import React from "react";
import { doNothing } from "@edgetier/utilities";

import { ITimelineContext } from "./timeline-context.types";

export const defaultContext = { getItemId: doNothing, onSelect: doNothing };

const TimelineContext = React.createContext<ITimelineContext<any>>(defaultContext);

export default TimelineContext;
