import { IChatState } from "redux/modules/chat/chat.types";

import actions from "./../chat-actions";

/**
 * Set the agent as being connected or disconnected to the backend.
 * @param state  Application state before ending a given chat.
 * @param action Action containing a payload of the chat to stop.
 * @returns      Chat state with the stopped chat.
 */
export default function (state: IChatState, action: ReturnType<typeof actions.toggleConnected>): IChatState {
    return { ...state, isConnected: action.payload.isConnected };
}
