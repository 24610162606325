import Qs from "qs";
import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse, CancelTokenSource as TokenSource } from "axios";
import { decamelizeKeys } from "humps";

import LocalStorageKey from "constants/local-storage-key";
import HttpHeader from "constants/http-header";
import saveHeaders from "utilities/save-headers";

export const BASE_URL = "/api";

// Create a customer Axios instance with a base URL for all requests and a query string encoding method that converts
// keys to snake case and splits arrays into repeating keywords.
const axios = Axios.create({
    baseURL: BASE_URL,
    paramsSerializer: (parameters) =>
        Qs.stringify(decamelizeKeys(parameters), {
            arrayFormat: "repeat",
            skipNulls: true,
        }),
});

// Application version to be included in a header.
const version = process.env.REACT_APP_VERSION;

/**
 * Add authentication and version headers on each request.
 */
axios.interceptors.request.use(function (configuration: AxiosRequestConfig): AxiosRequestConfig {
    configuration.headers = {
        ...configuration.headers,
        [HttpHeader.AccessToken]: localStorage.getItem(LocalStorageKey.Token),
        [HttpHeader.UserId]: localStorage.getItem(LocalStorageKey.UserId),
        [HttpHeader.Version]: version,
    };
    return configuration;
});

/**
 * Store authentication headers on each request.
 */
export function resetResponseInterceptor() {
    axios.interceptors.response.use(
        function (response: AxiosResponse): AxiosResponse {
            saveHeaders(response.headers);
            return response;
        },
        function (serverError: AxiosError): Promise<AxiosError> {
            if (typeof serverError.response !== "undefined") {
                saveHeaders(serverError.response.headers);
            }
            return Promise.reject(serverError);
        }
    );
}

/**
 * get cancel tokens and a pre-made configuration object
 * @return         cancel, token, configuration
 */
export function getAxiosCancelToken(): TokenSource & {
    configuration: { cancelToken: TokenSource["token"] };
} {
    const { cancel, token } = Axios.CancelToken.source();
    return { cancel, token, configuration: { cancelToken: token } };
}

/**
 * Aliased from Axios library, for checking if an error is caused by calling cancel()
 */
export const isCancel = Axios.isCancel;
export type CancelTokenSource = TokenSource;
export type CancelToken = CancelTokenSource["token"];

// Initially set the response interceptor.
resetResponseInterceptor();

export default axios;
