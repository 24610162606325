import { AxiosError } from "axios";
import { UseQueryOptions } from "react-query";
import { Url } from "@edgetier/types";
import { useDispatch } from "react-redux";

import useSimpleQuery from "queries/use-simple-query";
import { ISettings } from "redux/application.types";
import { toastOperations } from "redux/modules/toast";

// Modal title if there's an error.
export const ERROR_TITLE = "Settings Request Failed";

// Settings rarely change so this cache can be long.
const CACHE_MINUTES = 30;

/**
 * Get system settings.
 * @param configuration React Query settings.
 * @returns             React Query result.
 */
export const useSettings = (configuration: UseQueryOptions<ISettings, AxiosError> = {}) => {
    const dispatch = useDispatch();
    return useSimpleQuery<ISettings>(Url.Settings, {
        staleTime: CACHE_MINUTES * 60 * 1000,
        onError: (serverError: AxiosError<void>) => {
            dispatch(toastOperations.showServerErrorToast(ERROR_TITLE, serverError));
        },
        ...configuration,
    });
};
