import { AxiosError } from "axios";
import { Dispatch } from "redux";

import { modalOperations } from "redux/modules/modal";

/**
 * Show a modal with a error returned by the backend.
 *
 * @param dispatch Redux dispatch method.
 * @param message  Default error message.
 * @returns        Function that will accept and display the backend's error.
 */
export default function (dispatch: Dispatch, message: string): (serverError: AxiosError) => void {
    return function (serverError: AxiosError): void {
        if (typeof serverError.response === "undefined") {
            dispatch(modalOperations.showModal({ title: "Error", message }));
        } else {
            dispatch(
                modalOperations.showModal({
                    title: "Error",
                    message: serverError.response.data,
                })
            );
        }
    };
}
