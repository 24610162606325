/**
 * Create a dynamic HTML ID. The ID will be fairly convoluted in the hopes that it won't clash with any existing IDs on
 * the form.
 * @param components Parts of a string to combine.
 * @returns          String ID.
 *
 * @example
 * // Returns '__item__1__`.
 * createId('item', 1);
 */
export const createId = (...components: any[]) => `__${components.join("__")}__`;

/**
 * Create an ID for a field in a form that can be used to attach to any errors. This is needed for accessibility reasons
 * so that the field itself can be pointed to the error for screen readers.
 *
 * @param fieldName Form field name.
 * @returns         Field ID string.
 *
 * @example
 * // Returns "__ERROR__name".
 * createFieldErrorId('name');
 */
export const createFieldErrorId = (fieldName: string) => createId("ERROR", fieldName);
