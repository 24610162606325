import { IChatState } from "redux/modules/chat/chat.types";

import actions from "./../chat-actions";

/**
 * Update the transfer timeout ID. When an agent transfers a chat, it must be accepted/rejected within a certain period
 * of time. If not, the frontend cancels the request and it stays with the current agent.
 * @param state  Chat state before saving the timeout ID.
 * @param action Action containing the new transfer timeout ID.
 * @returns      Chat state after saving the timeout ID.
 */
export default function (state: IChatState, action: ReturnType<typeof actions.setTransferTimeoutId>): IChatState {
    if (typeof state.chats[action.payload.chatToken] === "undefined") {
        return state;
    }

    return {
        ...state,
        chats: {
            ...state.chats,
            [action.payload.chatToken]: {
                ...state.chats[action.payload.chatToken],
                transferTimeoutId: action.payload.transferTimeoutId,
            },
        },
    };
}
