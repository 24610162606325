import { IRedactFormSection } from "components-for/query-history/redact-action/redact-form/redact-form.types";

/**
 * Turn the sections into tabs/labels.
 * @param sections Details of the different properties and languages that can be redacted.
 * @returns        Labels that can be selected.
 */
export const createOptions = (sections: IRedactFormSection[]) => {
    return sections.flatMap(({ label, translations }, index) => {
        return Object.keys(translations).map((languageId, _, languageIds) => ({
            hasMultipleLanguages: languageIds.length > 1,
            index,
            label,
            languageId: parseInt(languageId, 10),
        }));
    });
};
