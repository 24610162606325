import { IChatState } from "redux/modules/chat/chat.types";

import actions from "./../chat-actions";

/**
 * Mark a to say it currently being transferred, or has stopped being transferred.
 * @param state  Application state before changing the transfer state.
 * @param action Action containing a payload of the chat to update and the transferreing state.
 * @returns      Application state after changing the transfer state.
 */
export default function (state: IChatState, action: ReturnType<typeof actions.toggleTransferring>): IChatState {
    if (typeof state.chats[action.payload.chatToken] === "undefined") {
        return state;
    }

    return {
        ...state,
        chats: {
            ...state.chats,
            [action.payload.chatToken]: {
                ...state.chats[action.payload.chatToken],
                isTransferring: action.payload.isTransferring,
            },
        },
    };
}
