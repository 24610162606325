import { ChatEventType, Url } from "@edgetier/types";
import urljoin from "url-join";
import { useDispatch } from "react-redux";

import useSimpleQuery from "queries/use-simple-query";
import { toastOperations } from "redux/modules/toast";
import { IUser } from "redux/application.types";

import { IProps } from "./interaction-event-name.types";

import "./interaction-event-name.scss";

/**
 * Display the name of a call or chat event.
 * @param props.event Chat or call event.
 * @returns           Name of a call or chat event.
 */
const InteractionEventName = ({ event }: IProps) => {
    const dispatch = useDispatch();

    // display agent name for invite events
    const displayAgent =
        "chatEventType" in event &&
        [ChatEventType.AgentInvite, ChatEventType.AgentInviteTimeout, ChatEventType.AgentRejectInvite].includes(
            event.chatEventTypeId
        ) &&
        typeof event.userId === "number";

    const { data } = useSimpleQuery<IUser>(urljoin(Url.Users, String(event.userId)), {
        onError: (serverError) => dispatch(toastOperations.showServerErrorToast("User Request Failed", serverError)),
        enabled: displayAgent,
        staleTime: 1000 * 60 * 5,
    });

    const eventName = "chatEventType" in event ? event.chatEventType : event.callEventType;
    return (
        <div className="interaction-event-name">
            {eventName.replace(/_/g, " ").toLowerCase()}
            {displayAgent && (
                <span>
                    : {data?.firstName} {data?.surname}
                </span>
            )}
        </div>
    );
};

export default InteractionEventName;
