import classNames from "classnames";
import React from "react";

import { IProps } from "./net-promoter-score-item.types";
import "./net-promoter-score-item.scss";

/**
 * Display a number in a NPS rating.
 * @param props.index The position of the item in the overall range.
 * @param props.value Value the user selected along the range.
 * @returns           Number value.
 */
const NetPromoterScoreItem = ({ index, value }: IProps) => (
    <div
        className={classNames("net-promoter-score-item", {
            "net-promoter-score-item--selected": index === value,
        })}
    >
        {index}
    </div>
);

export default NetPromoterScoreItem;
