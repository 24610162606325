import { connect } from "react-redux";
import { FormFieldType } from "@edgetier/types";
import { SpinnerUntil } from "@edgetier/components";
import { DetailsSection, DetailsSectionTitle } from "@edgetier/client-components";
import { intersectWithEnum } from "@edgetier/utilities";

import { IApplicationState } from "redux/types";
import { IFormSubmissionField } from "redux/application.types";
import { setupSelectors } from "redux/modules/setup";
import { ToggleTranslation, ToggleTranslationButton } from "shared/toggle-translation";

import { IProps, IStateProps } from "./form-submission.types";
import FormSubmissionField from "./form-submission-field";

/**
 * Render multiple tables and titles for each section in the booking details.
 * @returns Fragment containing multiple titles and tables.
 */
export const FormSubmission = ({
    actionButton,
    formSubmissionFields,
    languages,
    preferredLanguageId,
    secondaryLanguageId,
    title,
}: IProps) => (
    <DetailsSection>
        <ToggleTranslation
            preferredLanguageId={preferredLanguageId}
            languageIds={[preferredLanguageId, secondaryLanguageId]}
            languages={languages}
        >
            <DetailsSectionTitle title={title}>
                <>{preferredLanguageId !== secondaryLanguageId && <ToggleTranslationButton />}</>
            </DetailsSectionTitle>

            <SpinnerUntil<IFormSubmissionField[]>
                data={formSubmissionFields}
                isReady={Array.isArray(formSubmissionFields)}
            >
                {(data) => (
                    <table>
                        <tbody>
                            {intersectWithEnum(FormFieldType, data, ({ formField }) => formField.formFieldTypeId).map(
                                (field, index) => (
                                    <FormSubmissionField
                                        field={
                                            {
                                                ...field,
                                                formFieldTypeId: field.formField.formFieldTypeId,
                                            } as IFormSubmissionField
                                        }
                                        key={index}
                                    />
                                )
                            )}
                        </tbody>
                    </table>
                )}
            </SpinnerUntil>
            {actionButton}
        </ToggleTranslation>
    </DetailsSection>
);

/**
 * Get languages from the store.
 * @param state Application state.
 */
function mapStateToProps({ setup }: IApplicationState): IStateProps {
    return { languages: setupSelectors.getLanguages(setup) };
}

export default connect(mapStateToProps)(FormSubmission);
