import Axios from "axios";
import React from "react";
import { getServerError } from "@edgetier/utilities";

import ErrorModal from "../error-modal";

import { IProps } from "./server-error-modal.types";
import { MODAL_TITLE } from "./server-error-modal.constants";

/**
 * Render a server error inside a modal.
 * @param props Props containing a server error.
 */
export default class ServerErrorModal extends React.PureComponent<IProps> {
    render(): React.ReactNode {
        return (
            <ErrorModal>
                {(showError) =>
                    this.props.children(function (serverError: unknown): void {
                        if (!Axios.isCancel(serverError)) {
                            showError(
                                MODAL_TITLE,
                                Array.isArray(serverError)
                                    ? serverError.map((error) => getServerError(error))
                                    : getServerError(serverError)
                            );
                        }
                    })
                }
            </ErrorModal>
        );
    }
}
