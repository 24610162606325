import React from "react";

import { IProps } from "./unique-id.types";

// Counter to remember generate IDs.
let counter = 0;

/**
 * Create the next unique ID.
 * @returns Unique ID.
 */
function getId(): string {
    return (counter++).toString();
}

/**
 * Generates unique HTML IDs for components.
 */
export default class UniqueId extends React.PureComponent<IProps> {
    uniqueId = getId();

    /**
     * Render the child passing the unique ID.
     */
    render(): React.ReactNode {
        return this.props.children(this.uniqueId);
    }
}
