import React from "react";
import { DetailsFieldTitle, SplitTimestamp } from "@edgetier/client-components";

import { IProps } from "./email-in-dates.types";

/**
 * Render the dates associated with an inbound email.
 * @param props Props containing the received date and maybe the agent finish time.
 * @returns     Date table rows.
 */
export default ({ agentFinishTime, date }: IProps) => (
    <React.Fragment>
        <tr>
            <td>
                <DetailsFieldTitle>Received</DetailsFieldTitle>
            </td>
            <td>
                <SplitTimestamp date={date} />
            </td>
        </tr>
        {agentFinishTime instanceof Date && (
            <tr>
                <td>
                    <DetailsFieldTitle>Handled</DetailsFieldTitle>
                </td>
                <td>
                    <SplitTimestamp date={agentFinishTime} />
                </td>
            </tr>
        )}
    </React.Fragment>
);
