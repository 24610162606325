import { getLanguageName } from "@edgetier/utilities";

import { IProps } from "./translation-description.types";

/**
 * Description of source and destination languages for a translated email or chat.
 * @param props.agentLanguageId The language of the agent.
 * @param props.languageId      Language of the customer.
 * @returns                     Description of the translation that is taking/took place.
 */
const TranslationDescription = ({ agentLanguageId, languageId, languages }: IProps) => (
    <span>
        Translated from {getLanguageName(languages, languageId)} to {getLanguageName(languages, agentLanguageId)}
    </span>
);

export default TranslationDescription;
