import { IChatEvent } from "@edgetier/types";

import { ICallEvent } from "redux/application.types";

/**
 * Get the date of the event which has a different property name for call and chat events.
 * @param event Call or chat event.
 * @returns     Date of the event.
 */
export default function getInteractionEventDateTime(event: ICallEvent | IChatEvent) {
    return new Date("timestamp" in event ? event.timestamp : event.dateTime);
}
