import { IFormComponent } from "@edgetier/types";

/**
 * There are multiple types of input that can appear within a form including text inputs, textareas, number inputs, NPS
 * ratings, checkboxes, radios etc. These are grouped into categories in the backend depending on how they are stored.
 * For example, number inputs, NPS, star ratings etc. are all stored as numeric values.
 */
export default abstract class FormField<T extends IFormComponent, V> {
    constructor(data: T) {
        this.configuration = data.configuration;
        this.data = data;
    }

    protected configuration: T["configuration"];
    protected data: T;

    /**
     * Clean the value before submitting it.
     * @returns The value or null if it's blank.
     */
    abstract formatValue(value: V): V | null;

    /**
     * Return a suitable default for the field. Sometimes this is defined by the backend. If not, a reasonable default
     * is chosen depending on the type of field.
     * @returns A default value.
     */
    abstract getDefaultValue(): V;

    /**
     * Return configuration with no changes.
     * @param configuration  The field config to decorate.
     * @param formComponents The other fields in the form.
     * @returns     A new configuration object, with potential changes.
     */
    static decorateConfiguration(configuration: {}, formComponents: IFormComponent[]) {
        return configuration;
    }

    /**
     * See if the field is required and blank.
     * @param value Current field value.
     * @returns     True if the value is blank.
     */
    isBlank(value: V): boolean {
        return this.data.required === true && this.isNotBlank(value) === false;
    }

    /**
     * Validate the field given constraints provided by the backend.
     * @param value Current field value.
     * @returns     Result of validating the field.
     */
    isInvalid(value: V): boolean {
        return !this.isValid(value);
    }

    /**
     * Check for a blank field.
     * @param value Current field value.
     * @returns     True if the field is populated.
     */
    abstract isNotBlank(value: V): boolean;

    /**
     * Validate the field against constraints provided by the backend. These depend on the type of field.
     * @param value Current field value.
     * @returns     True if the field is valid.
     */
    abstract isValid(value: V): boolean;
}
