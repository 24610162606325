import { MessageRecipientType } from "@edgetier/types";

import { IChatState } from "redux/modules/chat/chat.types";

import actions from "./../chat-actions";

/**
 * Store a chat message in the correct chat.
 * @param state  Application state before storing the message.
 * @param action Incoming message details.
 * @returns      State with the message.
 */
export default function (state: IChatState, { payload }: ReturnType<typeof actions.storeMessage>): IChatState {
    const { chatToken } = payload;
    const chat = state.chats[chatToken];
    if (typeof chat === "undefined") {
        return state;
    }

    // Increase the unread message count if this is a message for the agent on a chat they're not currently viewing.
    const unreadMessages =
        (state.activeChat !== chatToken || !state.isChatScreenOpen) &&
        payload.messageRecipientTypeId === MessageRecipientType.Agent
            ? chat.unreadMessages + 1
            : chat.unreadMessages;

    return {
        ...state,
        chats: {
            ...state.chats,
            [payload.chatToken]: {
                ...chat,
                messages: chat.messages.concat([{ ...payload, timestamp: new Date().toISOString() }]),
                unreadMessages,
            },
        },
    };
}
