interface IUserSubset {
    readonly firstName: string;
    readonly surname: string;
}

/**
 * Combine and lowercase a user's first name and surname for sorting purposes.
 * @param props User data.
 * @returns     Full name.
 */
function combineNames({ firstName, surname }: IUserSubset) {
    return `${firstName} ${surname}`.toLowerCase();
}

/**
 * Sort users based on their first and surnames.
 * @returns Sorting result.
 */
export default <T extends IUserSubset>(userOne: T, userTwo: T) =>
    combineNames(userOne).localeCompare(combineNames(userTwo));
