import { IChatState } from "redux/modules/chat/chat.types";

import actions from "./../chat-actions";

/**
 * Join a chat. This is called after an agent has accepted a chat invitation.
 * @param state  Application state before entering the chat.
 * @param action Incoming chat details.
 */
export default function (state: IChatState, action: ReturnType<typeof actions.updateMessage>): IChatState {
    const { chatToken } = action.payload;
    const chat = state.chats[chatToken];
    if (typeof chat === "undefined") {
        return state;
    }

    return {
        ...state,
        chats: {
            ...state.chats,
            [chatToken]: {
                ...chat,
                messages: [
                    ...chat.messages.slice(0, action.payload.index),
                    {
                        ...chat.messages[action.payload.index],
                        ...action.payload.message,
                    },
                    ...chat.messages.slice(action.payload.index + 1),
                ],
            },
        },
    };
}
