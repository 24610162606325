import { IChatState } from "redux/modules/chat/chat.types";

import actions from "./../chat-actions";

/**
 * Toggle the chat screen open or closed.
 * @param state  The current state.
 * @param action Action with a payload indicating if the agent screen is open or not.
 * @returns      The new state.
 */
export default function (state: IChatState, action: ReturnType<typeof actions.toggleChatScreenOpen>): IChatState {
    return { ...state, isChatScreenOpen: action.payload };
}
