import { IChatState } from "redux/modules/chat/chat.types";

import actions from "./../chat-actions";

/**
 * Update an email related to an ongoing chat.
 * @param state  Application state before updating a given chat.
 * @param action Action containing a payload of the chat email to update.
 * @returns      Application state after updating a given chat.
 */
export default function (state: IChatState, action: ReturnType<typeof actions.updateChatEmail>): IChatState {
    const { data, chatToken } = action.payload;
    const chat = state.chats[chatToken];
    if (typeof chat === "undefined") {
        return state;
    }
    if (chat.emailDraft === null) {
        return state;
    }

    return {
        ...state,
        chats: { ...state.chats, [chatToken]: { ...chat, emailDraft: { ...chat.emailDraft, ...data } } },
    };
}
