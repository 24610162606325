import { ITranslations } from "@edgetier/types";

/**
 * Check if bodyHtml is null or it has empty translations
 */
export const emailContent = (bodyHtml: ITranslations | null, bodyText: ITranslations) => {
    if (bodyHtml === null) {
        return bodyText;
    }

    const hasEmptyTranslation = Object.values(bodyHtml).some(
        ({ translation }) => typeof translation !== "string" || translation.trim().length === 0
    );
    return hasEmptyTranslation ? bodyText : bodyHtml;
};
