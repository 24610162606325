import React from "react";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ModalWrapper from "../wrapper";
import ModalContainer from "../container";

import { IProps, IState } from "./error-modal.types";

/**
 * Render an error inside a modal.
 * @param props Props containing a server error.
 */
export default class ErrorModal extends React.PureComponent<IProps, IState> {
    state: IState = { messages: [], title: "" };

    /**
     * Render a modal with the given title and text.
     * @returns Modal component.
     */
    render(): React.ReactNode {
        return (
            <ModalContainer>
                {({ hideModal, showModal }) => (
                    <React.Fragment>
                        <ModalWrapper>
                            <div className="error-modal">
                                <h2>{this.state.title}</h2>
                                {this.state.messages.map((message, index) => (
                                    <p key={index}>{message}</p>
                                ))}
                                <div className="button" onClick={hideModal}>
                                    <FontAwesomeIcon icon={faCheck} /> OK
                                </div>
                            </div>
                        </ModalWrapper>
                        {this.props.children(this.showError.bind(this, showModal))}
                    </React.Fragment>
                )}
            </ModalContainer>
        );
    }

    /**
     * Display the error on the page with a given message.
     * @param showModal   Function to open a modal.
     * @param serverError Error from the backend.
     */
    showError = (showModal: VoidFunction, title: string, messages: string | string[]) =>
        this.setState({ title, messages: Array.isArray(messages) ? messages : [messages] }, showModal);
}
