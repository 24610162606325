import classNames from "classnames";
import { FunctionComponent, memo } from "react";
import { IAttachment } from "@edgetier/types";
import { Tooltip } from "@edgetier/components";

import AttachmentButton from "./attachment-button";
import AttachmentsContext from "./attachments-context";
import { IProps } from "./attachments.types";
import "./attachments.scss";

/**
 * Component to display and download/view attachments. The attachments could be anything coming from customers. At the
 * moment, they are just opened in a new tab, leaving the agent's browser to decide the best way of viewing them (images
 * and PDFs are generally viewable directly in the new tab, zip files etc. will be downloaded).
 */
const Attachments: FunctionComponent<IProps> = ({
    attachments,
    children,
    limitHeight = false,
    getAttachment,
    onBlocked,
    onServerError,
}) => {
    /**
     * Checks if an attachment has been deleted since the user opened the interaction thread.
     * @param deletedAttachments An array of the attachments that have been deleted since the user opened the thread.
     * @param propAttachment     The attachment that's being checked for deletion.
     * @returns                  The deleted attachment stored in context or undefined if attachment hasn't been deleted.
     */
    const getDeletedAttachment = (deletedAttachments: IAttachment[], attachment: IAttachment) => {
        return deletedAttachments.find(({ attachmentId }) => attachmentId === attachment.attachmentId);
    };

    if (!(attachments instanceof Array) || attachments.length === 0) {
        return null;
    }

    return (
        <AttachmentsContext.Consumer>
            {({ canDelete, showDeleted, deletedAttachments }) => {
                return (
                    <div
                        className={classNames("attachments", {
                            "attachments--limit": limitHeight === true,
                        })}
                    >
                        <div className="attachments__container">
                            {attachments
                                .filter((attachment) => showDeleted || !attachment.deleted)
                                .map((propAttachment, index) => {
                                    const attachment =
                                        getDeletedAttachment(deletedAttachments, propAttachment) || propAttachment;
                                    return (
                                        <Tooltip
                                            content={children && children(attachment)}
                                            disableTooltip={!attachment.deleted}
                                            key={index}
                                            placement="bottom-center"
                                        >
                                            <span className={"attachments__container__attachment"}>
                                                <AttachmentButton
                                                    attachment={attachment}
                                                    canDelete={canDelete}
                                                    deleted={attachment.deleted}
                                                    getAttachment={getAttachment}
                                                    onBlocked={onBlocked}
                                                    onServerError={onServerError}
                                                >
                                                    {children}
                                                </AttachmentButton>
                                            </span>
                                        </Tooltip>
                                    );
                                })}
                        </div>
                    </div>
                );
            }}
        </AttachmentsContext.Consumer>
    );
};

export default memo(Attachments);
