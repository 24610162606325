import { IBrowser, IDevice, IIPAddress, IOperatingSystem, Url } from "@edgetier/types";
import { UseQueryOptions } from "react-query";
import useSimpleQuery from "queries/use-simple-query";
import { AxiosError } from "axios";

/**
 * A query to get the user's device.
 * @param chatId  The ID of the chat.
 * @param options An optional react query configuration.
 */
export const useDevice = (chatId: number, options: UseQueryOptions<IDevice, AxiosError> = {}) => {
    const url = Url.ChatDevice.replace(":chatId", chatId.toString());
    return useSimpleQuery(url, options);
};

/**
 * A query to get the user's browser.
 * @param chatId  The ID of the chat.
 * @param options An optional react query configuration.
 */
export const useBrowser = (chatId: number, options: UseQueryOptions<IBrowser, AxiosError> = {}) => {
    const url = Url.ChatBrowser.replace(":chatId", chatId.toString());
    return useSimpleQuery(url, options);
};

/**
 * A query to get the user's operating system.
 * @param chatId  The ID of the chat.
 * @param options An optional react query configuration.
 */
export const useOperatingSystem = (chatId: number, options: UseQueryOptions<IOperatingSystem, AxiosError> = {}) => {
    const url = Url.ChatOperatingSystem.replace(":chatId", chatId.toString());
    return useSimpleQuery(url, options);
};

/**
 * A query to get the user's IP address.
 * @param chatId  The ID of the chat.
 * @param options An optional react query configuration.
 */
export const useIPAddress = (chatId: number, options: UseQueryOptions<IIPAddress, AxiosError> = {}) => {
    const url = Url.ChatIPAddress.replace(":chatId", chatId.toString());
    return useSimpleQuery(url, options);
};
