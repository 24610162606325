import { IProps } from "./typing-indicator.types";
import "./typing-indicator.scss";

/**
 * Display to indicate that the other person is typing.
 * @returns Flashing dots.
 */
const TypingIndicator = ({ label }: IProps) => (
    <div className="chat-messages__typing-indicator">
        <span className="chat-messages__typing-indicator__status" role="status">
            {label}
        </span>
        <div /> <div /> <div />
    </div>
);

export default TypingIndicator;
