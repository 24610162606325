import React from "react";
import { DetailsFieldTitle, SplitTimestamp } from "@edgetier/client-components";

import { IProps } from "./email-out-dates.types";

/**
 * Render the dates associated with an outbound email.
 * @param props Props containing the sent date.
 * @returns     Date table rows.
 */
export default ({ date }: IProps) => (
    <React.Fragment>
        <tr>
            <td>
                <DetailsFieldTitle>Sent</DetailsFieldTitle>
            </td>
            <td>
                <SplitTimestamp date={date} />
            </td>
        </tr>
    </React.Fragment>
);
