import React, { FunctionComponent, memo } from "react";
import { components } from "react-select";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { IProps } from "./select-option.types";
import "./select-option.scss";

/**
 * Display an option in a select component. It will show a check mark when selected.
 * @param props.children    Option content.
 * @param props.optionProps All other option props.
 * @returns                 Select option.
 */
const SelectOption: FunctionComponent<IProps> = ({ children, ...optionProps }) => {
    return (
        <components.Option {...optionProps}>
            <div className="react-select__option__label">{children}</div>
            {optionProps.isSelected && (
                <FontAwesomeIcon fixedWidth={true} icon={optionProps.isFocused ? faTimes : faCheck} />
            )}
        </components.Option>
    );
};

export default memo(SelectOption);
