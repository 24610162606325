import { IChatState } from "../chat.types";
import actions from "./../chat-actions";

/**
 * Store a draft of the wrap-up form
 * @param state  Application state before storing the draft.
 * @param action Action containing a payload of the wrap-up form draft.
 * @returns Chat state with the wrap-up form draft.
 */
export default function (state: IChatState, { payload }: ReturnType<typeof actions.storeWrapUpFormDraft>): IChatState {
    const { chatToken } = payload;
    const chat = state.chats[chatToken];
    if (typeof chat === "undefined") {
        return state;
    }

    return {
        ...state,
        chats: {
            ...state.chats,
            [chatToken]: {
                ...chat,
                wrapUpFormDraft: payload.formValues,
            },
        },
    };
}
