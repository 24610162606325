import { locales, Translation } from "@edgetier/types";

/**
 * Get a translation that's used in the chat window.
 * @param value      The name of the translation.
 * @param languageId The language that the translation should be in.
 * @returns The translation in the requested language or "Error" if not found.
 */
export const getChatWindowTranslation = (value: Translation, languageId: number) => {
    return locales[languageId]?.[value] ?? "Error";
};
