import { FunctionComponent, memo } from "react";

import Interaction from "./interaction";
import QueryHistoryNote from "./query-history-note";
import { IProps } from "./query-history-item.types";
import "./query-history-item.scss";

/**
 * Display an item in the query history. This could either be a note or interaction.
 * @param props.queryHistoryItem Note or interaction.
 * @returns                      Note or interaction.
 */
const QueryHistoryItem: FunctionComponent<IProps> = ({ queryHistoryItem }) => {
    return (
        <div className="query-history-item">
            {"noteId" in queryHistoryItem ? (
                <QueryHistoryNote noteId={queryHistoryItem.noteId} />
            ) : (
                <Interaction interactionSearchItem={queryHistoryItem} />
            )}
        </div>
    );
};

export default memo(QueryHistoryItem);
