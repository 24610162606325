import { IChatState } from "redux/modules/chat/chat.types";

import actions from "../chat-actions";

/**
 * Remove a proposed chat activity.
 * @param state  Application state before removing a proposed activity.
 * @param action Action containing a payload of the proposed activity ID to remove.
 * @returns      Chat state without proposed activity.
 */
export default function removeProposedActivity(
    state: IChatState,
    { payload }: ReturnType<typeof actions.removeProposedActivity>
): IChatState {
    const { chatToken, proposedActivityId } = payload;
    const chat = state.chats[chatToken];
    if (typeof chat === "undefined") {
        return state;
    }

    const { [proposedActivityId]: removed, ...proposedActivities } = chat.proposedActivities;
    const chats = { ...state.chats, [chatToken]: { ...chat, proposedActivities } };
    return { ...state, chats };
}
