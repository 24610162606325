import { Button } from "@edgetier/components";
import { faSignOut } from "@fortawesome/pro-solid-svg-icons";
import ChatWarning from "./chat-warning";
import DeferredQueryWarning from "./deferred-query-warning";
import { IProps } from "./warnings.types";

/**
 * Warnings about active chats and/or deferred queries that will be display to any agent when they attempt to sign out.
 * @param props Props containing active chat count and the deferred queries.
 * @returns     Active chats warning, deferred queries warning, or both.
 */
const Warnings = ({ chatCount, deferredQueryCount, hideModal, signOut }: IProps) => (
    <div className="navigation__sign-out-warnings">
        <p>There are some unfinished activities:</p>
        <ul>
            {chatCount > 0 && <ChatWarning chatCount={chatCount} onClick={hideModal} />}
            {deferredQueryCount > 0 && (
                <DeferredQueryWarning deferredQueryCount={deferredQueryCount} onClick={hideModal} />
            )}
        </ul>
        <Button styleName="negative" onClick={signOut} icon={faSignOut}>
            Ignore &amp; sign out
        </Button>
    </div>
);

export default Warnings;
