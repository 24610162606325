/**
 * Convert an enum name into some more readable.
 * @param value     Original enum name.
 * @param lowerCase Whether to lower case the result or not.
 * @returns         Nicer enum label.
 */
export const formatName = (value: string, lowerCase: boolean) => {
    const words = value
        .replace(/([a-z])([A-Z])/g, "$1_$2")
        .split("_")
        .map((word) => {
            return word[0].toUpperCase().concat(word.substring(1).toLowerCase());
        })
        .join(" ");

    return lowerCase ? words.toLocaleLowerCase() : words;
};
