import { FunctionComponent, memo } from "react";
import { useDispatch } from "react-redux";
import { useQueryClient } from "react-query";
import { Url } from "@edgetier/types";
import urljoin from "url-join";
import Axios from "axios";

import SplitButtonEnum from "components/split-button-enum";
import { QueryState, QUERY_STATE_ICONS } from "constants/query-state";
import setQueryStateId from "utilities/set-query-state-id";
import { toastOperations } from "redux/modules/toast";
import { loadingBlockerOperations } from "redux/modules/loading-blocker";

import { IProps } from "./update-query-state.types";
import "./update-query-state.scss";

/**
 * Render a drop down that shows the current query state and gives options to change it.
 * @param props.onUpdateQueryState Function that runs when the query state is updated.
 * @param props.queryId            The id of the query being updated.
 * @param props.queryStateId       The current query state id.
 */
const UpdateQueryState: FunctionComponent<IProps> = ({ onUpdateQueryState, queryId, queryStateId }) => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();

    /**
     * Function to update the query state.
     * @param queryStateId The new query state id.
     */
    const updateQueryState = async (queryStateId: number) => {
        dispatch(loadingBlockerOperations.showLoadingBlocker(true));
        try {
            await setQueryStateId(queryId, queryStateId);
            dispatch(toastOperations.showSuccessToast("Success", "Query state has been updated"));
            queryClient.setQueryData<{ queryStateId: number }>(urljoin(Url.Query, queryId.toString()), {
                queryStateId,
            });
            onUpdateQueryState(queryId, queryStateId);
        } catch (serverError) {
            if (Axios.isAxiosError(serverError)) {
                dispatch(toastOperations.showServerErrorToast("Update Failed", serverError));
            }
        } finally {
            dispatch(loadingBlockerOperations.hideLoadingBlocker(true));
        }
    };

    return (
        <SplitButtonEnum
            defaultOption={queryStateId}
            excludedOptions={[QueryState.AwaitingAction]}
            icons={QUERY_STATE_ICONS}
            options={QueryState}
            onOptionSelect={({ value }) => updateQueryState(value)}
            shouldButtonDoAction={false}
        />
    );
};

export default memo(UpdateQueryState);
