import React from "react";

import TimelineContext from "components/timeline/timeline-context";

import { defaultContext } from "../timeline-context";

import { IProps } from "./timeline-provider.types";

/**
 * Wrap a timeline with common functionality.
 */
class TimelineProvider<T> extends React.PureComponent<IProps<T>> {
    state = { ...defaultContext, ...this.props };

    /**
     * Render provider around children.
     * @returns Children components.
     */
    render(): React.ReactNode {
        return <TimelineContext.Provider value={this.state}>{this.props.children}</TimelineContext.Provider>;
    }
}

export default TimelineProvider;
