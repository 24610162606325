import { ChatEventType, ChatStateStatus, IMessage } from "@edgetier/types";

import { IChatState } from "redux/modules/chat/chat.types";

import actions from "../chat-actions";
import { chatIndices } from "../chat-utilities";

/**
 * Resume multiple chats. The first one is activated.
 * @param state  Application state before resuming the chats.
 * @param action Incoming chats.
 * @returns      Application state after adding the chats.
 */
export default function (state: IChatState, { payload }: ReturnType<typeof actions.resumeChats>): IChatState {
    chatIndices.reset();

    return {
        ...state,
        activeChat: state.activeChat ?? (payload.length > 0 ? payload[0].chatToken : null),
        chats: payload.reduce<IChatState["chats"]>(
            (chats, chat) => ({
                ...chats,
                [chat.chatToken]: {
                    ...state.chats[chat.chatToken],
                    actions: [],
                    active:
                        chat.chatStateStatusId !== ChatStateStatus.AgentWrapUp &&
                        chat.chatStateStatusId !== ChatStateStatus.Terminated,
                    agentLanguageId:
                        typeof chat.translatedToLanguageId === "number" ? chat.translatedToLanguageId : chat.languageId,
                    bookingId: chat.bookingId,
                    chatId: chat.chatId,
                    chatIndex: state.chats[chat.chatToken]?.chatIndex ?? chatIndices.next(chats),
                    chatSourceId: chat.chatSourceId,
                    chatStateStatusId: chat.chatStateStatusId,
                    chatToken: chat.chatToken,
                    contactId: chat.contactId,
                    conversationKey: chat.conversationKey,
                    customerName: chat.customerName,
                    customerUrls: chat.customerUrls,
                    emailDraft: null,
                    wrapUpDeadline: null,
                    enteredAt: new Date(),
                    events: chat.events,
                    form: null,
                    formSubmission: chat.formSubmission,
                    inactivityTimerStopped: chat.inactivityTimerStopped,
                    interactionDetailId: chat.interactionDetailId,
                    isInAutomaticWrapUp: false,
                    isTransferring: chat.chatStateStatusId === ChatStateStatus.Transferring,
                    isTyping: false,
                    languageId: chat.languageId,
                    messages: chat.events.filter(
                        (event): event is IMessage => event.chatEventTypeId === ChatEventType.Message
                    ),
                    proposedActivities: {},
                    queryId: chat.queryId,
                    settings: chat.settings,
                    setupId: chat.setupId,
                    skills: chat.skills,
                    surveyFormSubmissionId: chat.surveyFormSubmissionId,
                    transferTimeoutId: null,
                    translatedToLanguageId: chat.translatedToLanguageId,
                    unreadMessages: 0,
                    wrapUpExtended: false,
                    wrapUpForm: null,
                },
            }),
            {}
        ),
    };
}
