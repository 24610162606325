import { IChatState } from "redux/modules/chat/chat.types";

import actions from "../chat-actions";

export default function updateProposedActivity(
    state: IChatState,
    { payload }: ReturnType<typeof actions.updateProposedActivity>
): IChatState {
    const { chatToken, proposedActivityId, proposedActivity } = payload;
    const chat = state.chats[chatToken];
    if (typeof chat === "undefined") {
        return state;
    }

    const { proposedActivities } = chat;
    const updatedProposedActivity = {
        ...proposedActivities[proposedActivityId],
        ...proposedActivity,
    };
    const updatedProposedActivities = {
        ...proposedActivities,
        [proposedActivityId]: updatedProposedActivity,
    };

    const chats = { ...state.chats, [chatToken]: { ...chat, proposedActivities: updatedProposedActivities } };
    return { ...state, chats };
}
