import {
    ISourcedSelectFormComponent,
    ISourcedSelectOption,
    ISourcedSelectOptionsGetResponse,
    Url,
} from "@edgetier/types";
import { AxiosInstance } from "axios";
import { useQuery, UseQueryOptions } from "react-query";
interface ISourcedSelectParamters {
    languageId: number;
    search: string;
}
/**
 * Request the sourced select options from the backend.
 * @param url        The URL of the request.
 * @param axios      The axios instance to use in the request.
 * @param parameters Parameters to send with the request.
 * @returns An array of select options.
 */
const requestSourcedSelectItems = async (url: string, axios: AxiosInstance, parameters: ISourcedSelectParamters) => {
    const { data } = await axios.get<ISourcedSelectOptionsGetResponse>(url, { params: parameters });
    return Array.isArray(data.items) ? data.items : [];
};

/**
 * A query to request the sourced select options from the backend.
 * @param axiosInstance An axios instance used to make the request.
 * @param formField     The sourced select form field.
 * @param parameters    The parameters to send with the request.
 * @param configuration An optional react query configuration.
 */
export const useSourcedSelectItems = (
    axiosInstance: AxiosInstance,
    { formFieldId }: ISourcedSelectFormComponent,
    parameters: ISourcedSelectParamters,
    configuration: UseQueryOptions<ISourcedSelectOption[]> = {}
) => {
    const url = Url.FormFieldsSearch.replace(":formFieldId", formFieldId.toString());
    return useQuery<ISourcedSelectOption[]>(
        [url, parameters],
        () => requestSourcedSelectItems(url, axiosInstance, parameters),
        configuration
    );
};
