import { DEFAULT_LANGUAGE_ID, ISelectOptionTranslation } from "@edgetier/types";

/**
 * Find the customer's language in the translations for a select option.
 * @param selectOptionTranslations List of translations.
 * @param languageId               Customer language ID.
 * @returns                        Select option label.
 */
export default function findSelectTranslation(
    selectOptionTranslations: ISelectOptionTranslation[],
    languageId: number
) {
    const customerLanguage = selectOptionTranslations.find((item) => item.languageId === languageId);
    if (typeof customerLanguage !== "undefined") {
        return customerLanguage.translation;
    } else {
        const defaultLanguage = selectOptionTranslations.find((item) => item.languageId === DEFAULT_LANGUAGE_ID);
        return typeof defaultLanguage === "undefined" ? "???" : defaultLanguage.translation;
    }
}
