import {
    faUserCheck,
    faUserTimes,
    faUserFriends,
    faUserPlus,
    faPlusCircle,
    faClipboard,
    faTimes,
    faGlobeAmericas,
    faSignInAlt,
    faTags,
} from "@fortawesome/pro-solid-svg-icons";

import { IInteractionEventDisplay } from "components/interaction-events/interaction-event/interaction-event.types";

export const CREATE_EVENT: IInteractionEventDisplay = { icon: faPlusCircle };
export const SUBMIT_FORM_EVENT: IInteractionEventDisplay = { icon: faClipboard };
export const TRANSFER_REQUEST_EVENT: IInteractionEventDisplay = { icon: faUserFriends };
export const TRANSFER_SUCCESS_EVENT: IInteractionEventDisplay = { icon: faUserFriends, styleName: "positive" };
export const TRANSFER_NEGATIVE_EVENT: IInteractionEventDisplay = { icon: faUserFriends, styleName: "negative" };
export const FINISH_EVENT: IInteractionEventDisplay = { icon: faTimes };
export const LANGUAGE_CHANGE_EVENT: IInteractionEventDisplay = { icon: faGlobeAmericas };
export const AGENT_INVITE_EVENT: IInteractionEventDisplay = { icon: faUserPlus };
export const AGENT_INVITE_NEGATIVE_EVENT: IInteractionEventDisplay = { icon: faUserPlus, styleName: "negative" };
export const ENTER_QUEUE_EVENT: IInteractionEventDisplay = { icon: faSignInAlt };
export const USER_CONNECT_EVENT: IInteractionEventDisplay = { icon: faUserCheck, styleName: "positive" };
export const USER_DISCONNECT_EVENT: IInteractionEventDisplay = { icon: faUserTimes, styleName: "negative" };
export const CHANGE_SKILL_EVENT: IInteractionEventDisplay = { icon: faTags };
