import Axios from "axios";
import React from "react";
import { faSpinner } from "@fortawesome/free-solid-svg-icons/faSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Url, IPaginated } from "@edgetier/types";

import axios from "utilities/axios";
import logo from "images/colour-light-background.svg";

import { IAuthMethod, IProps, IState } from "./login.types";
import Version from "./version";
import "./login.scss";
import imageUrl from "./background-cover-image.jpg";

/**
 * Sign in page. The backend provides a list of possible authentication providers (Salesforce, Zendesk etc.) that are
 * presented to the user.
 */
export default class Login extends React.PureComponent<IProps, IState> {
    cancelTokenSource = Axios.CancelToken.source();
    state: IState = { authMethods: null };

    /**
     * Download authentication options.
     */
    async componentDidMount(): Promise<void> {
        try {
            const { data } = await axios.get<IPaginated<IAuthMethod>>(Url.AuthMethods);
            this.setState({ authMethods: data.items });
        } catch (serverError) {
            if (Axios.isAxiosError(serverError)) {
                this.props.showServerError(serverError);
            }
        }
    }

    /**
     * Cancel request.
     */
    componentWillUnmount(): void {
        this.cancelTokenSource.cancel();
    }

    /**
     * Render a sign in button for each available authentication provider.
     * @returns Application version number and sign in button(s).
     */
    render(): JSX.Element {
        return (
            <>
                <div className="login__background" style={{ backgroundImage: `url(${imageUrl})` }} />
                <div className="login">
                    <img src={logo} alt="EdgeTier" />
                    <h1>Arthur</h1>
                    {!Array.isArray(this.state.authMethods) ? (
                        <div className="login__loading">
                            <FontAwesomeIcon icon={faSpinner} spin={true} />
                        </div>
                    ) : (
                        this.state.authMethods.map(({ displayName, loginUrl }) => (
                            <a className="button" href={rewriteLoginUrl(loginUrl)} key={displayName}>
                                Sign in with {displayName}
                            </a>
                        ))
                    )}
                    <Version />
                </div>
            </>
        );
    }
}

/**
 * Production will transform this whole function into url => url
 * This is so the development server can log in via an SSO provider but it will not impact production
 * @param url
 */
function rewriteLoginUrl(url: string) {
    if (process.env.NODE_ENV === "development") {
        const redirectMatch = url.match(/redirect_uri=(.+)\/authenticate/);
        if (redirectMatch === null || typeof redirectMatch[1] === "undefined") return url;
        return url.replace(redirectMatch[1], "http://localhost:3131");
    } else {
        return url;
    }
}
