import { ITextFormComponent } from "@edgetier/types";

import FormField from "./form-field";

/**
 * Text fields include plain text inputs, textareas, email inputs etc.
 */
export default class TextFormField extends FormField<ITextFormComponent, string> {
    /**
     * Clean the value before submitting it. Empty strings should be sent as a null.
     * @returns The value.
     */
    formatValue(value: string) {
        const trimmed = value.trim();
        return trimmed.length === 0 ? null : trimmed;
    }

    /**
     * Initialise the field with either a sensible default or a default provided by the backend. React requires empty
     * strings rather than null values for text fields.
     * @returns Default value if provided or an empty string.
     */
    getDefaultValue() {
        return typeof this.configuration.defaultValue === "string" ? this.configuration.defaultValue : "";
    }

    /**
     * Check for a blank value. This is used to display a "required" error.
     * @param value Current field value.
     * @returns     True if the value is not blank.
     */
    isNotBlank(value: string) {
        return typeof value === "string" && value.trim().length > 0;
    }

    /**
     * Validate the field's and maximum length and format if those constraints have been provided by the backend.
     * @param value Current field value.
     * @returns     True if the field if valid.
     */
    isValid(value: string) {
        const { pattern, maximumLength } = this.configuration;

        if (!this.isNotBlank(value)) {
            return true;
        }

        if (typeof maximumLength === "number" && value.length > maximumLength) {
            return false;
        }

        if (pattern !== null) {
            try {
                const expression = new RegExp(pattern);
                return expression.test(value);
            } catch {
                return true;
            }
        } else {
            return true;
        }
    }
}
