import { IChatState } from "redux/modules/chat/chat.types";

/**
 * Open or close the proposed activities display.
 * @param state Application state before opening or closing the proposed activites..
 * @returns     Chat state with the open state flipped.
 */
export default function storeProposedActivity(state: IChatState): IChatState {
    return { ...state, proposedActivitiesOpen: !state.proposedActivitiesOpen };
}
