/**
 * Check an element in a scrollable container is visible.
 * @param element             The element that's a child of a scrollable container.
 * @param scrollableContainer The scrollable container.jjjjj
 * @returns
 */
export const isScrollingElementVisible = (element: Element, scrollableContainer: Element) => {
    const { bottom, height, top } = element.getBoundingClientRect();
    const { top: containerTop, bottom: containerBottom } = scrollableContainer.getBoundingClientRect();
    return top <= containerTop ? containerTop - top <= height : bottom - containerBottom <= height;
};
