import React from "react";
import { UnmountClosed } from "react-collapse";

import { IProps } from "./accordion-item-content.types";
import "./accordion-item-content.scss";

import useAccordionItemContext from "~/accordion/use-accordion-item-context";

/**
 * Aniamted expandable content inside an accordion item.
 * @param props.children Accordion content.
 * @returns              Accordion content.
 */
const AccordionItemContent = ({ children }: IProps) => {
    const { isOpen } = useAccordionItemContext();
    return (
        <div className="accordion__accordion-item-content">
            <UnmountClosed isOpened={isOpen}>{children}</UnmountClosed>
        </div>
    );
};

export default AccordionItemContent;
