import Axios from "axios";

import { logError } from "./log-error";

interface ISocketError {
    readonly message: string;
}

// Error message if none was returned by the backend.
export const DEFAULT_SERVER_ERROR_MESSAGE = "An unknown error occurred.";

/**
 * Check if a backend error message is actually a non-empty string.
 * @returns True if the message is a non-empty string.
 */
function isNonEmptyString(data: any): data is string {
    return typeof data === "string" && data.trim().length > 0;
}

/**
 * Check if the error is a socket error.
 * @param error The error that has been caught.
 * @returns Boolean indicating if the error is a socket error.
 */
function isSocketError(error: unknown): error is ISocketError {
    return typeof error === "object" && error !== null && "message" in error;
}

/**
 * Get an error message sent by the backend. This could be a HTTP or Socket error.
 * @param serverError    HTTP or Socket error.
 * @param defaultMessage Fallback if a server error message is not found.
 * @returns              Server error message string.
 */
export function getServerError(serverError: unknown, defaultMessage: string = DEFAULT_SERVER_ERROR_MESSAGE) {
    logError(serverError);

    if (Axios.isAxiosError(serverError) && typeof serverError.response !== "undefined") {
        const { data, statusText } = serverError.response;
        return isNonEmptyString(data) ? data : isNonEmptyString(statusText) ? statusText : defaultMessage;
    }

    if (isSocketError(serverError)) {
        return isNonEmptyString(serverError.message) ? serverError.message : defaultMessage;
    }

    return defaultMessage;
}
