import { IRecipient, RecipientType } from "redux/modules/email/email.types";

import { IRecipientsObject } from "./inbound-email-participants.types";

/**
 * Group the recipients by their type and return them in an object.
 * @param recipients   An array of recipients of the email.
 * @returns An object containing arrays of emails grouped by to, cc and bcc.
 */
export const groupRecipientsByType = (recipients: IRecipient[]) =>
    recipients.reduce<Partial<IRecipientsObject>>(
        (recipientsObject, { emailRecipientType, emailAccess: recipientEmailAddress }) => {
            const recipientType = emailRecipientType.toLocaleLowerCase() as RecipientType;
            const currentEmailAddresses = recipientsObject[recipientType];

            if (Array.isArray(currentEmailAddresses)) {
                currentEmailAddresses.push(recipientEmailAddress);
            } else {
                recipientsObject[recipientType] = [recipientEmailAddress];
            }

            return recipientsObject;
        },
        {}
    );
