import React from "react";
import { connect } from "react-redux";
import { NavLink, Route } from "react-router-dom";

import { IApplicationState } from "redux/types";

import { IProps, IStateProps } from "./navigation-link.types";

/**
 * Sidebar view link. This may not display if the user does not have the appropriate role.
 */
const NavigationLink = ({ children, view }: IProps) => (
    <NavLink to={view}>
        {typeof children !== "function" ? (
            children
        ) : (
            <Route>
                {({ location }) =>
                    children({
                        isCurrentRoute: location.pathname === view,
                    })
                }
            </Route>
        )}
    </NavLink>
);

/**
 * @param state Application state.
 * @returns     Component's state props.
 */
function mapStateToProps({ authentication }: IApplicationState): IStateProps {
    return { roleId: authentication.roleId };
}

export default connect(mapStateToProps)(NavigationLink);
