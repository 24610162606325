import { compareDesc } from "date-fns";

import { IQueryHistoryItem } from "redux/application.types";

/**
 * Get a unique ID for an external interaciton, interaction, or note.
 * @param item External interaction, interaction, or note.
 * @returns    Unique identifier.
 */
export const getItemId = (item: IQueryHistoryItem) => {
    if ("interactionId" in item) {
        return `interaction-${item.interactionId}`;
    } else if ("noteId" in item) {
        return `note-${item.noteId}`;
    } else {
        return `external-interaction-${item.externalInteractionId}`;
    }
};

/**
 * External interactions, interactions, and notes are in separate arrays but need to be combined into one list and
 * sorted in descending date order. This function gets all their dates and returns the index positions if they were
 * sorted by date. Using this array elsewhere, the JSX array of items can then be ordered the same way.
 * @param props.itemArrays Arrays of external interactions, interactions, and/or notes.
 * @returns                Array of integer index positions.
 *
 * @example
 * // Returns [1, 0]:
 * getSortOrder([{ dateTime: new Date(2020, 1, 1)}], [{ dateTime: new Date(2020, 1, 2)}])
 */
export const getSortOrder = (...itemArrays: ReadonlyArray<{ dateTime: Date }>[]) => {
    // Combine all item dates into one array.
    const combinedDates = itemArrays.flatMap((items) => items.map(({ dateTime }) => dateTime));

    // Get the index positions of the items if they were sorted.
    const indexes = Array.from(Array(combinedDates.length).keys());
    return indexes.sort((one, two) => compareDesc(combinedDates[one], combinedDates[two]));
};
