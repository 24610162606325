import { IChatState } from "redux/modules/chat/chat.types";

import actions from "../chat-actions";

/**
 * Store one or more proposed chat activities. The backend decides whether the each set of proposed activities should
 * append or replace the existing ones for a given chat (the "append" property in the event payload).
 * @param state  Application state before storing a proposed activity.
 * @param action Action containing a payload of the proposed activity to store.
 * @returns      Chat state with new proposed activity
 */
export default function storeProposedActivity(
    state: IChatState,
    { payload }: ReturnType<typeof actions.storeProposedActivities>
): IChatState {
    const { append, chatToken } = payload;
    const chat = state.chats[chatToken];
    if (typeof chat === "undefined") {
        return state;
    }

    // Either append to or replace the existing proposed activities.
    const proposedActivities = append ? { ...chat.proposedActivities } : {};
    payload.proposedActivities.forEach((proposedActivity) => {
        proposedActivities[proposedActivity.proposedActivityId] = proposedActivity;
    });

    const chats = { ...state.chats, [chatToken]: { ...chat, proposedActivities } };
    return { ...state, chats };
}
