import React, { Fragment } from "react";
import { faFileAlt } from "@fortawesome/free-solid-svg-icons/faFileAlt";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import View from "constants/view";

import NavigationLink from "../navigation-link";

/**
 * Link to the text templates page.
 * @returns A link to the page.
 */
const TemplatesLink = () => (
    <NavigationLink view={View.Templates}>
        <Fragment>
            <FontAwesomeIcon icon={faFileAlt} />
            Templates
        </Fragment>
    </NavigationLink>
);

export default TemplatesLink;
