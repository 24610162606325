import { BooleanIcon, DetailsFieldTitle, DetailsFieldValue } from "@edgetier/client-components";

import BlankField from "../blank-field";

import { IProps } from "./form-submission-boolean-field.types";
import "./form-submission-boolean-field.scss";

/**
 * Display a boolean form field submission. This is presented to users as a checkbox.
 * @param props.field Boolean form field submission.
 * @returns           Field title and icon indicated the boolean value.
 */
const FormSubmissionBooleanField = ({ field }: IProps) => (
    <tr>
        <td>
            <DetailsFieldTitle>{field.formFieldTranslation}</DetailsFieldTitle>
        </td>

        <td>
            {field.value === null ? (
                <BlankField />
            ) : (
                <DetailsFieldValue>
                    <BooleanIcon value={field.value} />
                </DetailsFieldValue>
            )}
        </td>
    </tr>
);

export default FormSubmissionBooleanField;
