import classNames from "classnames";
import LanguageName from "components/language-name";
import { createOptions } from "./redact-form-options.utilities";
import { Fragment, FunctionComponent, memo } from "react";
import { IProps } from "./redact-form-options.types";
import "./redact-form-options.scss";

/**
 * Allow the user to choose between different languages/properties to redact. For example, a chat message might have one
 * or more translations. An email could have the Body or HTML content and/or translations for both.
 * @param props.activeOption    Selected option.
 * @param props.sections        Details of the different properties and languages that can be redacted.
 * @param props.setActiveOption Handler when an option is chosen.
 * @returns
 */
const RedactFormOptions: FunctionComponent<IProps> = ({ activeOption, sections, setActiveOption }) => {
    const options = createOptions(sections);

    return (
        <div className="redact-form-options">
            {options.map(({ hasMultipleLanguages, index, label, languageId }) => (
                <Fragment key={`${label}-${languageId}`}>
                    <label
                        className={classNames("redact-form-options__option", {
                            "redact-form-options__option--is-active":
                                activeOption.index === index && activeOption.languageId === languageId,
                            "redact-form-options__option--is-inactive":
                                activeOption.index !== index || activeOption.languageId !== languageId,
                        })}
                        htmlFor={`${label}-${languageId}`}
                    >
                        {hasMultipleLanguages && (
                            <>
                                <LanguageName languageId={languageId} />
                                {sections.length > 1 && <> </>}
                            </>
                        )}

                        {sections.length > 1 && label}
                    </label>

                    <input
                        checked={activeOption.index === index && activeOption.languageId === languageId}
                        onChange={() => setActiveOption({ index, languageId })}
                        id={`${label}-${languageId}`}
                        type="radio"
                        value={`${label}-${languageId}`}
                    />
                </Fragment>
            ))}
        </div>
    );
};

export default memo(RedactFormOptions);
