import { IChatState } from "redux/modules/chat/chat.types";

import actions from "./../chat-actions";

/**
 * Activate one of the agent's chats.
 * @param state  Application state before activating a given chat.
 * @param action Action containing a payload of the chat to activate.
 * @returns      Chat state with newly activated chat.
 */
export default function (state: IChatState, action: ReturnType<typeof actions.removeWaitingChat>): IChatState {
    const { chatToken } = action.payload;
    const { [chatToken]: removed, ...chatsWaiting } = state.invites;
    return { ...state, invites: chatsWaiting };
}
