import { DetailsFieldTitle, DetailsFieldValue } from "@edgetier/client-components";

import BlankField from "../blank-field";

import { IProps } from "./form-submission-select-single-field.types";
import "./form-submission-select-single-field.scss";

/**
 * Display a select field submission that allows a single value e.g. buttons or radios. While only value is allowed,
 * the backend stores and returns them as an array since they are very similar to multi-select inputs otherwise.
 * @param props.field Radio or similar field submission.
 * @returns           Field title and value.
 */
const FormSubmissionSelectSingleField = ({ field }: IProps) => (
    <tr>
        <td>
            <DetailsFieldTitle>{field.formFieldTranslation}</DetailsFieldTitle>
        </td>

        <td>
            {!Array.isArray(field.value) || field.value.length === 0 ? (
                <BlankField />
            ) : (
                <DetailsFieldValue copyText={field.value[0]}>{field.value[0]}</DetailsFieldValue>
            )}
        </td>
    </tr>
);

export default FormSubmissionSelectSingleField;
