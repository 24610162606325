import React, { FunctionComponent, memo, useContext } from "react";
import { faWindowClose } from "@fortawesome/free-regular-svg-icons/faWindowClose";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ModalContext } from "../../modal-context";

import { IProps } from "./close-button.types";
import "./close-button.scss";

/**
 * A button that will close the modal.
 * @param props.label An optional aria label for screenreaders.
 */
const CloseButton: FunctionComponent<IProps> = ({ label }) => {
    const { closeModal } = useContext(ModalContext);
    return (
        <button className="close-modal-button" onClick={closeModal} aria-label={label}>
            <FontAwesomeIcon icon={faWindowClose} title="Close modal" />
        </button>
    );
};

export default memo(CloseButton);
