import { SelectField } from "@edgetier/select";
import { ISkill } from "redux/modules/setup/setup.types";
import useSkills from "utilities/use-skills";

import { IProps } from "./select-skill.types";

/**
 * Drop-down to select one or more skills.
 * @returns Select component.
 */
const SelectSkill = ({ name = "skillId", parameters = {}, ...otherProps }: IProps) => {
    const { data: skills, isLoading } = useSkills(parameters, {
        select: (skills) => skills.sort((first, second) => first.skill.localeCompare(second.skill)),
    });

    return (
        <SelectField<ISkill, number>
            description="skill"
            getLabel={({ skill }) => skill}
            getValue={({ skillId }) => skillId}
            isLoading={isLoading}
            items={skills || []}
            name={name}
            {...otherProps}
        />
    );
};

export default SelectSkill;
