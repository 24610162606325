import { FunctionComponent, memo } from "react";
import { Tooltip } from "@edgetier/components";

import { formatShortcutKeys } from "./shortcut-tooltip.utilities";
import { IProps } from "./shortcut-tooltip.types";

/**
 * A tooltip that shows what keys to use how to complete an action using a keyboard shortcut.
 * @param props.childen      The trigger of the tooltip.
 * @param props.isDisabled
 * @param props.shortcutKeys Keys for the shortcut in moustrap format.
 */
const ShortcutTooltip: FunctionComponent<IProps> = ({ children, isDisabled = false, shortcutKeys }) => (
    <Tooltip
        content={<div className="shortcut-tooltip__content">{formatShortcutKeys(shortcutKeys)}</div>}
        delayEnter={750}
        disableTooltip={isDisabled}
        possiblePlacements={["top-center", "bottom-center"]}
    >
        {children}
    </Tooltip>
);

export default memo(ShortcutTooltip);
