import React from "react";
import { doNothing } from "@edgetier/utilities";

import ModalContext from "./../context";
import ModalProvider from "./../provider/provider";
import { IProps } from "./container.types";

export default class Container extends React.PureComponent<IProps> {
    static defaultProps = { isOpen: false, onHide: doNothing, onShow: doNothing };

    /**
     * Render the modal as a portal.
     * @returns React portal.
     */
    render(): React.ReactNode {
        const { children, ...otherProps } = this.props;
        return (
            <ModalProvider {...otherProps}>
                <ModalContext.Consumer>
                    {(context) => (typeof children === "function" ? children(context) : children)}
                </ModalContext.Consumer>
            </ModalProvider>
        );
    }
}
