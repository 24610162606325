import { FunctionComponent, memo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStickyNote } from "@fortawesome/pro-solid-svg-icons";

import { getItemId } from "components-for/query-history/query-history-timeline/query-history-timeline.utilities";
import { getUserId } from "utilities/local-storage";
import { TimelineItem } from "components/timeline";

import QueryHistoryTimelineItem from "../query-history-timeline-item";

import { IProps } from "./query-history-timeline-note.types";
import "./query-history-timeline-note.scss";

/**
 * Display a note in a history timeline.
 * @param props.focusItem           The initially selected item.
 * @param props.note                Note to display.
 * @param props.interactionDetailId  Optional identifier for attaching notes to specific interactions.
 * @param props.showPreview         Whether or not to show a preview of each note or interaciton.
 * @returns                         Details of an interaction.
 */
const QueryHistoryTimelineNote: FunctionComponent<IProps> = ({ focusItem, interactionDetailId, note, showPreview }) => {
    const userId = getUserId();
    return (
        <TimelineItem
            autoFocus={typeof focusItem !== "undefined" && "noteId" in focusItem && focusItem.noteId === note.noteId}
            date={note.dateTime}
            key={getItemId(note)}
            guideOnly={showPreview}
            icon={
                <FontAwesomeIcon className="query-history-timeline-note__icon" fixedWidth={true} icon={faStickyNote} />
            }
            value={note}
        >
            {showPreview && (
                <QueryHistoryTimelineItem
                    subject={null}
                    body={note.note}
                    warning={
                        note.interactionDetailId === interactionDetailId && note.userId !== userId
                            ? "Note about current interaction"
                            : null
                    }
                >
                    Note
                </QueryHistoryTimelineItem>
            )}
        </TimelineItem>
    );
};

export default memo(QueryHistoryTimelineNote);
