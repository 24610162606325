import { IActionsUnion } from "redux/types";
import { ILoadingBlockerState } from "redux/modules/loading-blocker/loading-blocker.types";

import actions from "./loading-blocker-actions";
import LoadingBlockerType from "./loading-blocker-type";

type Action = IActionsUnion<typeof actions>;

export default function (state: ILoadingBlockerState = { isLoading: false }, action: Action): ILoadingBlockerState {
    switch (action.type) {
        case LoadingBlockerType.Hide:
            return { ...state, isLoading: false };
        case LoadingBlockerType.Show:
            return { ...state, isLoading: true };
        default:
            return state;
    }
}
