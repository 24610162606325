import { useQuery, UseQueryOptions } from "react-query";
import { TVariable, TVariablesParameters, Url } from "@edgetier/types";
import { AxiosError } from "axios";
import { getAllPaginatedItems } from "utilities/get-all-paginated-items";

/**
 * Request the variables from the backend.
 */
const requestVariables = async (url: string, queryParameters: TVariablesParameters) => {
    const data = await getAllPaginatedItems<TVariable>(url, { params: queryParameters });
    return data;
};

/**
 * A react-query hook to get variables from the backend.
 * @param queryParameters The query parameters for the request.
 * @param options Optional react query options.
 */
const useVariables = <T = TVariable[]>(
    queryParameters: TVariablesParameters = { limit: 100 },
    options: Omit<UseQueryOptions<TVariable[], AxiosError, T>, "queryKey" | "queryFn"> = {}
) => {
    const url = Url.Variables;
    return useQuery<TVariable[], AxiosError, T>(
        [url, queryParameters],
        () => requestVariables(url, queryParameters),
        options
    );
};

export default useVariables;
