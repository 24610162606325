import Axios from "axios";
import React from "react";
import urlJoin from "url-join";
import { Url } from "@edgetier/types";

import FormSubmission from "components/form-submission";
import axios from "utilities/axios";
import { IFormSubmissionField } from "redux/application.types";

import { IProps, IState } from "./form-submission-loader.types";

/**
 * Request and display a form submission.
 */
export default class FormSubmissionLoader extends React.PureComponent<IProps, IState> {
    cancelTokenSource = Axios.CancelToken.source();
    state = { formSubmissionFields: null };

    /**
     * Cancel requets on unmount.
     */
    componentWillUnmount() {
        this.cancelTokenSource.cancel();
    }

    /**
     * Request the form submission.
     */
    async componentDidMount() {
        try {
            const configuration = { cancelToken: this.cancelTokenSource.token };
            const url = urlJoin(Url.FormSubmissions, this.props.formSubmissionId.toString());
            const response = await axios.get<{ formSubmissionFields: IFormSubmissionField[] }>(url, configuration);
            this.setState({ formSubmissionFields: response.data.formSubmissionFields });
        } catch (serverError) {
            if (!Axios.isCancel(serverError) && Axios.isAxiosError(serverError)) {
                this.setState({ formSubmissionFields: [] });
            }
        }
    }

    /**
     * Display a spinner until the form submission has been requested and then show that.
     * @returns Spinner or form submission.
     */
    render() {
        return (
            <FormSubmission
                actionButton={this.props.actionButton}
                formSubmissionFields={this.state.formSubmissionFields}
                preferredLanguageId={this.props.preferredLanguageId}
                secondaryLanguageId={this.props.secondaryLanguageId}
                title={this.props.title}
            />
        );
    }
}
