import React from "react";
import { faComments, faInboxIn, faInboxOut, faRobot } from "@fortawesome/pro-duotone-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { IProps } from "./interaction-icon.types";
import "./interaction-icon.scss";

/**
 * Show a chat, call, inbound email, or outbound email icon.
 * @param props Props containing booleans to say what type of interaction is being displayed.
 * @returns     Icon element.
 */
const InteractionIcon = ({ isCall, isChat, isEmailIn, isAutomated }: IProps) =>
    isCall ? (
        <FontAwesomeIcon fixedWidth={true} icon={faPhone} />
    ) : isChat ? (
        <FontAwesomeIcon className="interaction-icon interaction-icon--chat" swapOpacity fixedWidth icon={faComments} />
    ) : isEmailIn ? (
        <FontAwesomeIcon className="interaction-icon interaction-icon--email-in" fixedWidth icon={faInboxIn} />
    ) : isAutomated ? (
        <FontAwesomeIcon className="interaction-icon interaction-icon--robot" fixedWidth icon={faRobot} />
    ) : (
        <FontAwesomeIcon className="interaction-icon interaction-icon--email-out" fixedWidth icon={faInboxOut} />
    );

export default InteractionIcon;
