import { useContext } from "react";

import accordionItemContext from "./accordion-item-context";

/**
 * Provide the accordion item context or throw an error if it does not exist.
 * @returns Accordion item context.
 */
export default function useAccordionItemContext() {
    const context = useContext(accordionItemContext);
    if (typeof context === "undefined") {
        throw new Error("No accordion item context provided.");
    }
    return context;
}
