import Attachments from "shared/attachments";
import MessageText from "./message-text";
import MessageTime from "./message-time";
import { Fragment } from "react";
import { IProps } from "./message-content.types";
import { MessageType } from "@edgetier/types";
import { ToggleTranslationButton, ToggleTranslationSection, Translation } from "shared/toggle-translation";

/**
 * Display a chat message. This could be from the agent, customer, or a system message generated from the backend. If
 * the other person is typing, a blank message is displayed with animated dots.
 *
 * All URLs are detected and turned into clickable links. Depending on whether this is an agent or customer, the links
 * will open in a new tab (agents) or the parent window (customers).
 *
 * @param props.children            A render prop function that returns a delete attachment button for the attachment provided.
 * @param props.getAttachment       Callback to open attachments.
 * @param props.linkTarget          Where to open links (could be the parent or new tab).
 * @param props.message             The chat message to display.
 * @param props.onBlocked           Callback when attachments are blocked.
 * @param props.onServerError       Callback to show server errors when opening attachments.
 * @param props.preferredLanguageId Default language to show if there are multiple translations available.
 * @param props.secondaryLanguageId Alternative translation available, if any.
 * @param props.showTranslations    Whether to show button to translate the message or not.
 * @returns                         Message content.
 */
const MessageContent = ({
    addAccessibilityRole,
    children,
    getAttachment,
    isPlainMessage,
    linkTarget,
    menu,
    message,
    onBlocked,
    onServerError,
    preferredLanguageId,
    secondaryLanguageId,
    showTranslations,
}: IProps) => (
    <Fragment>
        {message.messageTypeId === MessageType.Status ? (
            <MessageText
                addAccessibilityRole={true}
                linkTarget={linkTarget}
                translation={Object.values(message.translations)[0].translation}
            />
        ) : isPlainMessage ? (
            <MessageText
                addAccessibilityRole={addAccessibilityRole}
                linkTarget={linkTarget}
                translation={message.message}
            />
        ) : preferredLanguageId === secondaryLanguageId ? (
            <Translation languageId={preferredLanguageId} translations={message.translations}>
                {({ translation }) =>
                    typeof translation === "string" && (
                        <MessageText
                            addAccessibilityRole={addAccessibilityRole}
                            linkTarget={linkTarget}
                            translation={translation}
                        />
                    )
                }
            </Translation>
        ) : (
            <ToggleTranslationSection translations={message.translations}>
                {({ translation }) =>
                    typeof translation === "string" && (
                        <MessageText
                            addAccessibilityRole={addAccessibilityRole}
                            linkTarget={linkTarget}
                            translation={translation}
                        />
                    )
                }
            </ToggleTranslationSection>
        )}
        <Attachments
            attachments={message.attachments}
            getAttachment={getAttachment}
            onBlocked={onBlocked}
            onServerError={onServerError}
        >
            {children}
        </Attachments>

        {message.messageTypeId !== MessageType.Status && (
            <div className="chat-messages__message__extras">
                {showTranslations &&
                    preferredLanguageId !== secondaryLanguageId &&
                    Object.keys(message.translations).length > 1 && <ToggleTranslationButton />}

                {menu}

                <MessageTime date={new Date(message.timestamp)} />
            </div>
        )}
    </Fragment>
);

export default MessageContent;
