import { IProactiveChatState } from "./proactive-chat.types";
import { IActionsUnion } from "redux/types";
import actions from "./proactive-chat-actions";
import ProactiveChatType from "./proactive-chat-type";

type IProactiveChatAction = IActionsUnion<typeof actions>;

export const initialState: IProactiveChatState = {
    enabled: false,
    isProactiveChatEnabledOnUser: false,
};

/**
 * Update the proactive chat state.
 * @param state  Proactive chat state.
 * @param action Redux action.
 * @returns      Updated proactive chat state.
 */
export default function reducer(
    state: IProactiveChatState = initialState,
    action: IProactiveChatAction
): IProactiveChatState {
    switch (action.type) {
        // Store proactive chat.
        case ProactiveChatType.Store:
            return {
                ...state,
                ...action.payload,
            };

        // Enable or disable proactive chat.
        case ProactiveChatType.Toggle:
            return {
                ...state,
                enabled: action.payload,
            };
        default:
            return state;
    }
}
