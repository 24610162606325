import axios from "axios";

/**
 * Determine if a failed request should retry based on its error message.
 * @param failureCount The number of times the request has failed.
 * @param error        The error that has been thrown.
 * @returns True if the request should retry, false otherwise.
 */
export const shouldRequestRetry = (failureCount: number, error: unknown) => {
    // If the request has failed three times, don't retry again.
    if (failureCount >= 2) {
        return false;
    }

    if (axios.isAxiosError(error)) {
        if (typeof error.response === "undefined") {
            return false;
        }
        // https://developer.mozilla.org/en-US/docs/Web/HTTP/Status
        const code = error.response.status;
        // Don't retry for client errors.
        if (code >= 400 && code < 500) {
            return false;
        }
        // Don't retry if the route is not implemented.
        if (code === 501) {
            return false;
        }
        // Do retry if there's a server error because there could be a transient outage.
        if (code >= 500 && code <= 504) {
            return true;
        }
    }
    return false;
};
