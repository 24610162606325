import * as Yup from "yup";
import Axios from "axios";
import { FunctionComponent, memo, useContext, useEffect, useRef } from "react";
import urlJoin from "url-join";
import { useDispatch } from "react-redux";
import { faUserCheck } from "@fortawesome/free-solid-svg-icons/faUserCheck";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Formik } from "formik";
import { Url } from "@edgetier/types";
import { Button } from "@edgetier/components";

import InputError from "constants/input-error";
import { ampersandList } from "@edgetier/client-components";
import SelectAgent from "components/select-agent";
import axios from "utilities/axios";
import { IUser } from "redux/application.types";
import { loadingBlockerOperations } from "redux/modules/loading-blocker";
import Modal, { ModalContent, ModalHeader, useModal } from "@edgetier/modal";
import { toastOperations } from "redux/modules/toast";

import { IFormValues, IProps } from "./assign-agents.types";
import { showErrorToast } from "redux/modules/toast/toast-operations";
import { doNothing, getServerError } from "@edgetier/utilities";
import { InteractionSearchResultsContext } from "context-for/interaction-search-results/interaction-search-results-context";

/**
 * Manually assign agents to handle an email or emails.
 * @param props.emailIds  The IDs of the emails to be assigned.
 * @param props.onSuccess Callback to be invoked when the assignment is successful. f
 */
const AssignAgents: FunctionComponent<IProps> = ({ emailIds, onSuccess = doNothing }) => {
    const dispatch = useDispatch();
    const cancelTokenSource = useRef(Axios.CancelToken.source());
    useEffect(() => cancelTokenSource.current.cancel, []);

    const { interactions, updateInteractions } = useContext(InteractionSearchResultsContext);

    const { modalProps, openModal, closeModal } = useModal();

    /**
     * Are any emails enabled? This will determine if the button itself if enabled (i.e. clickable).
     */
    const isEnabled = (): boolean => {
        return emailIds.length > 0;
    };

    /**
     * Create a description of the number of emails.
     */
    const labelEmails = (): string => {
        if (emailIds.length === 1) {
            return "the selected email";
        }
        return `the ${emailIds.length} selected emails`;
    };

    /**
     * The user has selected one or more agents to assign the email or emails to.
     * @param values Form values containing agents that the email or emails should be assigned to.
     */
    const onSubmit = async ({ userIds }: IFormValues): Promise<void> => {
        try {
            dispatch(loadingBlockerOperations.showLoadingBlocker(true));

            // Request that the backend assign the email(s) to the selected user(s).
            const configuration = { cancelToken: cancelTokenSource.current.token };

            await axios.post(Url.ManualRoutingEmails, { emailIds, userIds }, configuration);

            // Request all assigned users' details.
            const usersResponse = await Promise.all(
                userIds.map((userId) => axios.get<IUser>(urlJoin(Url.Users, userId.toString()), configuration))
            );

            // Generate a toast message.
            const users = usersResponse.map(({ data }) => data);
            const emailLabel = emailIds.length === 1 ? "email" : "emails";
            const names = users.map(({ firstName, surname }) => `${firstName} ${surname}`);
            dispatch(
                toastOperations.showSuccessToast(
                    "Assignment Successful",
                    `Assigned ${emailLabel} to ${ampersandList(names)}`
                )
            );

            updateInteractions(
                (interactions ?? []).map((interaction) => ({
                    ...interaction,
                    assignedTo:
                        "emailId" in interaction && emailIds.includes(interaction.emailId)
                            ? names
                            : interaction.assignedTo,
                }))
            );

            onSuccess(users);
            closeModal();
        } catch (serverError: any) {
            dispatch(showErrorToast("Assignment Failed", getServerError(serverError)));
        } finally {
            dispatch(loadingBlockerOperations.hideLoadingBlocker(true));
        }
    };

    return (
        <>
            <Modal {...modalProps}>
                <ModalHeader>
                    <h2>Assign Agents</h2>
                </ModalHeader>
                <ModalContent>
                    <div>
                        <p>Please select one or more agents to assign {labelEmails()}.</p>
                        <Formik<IFormValues>
                            initialValues={{ userIds: [] }}
                            onSubmit={onSubmit}
                            validationSchema={Yup.object().shape({
                                userIds: Yup.array<number>().min(1, InputError.Required),
                            })}
                        >
                            {({ dirty, isValid }) => (
                                <Form name="assign agents">
                                    <div className="field">
                                        <label htmlFor="userIds">Agent</label>
                                        <SelectAgent isCompact name="userIds" isFilter={false} />
                                    </div>
                                    <button className="button" disabled={!dirty || !isValid} type="submit">
                                        <FontAwesomeIcon icon={faUserCheck} />
                                        Assign agents
                                    </button>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </ModalContent>
            </Modal>
            <Button className="button--no-modal" disabled={!isEnabled()} icon={faUserCheck} onClick={openModal}>
                Assign agents
            </Button>
        </>
    );
};

export default memo(AssignAgents);
