import React from "react";
import { useFormikContext } from "formik";

import KeyboardShortcut from "../keyboard-shortcut";

import { IProps } from "./submit-form-keyboard-shortcut.types";

/**
 * Creates a keyboard shortcut for submitting a form.
 * @param props.scope    An optional scope in which the shortcut will be active.
 * @param props.shortcut Keys for the shortcut in moustrap format.
 */
const SubmitFormKeyboardShortcut = ({ shortcut = "command+enter, ctrl+enter", ...props }: IProps) => {
    const { submitForm } = useFormikContext();

    const submit = () => {
        submitForm();
    };

    return <KeyboardShortcut shortcut={shortcut} callback={submit} {...props} />;
};

export default SubmitFormKeyboardShortcut;
