import { createContext } from "react";
import { doNothing } from "@edgetier/utilities";

import { IModalContext } from "./context.types";

const ModalContext = createContext<IModalContext>({
    canClose: true,
    hideModal: doNothing,
    isOpen: false,
    showModal: doNothing,
});

export default ModalContext;
