import { DetailsFieldTitle } from "@edgetier/client-components";

import TextFieldValue from "../text-field-value";

import { IProps } from "./form-submission-textarea-field.types";
import "./form-submission-textarea-field.scss";

/**
 * Display the contents of a textarea submitted through a form.
 * @param props.field Textarea field submission.
 * @returns           Title and value of the field.
 */
const FormSubmissionTextareaField = ({ field }: IProps) => (
    <tr className="form-submission-textarea-field">
        <td colSpan={2}>
            <DetailsFieldTitle>{field.formFieldTranslation}</DetailsFieldTitle>
            <TextFieldValue>{field.value}</TextFieldValue>
        </td>
    </tr>
);

export default FormSubmissionTextareaField;
