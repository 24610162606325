/**
 * Create a dictionary mapping IDs to items.
 * @param items Array of items to key.
 * @param getId Getter to retrieve the ID of an item.
 * @returns     Lookup from ID to item.
 */
export function keyById<IItem>(items: IItem[], getId: (item: IItem) => number) {
    const result: { [index: number]: IItem } = {};
    items.forEach((item) => {
        result[getId(item)] = item;
    });
    return result;
}
