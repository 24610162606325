export const CHART_HEIGHT = 260;
export const CHART_MARGIN_LABELLED = { top: 16, right: 40, bottom: 40, left: 16 };
export const CHART_MARGIN = { top: 16, right: 40, bottom: 16, left: 16 };
export const EMAIL_QUEUE_LENGTH: number = 1;
export const DEFAULT_LANGUAGE_ID = 11;

// Root element.
export const ROOT_ID = "root";

// Valid HEX colour pattern.
export const COLOUR_PATTERN = /^#(?:[0-9a-fA-F]{3}){1,2}$/;
