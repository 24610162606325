import classNames from "classnames";
import React from "react";

import { IProps } from "./timer-bar.types";

/**
 * Countdown timer to signify something will be triggered when some amount of time has elapsed.
 * @param props.animateColour  Whether the colour of the bar should change over time or not.
 * @param props.elapsedSeconds How much time has already passed. This will skip some of the countdown.
 * @param props.totalSeconds   The countdown time in seconds.
 * @returns                    Animated bar.
 */
const TimerBar = ({ animateColour, barColour, elapsedSeconds, totalSeconds }: IProps) => (
    <div className={classNames("timer-bar", { "timer-bar--animated": animateColour })}>
        <div
            role="timer"
            className="timer-bar__inner"
            style={{
                animationDelay: `-${elapsedSeconds}s`,
                animationDuration: `${totalSeconds}s`,
                backgroundColor: barColour,
            }}
        />
    </div>
);

export default React.memo(TimerBar);
