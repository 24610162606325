// Promise reject value when it is cancelled.
export const CANCELLED = { __isPromiseCancelled__: true };

/**
 * Make a promise cancelleable. This is taken with some small modification from the official React documentation.
 * @param promise The promise that can be cancelled.
 * @returns       A promise and a function to cancel it.
 */
export function makeCancellable<T>(promise: Promise<T>) {
    let hasCancelled = false;
    return {
        cancel() {
            hasCancelled = true;
        },
        promise: new Promise<T>((resolve, reject) => {
            promise.then(
                (value) => (hasCancelled ? reject(CANCELLED) : resolve(value)),
                (error) => (hasCancelled ? reject(CANCELLED) : reject(error))
            );
        }),
    };
}

/**
 * Check if a value is a cancelled promise.
 *
 * @param value Value or object that might be a cancelled promise.
 * @returns     True if the value was cancelled.
 *
 * @example
 *
 * // Cancelled promise value returns true.
 * const { cancel, promise } = makeCancellable(() => Promise.resolve([]))
 * cancel();
 *
 * try {
 *     await promise;
 * } catch (someError) {
 *     isCancelled(someError);
 * }
 */
export function isCancelled(value: any): boolean {
    return value === CANCELLED;
}
