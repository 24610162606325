import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { faTimes, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { faStopwatch } from "@fortawesome/free-solid-svg-icons/faStopwatch";

export enum QueryState {
    AwaitingAction = 1,
    Blocked = 2,
    Complete = 3,
}

export const QUERY_STATE_ICONS: { [Key in QueryState]: IconDefinition } = {
    [QueryState.Complete]: faCheck,
    [QueryState.Blocked]: faTimes,
    [QueryState.AwaitingAction]: faStopwatch,
};
