/**
 * Trim the string values of an object.
 * @param object An object who's string values need to be trimmed.
 * @returns The object that was passed in but with it's string values trimmed.
 */
export const trimObjectValues = <T extends {}>(object: T) => {
    return Object.entries(object).reduce((trimmedObject, [key, value]) => {
        if (typeof value === "string") {
            return {
                ...trimmedObject,
                [key]: value.trim(),
            };
        }
        return trimmedObject;
    }, object);
};
