import {
    faMicrophone,
    faMicrophoneSlash,
    faPause,
    faPhoneVolume,
    faPlay,
    faVoicemail,
} from "@fortawesome/free-solid-svg-icons";

import { IInteractionEventDisplay } from "components/interaction-events/interaction-event/interaction-event.types";
import CallEventType from "constants/call-event-type";

import {
    AGENT_INVITE_EVENT,
    AGENT_INVITE_NEGATIVE_EVENT,
    CREATE_EVENT,
    ENTER_QUEUE_EVENT,
    FINISH_EVENT,
    LANGUAGE_CHANGE_EVENT,
    SUBMIT_FORM_EVENT,
    TRANSFER_NEGATIVE_EVENT,
    TRANSFER_REQUEST_EVENT,
    TRANSFER_SUCCESS_EVENT,
    USER_CONNECT_EVENT,
    USER_DISCONNECT_EVENT,
} from "../../../interaction.constants";

export const CALL_EVENT_DISPLAY: { [key in CallEventType]: IInteractionEventDisplay } = {
    [CallEventType.Create]: CREATE_EVENT,
    [CallEventType.SubmitForm]: SUBMIT_FORM_EVENT,
    [CallEventType.AgentConnect]: USER_CONNECT_EVENT,
    [CallEventType.CustomerConnect]: USER_CONNECT_EVENT,
    [CallEventType.AgentDisconnect]: USER_DISCONNECT_EVENT,
    [CallEventType.CustomerDisconnect]: USER_DISCONNECT_EVENT,
    [CallEventType.TransferRequest]: TRANSFER_REQUEST_EVENT,
    [CallEventType.TransferSuccess]: TRANSFER_SUCCESS_EVENT,
    [CallEventType.TransferFailure]: TRANSFER_NEGATIVE_EVENT,
    [CallEventType.TransferCancel]: TRANSFER_NEGATIVE_EVENT,
    [CallEventType.Finish]: FINISH_EVENT,
    [CallEventType.LanguageChange]: LANGUAGE_CHANGE_EVENT,
    [CallEventType.AgentRejectInvite]: AGENT_INVITE_NEGATIVE_EVENT,
    [CallEventType.AgentInviteTimeout]: AGENT_INVITE_NEGATIVE_EVENT,
    [CallEventType.AgentInvite]: AGENT_INVITE_EVENT,
    [CallEventType.EnterQueue]: ENTER_QUEUE_EVENT,

    //Events unique to calls.
    [CallEventType.VoicemailConnect]: { icon: faVoicemail },
    [CallEventType.VoicemailDisconnect]: { icon: faVoicemail, styleName: "negative" },
    [CallEventType.OnHoldOn]: { icon: faPause },
    [CallEventType.OnHoldOff]: { icon: faPlay },
    [CallEventType.UserConnect]: USER_CONNECT_EVENT,
    [CallEventType.UserDisconnect]: USER_DISCONNECT_EVENT,
    [CallEventType.IvrConnect]: { icon: faMicrophone, styleName: "positive" },
    [CallEventType.IvrDisconnect]: { icon: faMicrophoneSlash, styleName: "negative" },
    [CallEventType.CallbackRequest]: { icon: faPhoneVolume },
};
