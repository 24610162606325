import { Url } from "@edgetier/types";
import urlJoin from "url-join";
import { AxiosError } from "axios";
import { useDispatch } from "react-redux";
import { useQueryClient, useMutation, UseMutationOptions } from "react-query";

import { IQueryNotesQueryCache } from "hooks-for/query-history/use-query-notes/use-query-notes.types";
import { INote } from "redux/application.types";
import { loadingBlockerOperations } from "redux/modules/loading-blocker";
import { toastOperations } from "redux/modules/toast";

import { patchOrPostNote } from "./use-create-note.utilities";
import { IProps } from "./use-create-note.types";

/**
 * Return a mutation to create a new note or patch an existing one if the pre-existing note is provided.
 * @param props.interactionDetailId Optional identifier to tie the note to a specific call, chat, or email.
 * @param props.queryId             Query where the note will be appended.
 * @param props.onComplete          Method to call after the note has been submitted.
 * @param props.note                Optional existing note if it is being edited. Otherwise a new one will be created.
 */
const useCreateNote = (
    { interactionDetailId, queryId, onComplete, note }: IProps,
    configuration: UseMutationOptions<INote, AxiosError, string> = {}
) => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();

    /**
     * Show an error as a toast message.
     * @param serverError Backend error.
     */
    const onError = (serverError: AxiosError) => {
        dispatch(toastOperations.showServerErrorToast("Error", serverError));
    };

    /**
     * Show the loading blocker when the request starts.
     */
    const onMutate = () => {
        dispatch(loadingBlockerOperations.showLoadingBlocker(true));
    };

    /**
     * Hide the loading blocker when the request ends.
     */
    const onSettled = () => {
        dispatch(loadingBlockerOperations.hideLoadingBlocker(true));
    };

    /**
     * Handle a note being created/edited successfully. This may need to update the query cache to add the new note or
     * update an existing one to ensure the user's display is updated appropriately.
     * @param backendNote Newly created note.
     */
    const onSuccess = async (updatedNote: INote) => {
        const { noteId } = updatedNote;

        // If the cache contains query notes it should be updated. Otherwise if the cache was already empty it can be
        // left that way.
        const queryNotesUrl = urlJoin(Url.Query, queryId.toString(), Url.Notes);
        queryClient.setQueryData<IQueryNotesQueryCache | undefined>(queryNotesUrl, (notes) =>
            typeof notes === "undefined" ? undefined : { ...notes, [noteId]: updatedNote }
        );

        // Individual notes may also be cached.
        const noteUrl = urlJoin(Url.Notes, updatedNote.noteId.toString());
        queryClient.setQueryData<INote | undefined>(noteUrl, updatedNote);

        // Inform the user that the creation or update was successful.
        if (typeof note === "undefined") {
            dispatch(toastOperations.showSuccessToast("Created", "Your note was saved"));
        } else {
            dispatch(toastOperations.showSuccessToast("Edit Successful", "Your note was updated"));
        }

        // Let the parent component know the note was created or updated.
        if (typeof onComplete === "function") onComplete();
    };

    // A mutation to create or edit a note.
    const mutationSettings = { onError, onMutate, onSettled, onSuccess, ...configuration };
    return useMutation<INote, AxiosError, string>(
        patchOrPostNote(interactionDetailId, queryId, note),
        mutationSettings
    );
};

export default useCreateNote;
