import { doNothing } from "@edgetier/utilities";
import { createContext } from "react";
import { UserState } from "redux/application.types";
import { IUserStateContext } from "./user-state-context.types";

export const defaultContext: IUserStateContext = {
    isUserStateLoading: false,
    userStateId: UserState.Unavailable,
    setUserStateId: doNothing,
    setIsUserStateLoading: doNothing,
};

export const UserStateContext = createContext<IUserStateContext>(defaultContext);
export default UserStateContext;
