import React, { memo } from "react";
import { Form, Formik } from "formik";
import classNames from "classnames";
import { SubmitOnChange } from "@edgetier/components";

import TimelineProvider from "./timeline-provider";
import { IProps } from "./timeline.types";

import "./timeline.scss";

/**
 * Display a list of items along a chronological timeline. This is wrapped in a form and uses radio buttons so that
 * keyboard arrows etc. work without an extra code.
 * @param props.children       Items to display in the timeline.
 * @param props.initialValue   Optional value to select at first.
 * @param props.submitOnChange Fire the select event on any change. Otherwise, the user will have to press enter.
 * @param props.onSelect       Callback when an item is selected.
 * @param props.hasMoreItems   Only for visual purpose, keeps the guide line continuous or not
 * @returns                    List of chronological items.
 */
const Timeline = <T extends {}>({
    children,
    getItemId,
    initialValue,
    submitOnChange,
    onSelect,
    hasMoreItems,
}: IProps<T>) => {
    const timelineClassNames = classNames("timeline", {
        "timeline__more-items": hasMoreItems,
    });
    return (
        <div className={timelineClassNames}>
            <Formik
                enableReinitialize={true}
                initialValues={{ item: initialValue }}
                onSubmit={({ item }) => onSelect(item)}
            >
                <Form>
                    <TimelineProvider getItemId={getItemId} onSelect={onSelect}>
                        <fieldset>{children}</fieldset>
                    </TimelineProvider>
                    <button type="submit">Submit</button>
                    {submitOnChange && <SubmitOnChange delay={0} />}
                </Form>
            </Formik>
        </div>
    );
};

export default memo(Timeline) as typeof Timeline;
