import React from "react";
import formatDistance from "date-fns/formatDistance";

import { IProps } from "./total-handling-time.types";
import { SECONDS_THRESHOLD } from "./total-handling-time.constants";

/**
 * Show the handling time for a chat or email.
 * @returns Handling time.
 */
export default ({ totalHandlingTime }: IProps) => (
    <React.Fragment>
        {totalHandlingTime < SECONDS_THRESHOLD
            ? `${Math.round(totalHandlingTime / 1000)} seconds`
            : formatDistance(0, totalHandlingTime)}
    </React.Fragment>
);
