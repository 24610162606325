import { IMessage } from "@edgetier/types";

import { IBackendChat, IChatInvite, IChatQuery, IResumeChat, IProposedActivity } from "redux/modules/chat/chat.types";
import { ICustomerUrl, ISettings, IUser } from "redux/application.types";

import { IBlankEmail } from "../email/email.types";

import ChatType from "./chat-type";
import { IFormValues } from "@edgetier/dynamic-form";

const createAction = <T extends { type: ChatType }>(action: T) => action;

/**
 * Switch to a given chat.
 * @param payload Identifier of the chat being activated.
 * @returns       Activate chat action.
 */
const activateChat = (payload: string) =>
    createAction({
        type: ChatType.ActivateChat,
        payload,
    });

/**
 * Join a chat with a customer.
 * @param payload The chat identifier of the chat being entered and various other details.
 * @returns       An action with the chat token as the payload.
 */
const enterChat = (payload: {
    chat: IBackendChat;
    chatToken: string;
    customerUrls: ICustomerUrl[];
    translatedToLanguageId: number | null;
    settings: ISettings;
}) =>
    createAction({
        type: ChatType.EnterChat,
        payload,
    });

/**
 * Set hasBeenForceDisabled to the payload value.
 * @param payload The new value for hasBeenForceDisabled.
 * @returns       A set hasBeenForceDisabled action with hasBeenForceDisabled as a payload.
 */
const setHasBeenForceDisabled = (payload: { hasBeenForceDisabled: boolean }) =>
    createAction({ type: ChatType.SetHasBeenForceDisabled, payload });

/**
 * The agent has finished with a chat so it can be removed from their screen.
 * @param payload The chat ID to leave.
 * @returns       A leave chat action with the chat ID as the payload.
 */
const removeChat = (payload: { chatToken: string }) =>
    createAction({
        type: ChatType.RemoveChat,
        payload,
    });

/**
 * Remove a chat from the waiting list.
 * @param chatToken          The chat that should have the proposed activity removed.
 * @param proposedActivityId Identifier of the proposed activitiy to remove.
 */
const removeProposedActivity = (chatToken: string, proposedActivityId: string) =>
    createAction({
        type: ChatType.RemoveProposedActivity,
        payload: { chatToken, proposedActivityId },
    });

/**
 * Remove a chat from the waiting list.
 * @param payload The chat ID of the waiting chat.
 */
const removeWaitingChat = (payload: { chatToken: string }) =>
    createAction({
        type: ChatType.RemoveInvite,
        payload,
    });

/**
 * Resume multiple chats. This may be required after a page refresh or reconnect.
 * @param payload Chats to resume.
 */
const resumeChats = (payload: IResumeChat[]) =>
    createAction({
        type: ChatType.ResumeChats,
        payload,
    });

/**
 * When a transfer request is made, it can only continue for a certain period of time until it must be cancelled. This
 * is because the agent initiating it has responsibility for the chat. Therefore if no other agent accepts or rejects
 * the transfer within a certain period of time, it is cancelled. The timeout ID keeps track of the wait time and can
 * be used to cancel the cancellation after a successful/failed transfer.
 * @param payload Action payload containing chat token and timeout ID.
 * @returns       Set timeout ID action.
 */
const setTransferTimeoutId = (payload: { chatToken: string; transferTimeoutId: number | null }) =>
    createAction({
        type: ChatType.SetTransferTimeoutId,
        payload,
    });

/**
 * Store a new URL that the customer has navigated to during a chat.
 * @param payload Details of the URL change.
 * @returns       Store customer URL action.
 */
const storeCustomerUrl = (payload: { chatToken: string; customerUrl: ICustomerUrl }) =>
    createAction({
        type: ChatType.StoreCustomerUrl,
        payload,
    });

/**
 * Offer a chat to the agent.
 * @param payload Details of the chat.
 * @returns       Offer chat action.
 */
const storeInvite = (payload: IChatInvite) =>
    createAction({
        type: ChatType.StoreInvite,
        payload,
    });

/**
 * Store a message sent or received.
 * @param payload Message sent by the backend or typed by the user.
 * @returns       A sent/received message action.
 */
const storeMessage = (payload: IMessage) =>
    createAction({
        type: ChatType.StoreMessage,
        payload,
    });

/**
 * Store one or more suggested chat activities.
 * @param chatToken          Identifier of the chat.
 * @param append             The backend will say to either append or replace the existing proposed activities.
 * @param proposedActivities Details of the activities the agent may consider.
 * @returns                  Store proposed activities item action.
 */
const storeProposedActivities = (chatToken: string, append: boolean, proposedActivities: IProposedActivity[]) =>
    createAction({
        type: ChatType.StoreProposedActivities,
        payload: { append, chatToken, proposedActivities },
    });

/**
 * Store a draft of the wrap form for a chat.
 * @param chatToken  Identifier of the chat.
 * @param formValues The form values of the wrap up form being saved.
 * @returns          Store the wrap-up form draft.
 */
const storeWrapUpFormDraft = (chatToken: string, formValues: IFormValues) =>
    createAction({
        type: ChatType.StoreWrapUpDraft,
        payload: { chatToken, formValues },
    });

/**
 * update an existing proposed activity by shallowly merging changes.
 * @param chatToken          Identifier of the chat.
 * @param proposedActivityId Id of the activity to update
 * @param proposedActivity   partially updated activity.
 * @returns                  Update proposed activities item action.
 */
const updateProposedActivity = (
    chatToken: string,
    proposedActivityId: string,
    proposedActivity: Partial<IProposedActivity>
) =>
    createAction({
        type: ChatType.UpdateProposedActivity,
        payload: { chatToken, proposedActivityId, proposedActivity },
    });

/**
 * Enable or disable chat.
 * @param payload Whether or not to turn chat on or off.
 * @returns       Offer chat action.
 */
const toggleChat = (payload: boolean) =>
    createAction({
        type: ChatType.ToggleChat,
        payload,
    });

/**
 * Enable or disable chat.
 * @param payload Whether or not to turn chat on or off.
 * @returns       Offer chat action.
 */
const toggleChatScreenOpen = (payload: boolean) =>
    createAction({
        type: ChatType.ToggleChatScreenOpen,
        payload,
    });

/**
 * Toggle the socket connection status.
 * @param payload Whether or not the socket is connected.
 * @returns       Offer chat action.
 */
const toggleConnected = (payload: { isConnected: boolean }) =>
    createAction({
        type: ChatType.ToggleConnected,
        payload,
    });

/**
 * Open or close the proposed activities display.
 * @returns Toggle proposed activities action.
 */
const toggleProposedActivitiesOpen = () =>
    createAction({
        type: ChatType.ToggleProposedActivitiesOpen,
    });

/**
 * Set the transferring state of a chat. When an agent initiates a transfer, the other agent must accept it. While this
 * is happening, the chat is in a transfer state meaning it can't be edited.
 * @param payload The chat ID of the chat being transferred and the transferring state.
 */
const toggleTransferring = (payload: { chatToken: string; isTransferring: boolean }) =>
    createAction({
        type: ChatType.ToggleTransferring,
        payload,
    });

/**
 * Update some elements of a chat including language or whatever else.
 * @param payload Payload containing the chat token to update and the values to replace.
 * @returns       Update chat action.
 */
const updateChat = (payload: { chatToken: string; data: Partial<IChatQuery> }) =>
    createAction({
        type: ChatType.UpdateChat,
        payload,
    });

const updateChatEmail = (payload: { chatToken: string; data: Partial<IBlankEmail> }) =>
    createAction({
        type: ChatType.UpdateChatEmail,
        payload,
    });

/**
 * Update a message.
 * @param payload Payload containing the new message content and the position of the message in the overall list.
 * @returns       An update message action.
 */
const updateMessage = (payload: { chatToken: string; index: number; message: Partial<IMessage> }) =>
    createAction({
        type: ChatType.UpdateMessage,
        payload,
    });

/**
 * Set whether the agent is typing or not.
 * @param payload Whether or not the agent is typing.
 * @returns       A typing update action.
 */
const updateTyping = (payload: { chatToken: string; isTyping: boolean }) =>
    createAction({
        type: ChatType.UpdateTyping,
        payload,
    });

/**
 * Update the global chat settings from global and user settings data.
 * @param   settings, global app settings.
 * @param   user,     user settings.
 * @returns           A settings update action.
 */
const updateChatSettings = (settings: ISettings, user: IUser) => {
    const { maximumConcurrentChats: userMax } = user;
    const { defaultMaximumConcurrentChats } = settings;
    const maximumConcurrentChats =
        userMax !== null && Number.isInteger(userMax) ? userMax : defaultMaximumConcurrentChats;

    return createAction({
        type: ChatType.UpdateSettings,
        payload: {
            maximumConcurrentChats,
        },
    });
};

export default {
    activateChat,
    enterChat,
    removeChat,
    removeProposedActivity,
    removeWaitingChat,
    resumeChats,
    setHasBeenForceDisabled,
    setTransferTimeoutId,
    storeCustomerUrl,
    storeInvite,
    storeMessage,
    storeProposedActivities,
    storeWrapUpFormDraft,
    toggleChat,
    toggleChatScreenOpen,
    toggleConnected,
    toggleProposedActivities: toggleProposedActivitiesOpen,
    toggleTransferring,
    updateChat,
    updateChatEmail,
    updateChatSettings,
    updateMessage,
    updateProposedActivity,
    updateTyping,
};
