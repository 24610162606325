import React, { FunctionComponent, memo, useMemo } from "react";

import EmailParticipants from "../email-participants";

import { groupRecipientsByType } from "./inbound-email-participants.utilities";
import { IProps } from "./inbound-email-participants.types";

/**
 * Display where the inbound email's been sent from, who it's sent to, and anyone cc'd or bcc'd.
 * @param props.fromEmailAddress The email address of the person who sent the email.
 * @param props.recipients       An array of recipients of the email.
 */
const InboundEmailParticipants: FunctionComponent<IProps> = ({ fromEmailAddress, recipients }) => {
    const groupedRecipients = useMemo(() => groupRecipientsByType(recipients), [recipients]);
    return <EmailParticipants fromEmailAddress={fromEmailAddress} recipients={groupedRecipients} />;
};

export default memo(InboundEmailParticipants);
