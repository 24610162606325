import { IProps } from "./language-label.types";

/**
 * Language ISO code for a translation toggle label.
 * @param props Languages and the language to find.
 * @returns     ISO code for the requested language.
 */
const LanguageLabel = ({ languageId, languages }: IProps) => {
    const value = languages.find((language) => language.languageId === languageId);
    return (
        <span className="toggle-translation__button__label">
            {typeof value === "undefined" ? "??" : value.isoCode.toUpperCase()}
        </span>
    );
};

export default LanguageLabel;
