// Shortcut keys to move up and down the table.
export const UP_ROW_KEY = "up";
export const DOWN_ROW_KEY = "down";

export const FUSE_OPTIONS = {
    caseSensitive: false,
    distance: 1000,
    shouldSort: true,
    threshold: 0.1,
    tokenize: true,
};
