import { ILanguage } from "@edgetier/types";

// Language name if no match is found.
export const UNKNOWN_LANGUAGE = "Unknown Language";

/**
 * Find the name of a language from its ID.
 * @param languages  List of all available languages.
 * @param languageId The language ID to find.
 * @returns          Language name.
 */
export function getLanguageName(
    languages: ILanguage[] | undefined,
    languageId: number,
    unknownName: string = UNKNOWN_LANGUAGE
): string {
    const language = (languages || []).find((item) => item.languageId === languageId);
    return typeof language === "undefined" ? unknownName : language.language;
}
