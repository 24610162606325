import React from "react";

import { IProps } from "./deferred-query-warning-multiple.types";

/**
 * Warning that an agent has multiple active chats when trying to sign out.
 * @param props Props containing how many active chats there are.
 * @returns     Warning message.
 */
const DeferredQueryWarningMultiple = ({ deferredQueryCount }: IProps) => (
    <p>
        You have {deferredQueryCount} deferred queries. If you do not complete them they will eventually be assigned to
        another agent.
    </p>
);

export default DeferredQueryWarningMultiple;
