import React from "react";
import "./blank-field.scss";

/**
 * Render a display for empty customer form fields.
 * @returns Span element.
 */
const BlankField = () => <div className="blank-field">Blank</div>;

export default BlankField;
