import { FunctionComponent, memo } from "react";
import { Button } from "@edgetier/components";
import { faStickyNote } from "@fortawesome/free-regular-svg-icons";
import { Form, Formik } from "formik";
import * as Yup from "yup";

import SubmitFormKeyboardShortcut from "components-for/shortcuts/submit-form-keyboard-shortcut";
import { KeyboardShortcutScope } from "services/keyboard-shortcut-service";
import useCreateNote from "components-for/notes/use-create-note";
import InputError from "constants/input-error";

import NoteField from "../note-field";

import { IProps } from "./note-form.types";

/**
 * Form to create a new note. Notes are attached to queries. Agents can add notes from the chat or email screen. Anybody
 * can add a note from the search screen.
 * @param props.interactionDetailId Optional identifier to tie the note to a specific call, chat, or email.
 * @param props.queryId             Query where the note will be appended.
 * @param props.note                Optional existing note if it is being edited. Otherwise a new one will be created.
 * @param props.onComplete          Method to call after the note has been submitted. Used to close a modal.
 * @returns                         Form with a single text area input.
 */
const NoteForm: FunctionComponent<IProps> = ({ interactionDetailId, queryId, note, onComplete }) => {
    const { mutate: createNote } = useCreateNote({ interactionDetailId, queryId, onComplete, note });

    return (
        <Formik
            initialValues={{ note: note?.note ?? "" }}
            onSubmit={({ note }) => createNote(note)}
            validationSchema={Yup.object({
                note: Yup.string().trim().required(InputError.Required),
            })}
        >
            <Form className="notes__create-note">
                <NoteField autoFocus />

                <SubmitFormKeyboardShortcut scope={KeyboardShortcutScope.Modal} setScopeWhileActive />
                <Button icon={faStickyNote} styleName="positive" type="submit">
                    Save note
                </Button>
            </Form>
        </Formik>
    );
};

export default memo(NoteForm);
