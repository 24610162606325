import { FunctionComponent, memo, useState } from "react";
import { IProps } from "./user-state-provider.types";
import UserStateContext from "components-for/users/user-state-service/user-state-context";
import UserStateService from "components-for/users/user-state-service";
import { defaultContext } from "components-for/users/user-state-service/user-state-context/user-state-context";
import { UserState } from "redux/application.types";
import "./user-state-provider.scss";

/**
 * A provider for the user state context.
 * @param children The children to render.
 */
const UserStateProvider: FunctionComponent<IProps> = ({ children }) => {
    const [userStateId, setUserStateIdState] = useState<number>(defaultContext.userStateId);
    const setUserStateId = (userStateId: UserState | number) => {
        setUserStateIdState(userStateId);
    };

    const [isUserStateLoading, setIsUserStateLoadingState] = useState<boolean>(defaultContext.isUserStateLoading);
    const setIsUserStateLoading = (isLoading: boolean) => {
        setIsUserStateLoadingState(isLoading);
    };

    return (
        <UserStateContext.Provider value={{ isUserStateLoading, setIsUserStateLoading, userStateId, setUserStateId }}>
            <UserStateService>{children}</UserStateService>
        </UserStateContext.Provider>
    );
};

export default memo(UserStateProvider);
