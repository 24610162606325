import { Fragment, FunctionComponent, memo } from "react";
import urlJoin from "url-join";
import { Button } from "@edgetier/components";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { Url } from "@edgetier/types";
import { useQueryClient } from "react-query";

import DeleteButton from "components/delete-button";
import { IQueryNotesQueryCache } from "hooks-for/query-history/use-query-notes/use-query-notes.types";

import { IProps } from "./query-history-note-delete-button.types";
import "./query-history-note-delete-button.scss";

/**
 * Button to delete a note. Users can only delete their own notes.
 * @param props.queryId Which query the note belongs. Used to update the query cache.
 * @param props.noteId  Note identifier.
 * @returns             Delete button.
 */
const QueryHistoryNoteDeleteButton: FunctionComponent<IProps> = ({ queryId, noteId }) => {
    const queryClient = useQueryClient();

    /**
     * Remove the note from any cache once it was deleted.
     */
    const onDelete = () => {
        // Remove note from query notes list.
        const queryNotesUrl = urlJoin(Url.Query, queryId.toString(), Url.Notes);

        queryClient.setQueryData<IQueryNotesQueryCache | undefined>(queryNotesUrl, (notes) => {
            if (typeof notes !== "undefined") {
                const { [noteId]: deletedNote, ...others } = notes;
                return others;
            }
        });

        // Individual notes may also be cached.
        const noteUrl = urlJoin(Url.Notes, noteId.toString());
        queryClient.setQueryData(noteUrl, undefined);
    };

    return (
        <DeleteButton
            buttonLabel="Delete"
            className="button--no-modal"
            onDelete={onDelete}
            url={urlJoin(Url.Notes, noteId.toString())}
        >
            {(deleteNote) => (
                <Fragment>
                    <h2>Delete Note</h2>
                    <p>Are you sure you want to delete this note?</p>
                    <Button icon={faTrash} onClick={() => deleteNote()}>
                        Delete
                    </Button>
                </Fragment>
            )}
        </DeleteButton>
    );
};

export default memo(QueryHistoryNoteDeleteButton);
