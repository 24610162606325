enum ChatType {
    ActivateChat = "chat/ACTIVATE_CHAT",
    EnterChat = "chat/ENTER_CHAT",
    RemoveChat = "chat/REMOVE_CHAT",
    RemoveInvite = "chat/REMOVE_INVITE",
    RemoveProposedActivity = "chat/REMOVE_PROPOSED_ACTIVITY",
    ResumeChats = "chat/RESUME_CHATS",
    SetHasBeenForceDisabled = "chat/SET_HAS_BEEN_FORCE_DISABLED",
    SetTransferTimeoutId = "chat/SET_TRANSFER_TIMEOUT_ID",
    StopChat = "chat/STOP_CHAT",
    StoreAgentActions = "chat/STORE_AGENT_ACTIONS",
    StoreCustomerUrl = "chat/STORE_CUSTOMER_URL",
    StoreInvite = "chat/STORE_INVITE",
    StoreMessage = "chat/STORE_MESSAGE",
    StoreProposedActivities = "chat/STORE_PROPOSED_ACTIVITIES",
    StoreWrapUpDraft = "chat/STORE_WRAP_UP_DRAFT",
    ToggleChat = "chat/TOGGLE_CHAT",
    ToggleChatScreenOpen = "chat/TOGGLE_CHAT_SCREEN_OPEN",
    ToggleConnected = "chat/TOGGLE_CONNECTED",
    ToggleProposedActivitiesOpen = "chat/TOGGLE_PROPOSED_ACTIVITIES_OPEN",
    ToggleTransferring = "chat/TOGGLE_TRANSFERRING",
    UpdateChat = "chat/UPDATE_CHAT",
    UpdateChatEmail = "chat/UPDATE_CHAT_EMAIL",
    UpdateMessage = "chat/UPDATE_MESSAGE",
    UpdateProposedActivity = "chat/UPDATE_PROPOSED_ACTIVITY",
    UpdateSettings = "chat/UPDATE_SETTINGS",
    UpdateTyping = "chat/TYPING",
}

export default ChatType;
