import { useEffect, useState, useRef } from "react";

/**
 * React hook to wait a given number of milliseconds
 * @param    delay    optional number of milliseconds, defaults to 800
 * @returns           true after @delay has passed
 */
const useDelay = function useDelay(delay: number = 800) {
    const timeout = useRef<number>();
    const [isReady, setIsReady] = useState<boolean>(delay === 0);

    useEffect(() => {
        async function update() {
            await new Promise((resolve) => {
                timeout.current = window.setTimeout(resolve, delay);
            });
            setIsReady(true);
        }
        if (delay > 0) {
            update();
        }

        return () => {
            window.clearTimeout(timeout.current);
        };
    }, [delay]);

    return isReady;
};

export default useDelay;
