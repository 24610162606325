import { Url } from "@edgetier/types";
import { AxiosRequestConfig } from "axios";
import urljoin from "url-join";

import { IAction, ISettings, ISendEmailAction } from "redux/application.types";
import { IBlankEmail } from "redux/modules/email/email.types";
import { ISkill } from "redux/modules/setup/setup.types";

import axios from "./axios";
import formatProposedActions from "./format-proposed-actions";

interface IAutomatableEmailPostData {
    readonly bookingId?: string | null;
    readonly chatId?: number;
    readonly emailId?: number;
    readonly interactionDetailId?: number | null;
    readonly languageId: number;
    readonly outboundEmailActionId?: number;
    readonly queryId?: number;
    readonly skillId?: number | null;
}

/**
 * Request the skill from the backend if there is a skillId present.
 * @param skillId The ID of the skill, if it is present.
 * @returns The skill if there is a skill ID, null otherwise.
 */
const getSkill = async (skillId: number | null | undefined) => {
    if (typeof skillId === "number") {
        const { data } = await axios.get<ISkill>(urljoin(Url.Skills, String(skillId)));
        return data;
    }
    return null;
};

/**
 * Make the relevant requests needed to create a blank email and return email object with default properties.
 * @param postData      Data to be sent in the body of the POST request to the /automatable-email route.
 * @param configuration Axios configuration argument.
 */
const createBlankEmail = async (
    postData: IAutomatableEmailPostData,
    configuration?: AxiosRequestConfig
): Promise<IBlankEmail> => {
    const { languageId, bookingId, skillId } = postData;

    const [response, { data: settings }, skill] = await Promise.all([
        axios.post<{ actions: IAction[]; templateActions: IAction[]; wrapUpFormId: number }>(
            Url.AutomatableEmail,
            postData,
            configuration
        ),
        axios.get<ISettings>(Url.Settings),
        getSkill(skillId),
    ]);
    const { actions, templateActions, wrapUpFormId } = response.data;
    const queryId = (actions[0] as ISendEmailAction).data.queryId;
    return {
        actions: formatProposedActions(actions),
        bookingId: typeof bookingId === "undefined" ? null : bookingId,
        createdAt: new Date(),
        description: "New Email Details",
        emailId: null,
        handlingTimes: [],
        isExpired: false,
        isExpiring: false,
        isExpiringSoon: false,
        languageId,
        queryId,
        settings,
        skills: skill !== null ? [skill] : [],
        templateActions: formatProposedActions(templateActions),
        translatedToLanguageId: null,
        wrapUpFormDraft: null,
        wrapUpFormId,
    };
};

export default createBlankEmail;
