import { IChatState } from "redux/modules/chat/chat.types";

import actions from "./../chat-actions";

/**
 * Update the typing status of the customer.
 * @param state  Application state before changing the typing status.
 * @param action Action with a payload indicating if the customer is currently typing or not.
 * @returns      State with the new typing state.
 */
export default function (state: IChatState, action: ReturnType<typeof actions.updateTyping>): IChatState {
    const chat = state.chats[action.payload.chatToken];
    if (typeof chat === "undefined") {
        return state;
    }

    return {
        ...state,
        chats: {
            ...state.chats,
            [action.payload.chatToken]: {
                ...chat,
                isTyping: action.payload.isTyping,
            },
        },
    };
}
