import { faClock } from "@fortawesome/free-regular-svg-icons/faClock";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

import View from "constants/view";

import { IProps } from "./deferred-query-warning.types";
import DeferredQueryWarningSingular from "./deferred-query-warning-singular";
import DeferredQueryWarningMultiple from "./deferred-query-warning-multiple";

/**
 * Display a warning if an agent has deferred queries when trying to sign out.
 * @param props Props containing an array of deferred queries and a click handler.
 * @returns     An empty container if there are no deferred queries or a warning message otherwise.
 */
const DeferredQueryWarning = ({ deferredQueryCount, onClick }: IProps) => (
    <li>
        {deferredQueryCount === 1 ? (
            <DeferredQueryWarningSingular />
        ) : (
            <DeferredQueryWarningMultiple deferredQueryCount={deferredQueryCount} />
        )}
        <Link className="button button--outline" to={View.DeferredQueries} onClick={onClick}>
            <FontAwesomeIcon icon={faClock} /> View queries
        </Link>
    </li>
);

export default DeferredQueryWarning;
