import classNames from "classnames";
import GridContext from "../grid-context";
import React, { CSSProperties, useCallback, useContext, useLayoutEffect, useRef, useState } from "react";
import ResizeObserver from "resize-observer-polyfill";
import TableMenuSortOptions from "../table-menu-sort-options";
import TableMenuSection from "../table-menu-section";
import { Arrow, useLayer } from "react-laag";
import { faAngleDown, faSortAmountDown, faSortAmountUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IProps } from "./heading.types";
import "./heading.scss";

/**
 * Heading for a table.
 */
export const Heading = <T extends {}>({
    children,
    className,
    isFiltered,
    reverseSortDirection = false,
    menu,
    ...otherProps
}: IProps<T>) => {
    const element = useRef<HTMLTableCellElement>(null);
    const { clearSort, constantWidth, sort, hasSetWidth, rows, setHasSetWidth, ...context } = useContext(GridContext);
    const [style, setStyle] = useState<CSSProperties>({});

    // A menu will be available if the props contains one or a sorting property.
    const hasMenu = typeof menu !== "undefined" || typeof otherProps.sortProperty !== "undefined";

    // Props can override the sorting state or it will be handled internally.
    const isSorted = (context.sortProperty === otherProps.sortProperty || otherProps.isSorted) ?? false;
    const isSortedAscending =
        isSorted &&
        (((context.isSortedAscending && typeof otherProps.sortProperty !== "undefined") ||
            otherProps.isSortedAscending) ??
            false);

    const isSortedDescending = isSorted && !isSortedAscending;

    const [isOpen, setIsOpen] = useState(false);

    /**
     * Open or close the menu.
     */
    const toggleMenu = () => {
        if (hasMenu) {
            return setIsOpen((current) => !current);
        }
    };

    const closeMenu = () => {
        return setIsOpen(false);
    };

    const { triggerProps, layerProps, arrowProps, renderLayer } = useLayer({
        auto: true,
        isOpen: isOpen,
        onParentClose: closeMenu,
        onOutsideClick: closeMenu,
        placement: "bottom-end",
        ResizeObserver,
        triggerOffset: 12,
    });

    // There's an option for constant width columns. This measures them and sets the column width.
    useLayoutEffect(() => {
        if (constantWidth && !hasSetWidth && element.current !== null && Array.isArray(rows)) {
            setStyle({ width: `${element.current.offsetWidth}px` });
            setHasSetWidth(true);
        }
    }, [constantWidth, hasSetWidth, setHasSetWidth, setStyle, rows]);

    /**
     * Handle sorting.
     */
    const onSort = useCallback(
        (isSortedAscending: boolean) => () => {
            if (typeof otherProps.sortProperty !== "undefined") {
                sort(otherProps.sortProperty, isSortedAscending);
                closeMenu();
            }
        },
        [otherProps.sortProperty, sort]
    );

    return (
        <>
            {isOpen &&
                hasMenu &&
                renderLayer(
                    <div className="grid__menu" role="menu" {...layerProps}>
                        <Arrow {...arrowProps} />
                        {typeof otherProps.sortProperty !== "undefined" && (
                            <TableMenuSection>
                                <TableMenuSortOptions
                                    onClearSort={clearSort}
                                    isSorted={isSorted}
                                    isSortedAscending={reverseSortDirection ? !isSortedAscending : isSortedAscending}
                                    onSortAscending={onSort(reverseSortDirection ? false : true)}
                                    onSortDescending={onSort(reverseSortDirection ? true : false)}
                                />
                            </TableMenuSection>
                        )}
                        {typeof menu === "function" ? menu(closeMenu) : menu}
                    </div>
                )}
            <th
                className={classNames("grid__heading", {
                    "grid__heading--has-menu": hasMenu,
                    "grid__heading--is-filtered": isSorted || isFiltered,
                    [className ?? ""]: typeof className !== "undefined",
                })}
                ref={element}
                onClick={toggleMenu}
                style={style}
            >
                <div {...triggerProps}>
                    {children}
                    {isSortedAscending && <FontAwesomeIcon fixedWidth={true} icon={faSortAmountUp} />}
                    {isSortedDescending && <FontAwesomeIcon fixedWidth={true} icon={faSortAmountDown} />}
                    {hasMenu && <FontAwesomeIcon icon={faAngleDown} />}
                </div>
            </th>
        </>
    );
};

export default Heading;
