import { IProps } from "./chat-variable-field-values.types";
import { VariableType } from "@edgetier/types";
import { DetailsFieldValue, SplitTimestamp } from "@edgetier/client-components";
import { parseISO } from "date-fns";
import { unreachableCaseError } from "@edgetier/utilities";
import "./chat-variable-field-values.scss";

/**
 * Using the variable type return a parsed value of interaction detail value
 * @param props.value
 * @param props.variableType
 */
function ChatVariableFieldValues({ value, variableType }: IProps) {
    switch (variableType) {
        case VariableType.Boolean:
            return (
                <DetailsFieldValue copyText={value === "true" ? "Yes" : "No"}>
                    {value === "true" ? "Yes" : "No"}
                </DetailsFieldValue>
            );
        case VariableType.String:
        case VariableType.Numeric:
        case VariableType.Link:
        case VariableType.Array:
            return <DetailsFieldValue copyText={value}>{value}</DetailsFieldValue>;
        case VariableType.Date:
        case VariableType.Time:
            return (
                <DetailsFieldValue copyText={value}>
                    <SplitTimestamp date={parseISO(value)} />
                </DetailsFieldValue>
            );
        default:
            unreachableCaseError(variableType);
            return <>{value}</>;
    }
}

export default ChatVariableFieldValues;
