import AutomatedIcon from "./automated-icon";
import classNames from "classnames";
import DynamicForm from "@edgetier/dynamic-form";
import ErrorIcon from "./error-icon";
import MessageContent from "./message-content/message-content";
import TypingIndicator from "./typing-indicator";
import { FormFieldType, MessageType } from "@edgetier/types";
import { IProps } from "./chat-message.types";
import { ToggleTranslation } from "shared/toggle-translation";
import "./chat-message.scss";
import axios from "utilities/axios";

/**
 * Display a chat message. This could be from the agent, customer, or a system message generated from the backend. If
 * the other person is typing, a blank message is displayed with animated dots.
 *
 * All URLs are detected and turned into clickable links. Depending on whether this is an agent or customer, the links
 * will open in a new tab (agents) or the parent window (customers).
 *
 * @param props                         Props containing message to display.
 * @param props.children                A render prop function that returns a delete attachment button for the attachment provided.
 * @param props.getAttachment           Callback to open attachments.
 * @param props.highlightSystemMessages Show an icon for automated messages. Only agents should see this.
 * @param props.invalidError            Form error for invalid fields.
 * @param props.isLastOfType            If this is the last message from a customer/agent.
 * @param props.isPlainMessage          Boolean indicating if the message is just text.
 * @param props.isTyping                Whether or not the other person in the chat is typing.
 * @param props.languages               List of languages.
 * @param props.linkTarget              Where to open links (could be the parent or new tab).
 * @param props.message                 The chat message to display.
 * @param props.onBlocked               Callback when attachments are blocked.
 * @param props.onServerError           Callback to show server errors when opening attachments.
 * @param props.onSubmit                Method to call when a chat message form is submitted.
 * @param props.preferredLanguageId     The perferred language of the agent.
 * @param props.renderFormsInMessages   Boolean indicating if the chat message should render forms.
 * @param props.requiredError           Form error for required fields.
 * @param props.showTranslations        Whether to show button to translate the message or not.
 * @param props.submitLabel             Text for chat message forms.
 * @param props.typingIndicatorLabel    Label to read to screen readers when the other person is typing.
 * @param props.userRecipientTypeId     Who is receiving the message (i.e. an agent or customer). This will determine if
 *                                      the chat message is displayed on the right or left.
 * @returns                             Message.
 */
const ChatMessage = ({
    children,
    getAttachment,
    highlightSystemMessages,
    invalidError,
    isPlainMessage,
    isTyping,
    languages,
    linkTarget,
    message,
    onBlocked,
    onServerError,
    onSubmit,
    menu,
    preferredLanguageId,
    requiredError,
    secondaryLanguageId,
    showTranslations,
    shouldRenderForm = true,
    submitLabel,
    typingIndicatorLabel,
    userRecipientTypeId,
}: IProps) => (
    <div
        className={classNames("chat-messages__message", {
            "chat-messages__message--out":
                message.messageRecipientTypeId !== userRecipientTypeId && message.messageTypeId !== MessageType.Status,
            "chat-messages__message--system":
                message.messageTypeId === MessageType.Automated && highlightSystemMessages,
            "chat-messages__message--in":
                message.messageRecipientTypeId === userRecipientTypeId && message.messageTypeId !== MessageType.Status,
            "chat-messages__message--status": message.messageTypeId === MessageType.Status,
            "chat-messages__message--error": typeof message.error === "string",
            "chat-messages__message--typing": isTyping,
            "chat-messages__message--has-form": shouldRenderForm && Array.isArray(message.form?.formComponents),
            "chat-messages__message--single-button-field":
                shouldRenderForm &&
                Array.isArray(message.form?.formComponents) &&
                message.form?.formComponents.length === 1 &&
                message.form.formComponents[0].formFieldTypeId === FormFieldType.Buttons,
        })}
    >
        {highlightSystemMessages && message.messageTypeId === MessageType.Automated && <AutomatedIcon />}

        {typeof message.error === "string" && <ErrorIcon />}

        <div className="chat-messages__message__container">
            {isTyping ? (
                <TypingIndicator label={typingIndicatorLabel} />
            ) : preferredLanguageId === secondaryLanguageId || showTranslations === false ? (
                <MessageContent
                    addAccessibilityRole={message.messageRecipientTypeId === userRecipientTypeId}
                    getAttachment={getAttachment}
                    linkTarget={linkTarget}
                    isPlainMessage={isPlainMessage}
                    menu={menu}
                    message={message}
                    preferredLanguageId={preferredLanguageId}
                    secondaryLanguageId={secondaryLanguageId}
                    showTranslations={showTranslations}
                    onBlocked={onBlocked}
                    onServerError={onServerError}
                >
                    {children}
                </MessageContent>
            ) : (
                <ToggleTranslation
                    key={Object.keys(message.translations).join("-")}
                    languages={languages}
                    preferredLanguageId={preferredLanguageId}
                    translations={[message.translations]}
                >
                    <MessageContent
                        addAccessibilityRole={message.messageRecipientTypeId === userRecipientTypeId}
                        getAttachment={getAttachment}
                        linkTarget={linkTarget}
                        isPlainMessage={isPlainMessage}
                        menu={menu}
                        message={message}
                        preferredLanguageId={preferredLanguageId}
                        secondaryLanguageId={secondaryLanguageId}
                        showTranslations={showTranslations}
                        onBlocked={onBlocked}
                        onServerError={onServerError}
                    >
                        {children}
                    </MessageContent>
                </ToggleTranslation>
            )}
        </div>

        {message.form && shouldRenderForm && (
            <DynamicForm
                axiosInstance={axios}
                formComponents={message.form.formComponents}
                formId={message.form.formId}
                simplifySingleFields={true}
                invalidError={invalidError}
                onSubmit={onSubmit}
                languageId={preferredLanguageId}
                name={`message-form-${message.form.formId}-${message.timestamp}`}
                requiredError={requiredError}
                submitLabel={submitLabel}
            />
        )}
    </div>
);

export default ChatMessage;
