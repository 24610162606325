import React, { FunctionComponent, memo } from "react";

import { IProps } from "./select-multi-value.types";
import "./select-multi-value.scss";

/**
 * Container for when a select component allows multiple items.
 * @param props.children Select contents.
 * @returns              Multi-value container.
 */
const SelectMultiValue: FunctionComponent<IProps> = ({ children }) => {
    return <div className="react-select__custom-multi-value">{children}</div>;
};

export default memo(SelectMultiValue);
