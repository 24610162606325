import { IActionsUnion } from "redux/types";
import { IPauseState } from "redux/modules/pause/pause.types";

import actions from "./pause-actions";
import PauseType from "./pause-type";

type IPauseAction = IActionsUnion<typeof actions>;

export const initialState = { paused: false };

export default function (state: IPauseState = initialState, action: IPauseAction): IPauseState {
    switch (action.type) {
        case PauseType.Resume:
            return { ...state, paused: false };
        case PauseType.Pause:
            return { ...state, paused: true };
        default:
            return state;
    }
}
