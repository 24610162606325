import React, { Children, FunctionComponent, isValidElement, memo } from "react";
import { components } from "react-select";

import SelectMultiValue from "../select-multi-value";

import SelectValueContainerSummary from "./select-value-container-summary";
import { IProps } from "./select-value-container.types";
import "./select-value-container.scss";

/**
 * Custom display of selected items. By default, React Select does this horribly and the input just keeps growing
 * forever. This component changes that behaviour so only the first selected item is shown in full. After that, further
 * items selected will show something like "2 items selected".
 * @param props.children    Default content of the container.
 * @param props.description Description of the items being displayed.
 * @returns                 Custom selected items display.
 */
const SelectValueContainer: FunctionComponent<IProps> = ({
    children,
    description = "options",
    ...valueContainerProps
}) => {
    const { inputValue, menuIsOpen } = valueContainerProps.selectProps;
    const flattened = Children.toArray(children).flatMap((child) => child);
    const valid = flattened.filter(isValidElement);
    const values = valid.filter((child) => child.type === SelectMultiValue && !menuIsOpen);
    const others = valid.filter((child) => child.type !== SelectMultiValue);
    const hasPlaceholder = valid.some((child) => child.type === components.Placeholder);

    return (
        <components.ValueContainer {...valueContainerProps}>
            {values.length <= 1 && values}
            {values.length > 1 && <SelectValueContainerSummary description={description} valuesCount={values.length} />}
            {others}
            {menuIsOpen &&
                !hasPlaceholder &&
                !(typeof inputValue === "string" && inputValue.length > 0) &&
                values.length === 0 && (
                    <span className="react-select__placeholder">Select more {description}&hellip;</span>
                )}
        </components.ValueContainer>
    );
};

export default memo(SelectValueContainer);
