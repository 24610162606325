import { ChatEventType, IChatEvent } from "@edgetier/types";

/**
 * Filter chat events to show all of them or just chat messages.
 * @param sortedEvents  Chat events.
 * @param showAllEvents Whether all events or just chat messages should be displayed.
 * @returns             Array of chat events.
 */
export const filterEvents = (sortedEvents: IChatEvent[], showAllEvents: boolean) => {
    return sortedEvents.filter((event) => showAllEvents || event.chatEventTypeId === ChatEventType.Message);
};
