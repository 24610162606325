import urlJoin from "url-join";
import { SOCKET_NAMESPACE } from "@edgetier/types";

/**
 * Generate a URL to connect to the Socket backend.
 * @param baseUrl Base URL that will default to the current URL.
 * @returns       Socket connection URL.
 */
export function getSocketUrl(baseUrl: string = window.location.protocol + "//" + window.location.host): string {
    return urlJoin(baseUrl, SOCKET_NAMESPACE);
}
