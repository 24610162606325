import React from "react";
import { DetailsFieldTitle } from "@edgetier/client-components";

import TextFieldValue from "../text-field-value";

import { IProps } from "./form-submission-text-field.types";
import "./form-submission-text-field.scss";

/**
 * Display what a user entered in a text, email or similar field on a form.
 * @param props.field Text field submission.
 * @returns           Title and value of the field.
 */
const FormSubmissionTextField = ({ field }: IProps) => (
    <tr>
        <td>
            <DetailsFieldTitle>{field.formFieldTranslation}</DetailsFieldTitle>
        </td>
        <td>
            <TextFieldValue>{field.value}</TextFieldValue>
        </td>
    </tr>
);

export default FormSubmissionTextField;
