import { IFormComponent, ISelectFormComponent } from "@edgetier/types";
import { keyById } from "@edgetier/utilities";

import { IFormComponentTranslated } from "./dynamic-form.types";
import SelectFormField from "./form-fields/select-form-field";

/**
 * Take a configuration and process through decorateConfiguration if needed
 */
export const normaliseConfiguration = (
    configuration: IFormComponent["configuration"],
    formComponents: IFormComponent[]
): IFormComponent["configuration"] => {
    if (Object.prototype.hasOwnProperty.call(configuration, "parentFormFieldId")) {
        return SelectFormField.decorateConfiguration(
            configuration as ISelectFormComponent["configuration"],
            formComponents
        );
    }
    return configuration;
};

/**
 * Convert form fields into a format we can work with
 */
export const normaliseFields = (
    formComponents: IFormComponent[]
): {
    fieldIds: number[];
    childFieldsByParentId: { [fieldId: string]: number[] };
    fields: { [index: string]: IFormComponentTranslated };
} => {
    const fields: { [index: string]: IFormComponentTranslated } = {};
    const childFieldsByParentId: { [fieldId: string]: number[] } = {};
    const fieldIds = formComponents
        .map((field) => {
            const { configuration, formFieldTranslations, ...rest } = field;
            const normalisedConfiguration = normaliseConfiguration(configuration, formComponents);

            // If a field has a 'parent' we will track all that parent's children in an array
            if ("parentFormFieldId" in normalisedConfiguration && normalisedConfiguration.parentFormFieldId !== null) {
                const parentId = String(normalisedConfiguration.parentFormFieldId);
                if (!childFieldsByParentId[parentId]) {
                    childFieldsByParentId[parentId] = [field.formFieldId];
                } else {
                    childFieldsByParentId[parentId].push(field.formFieldId);
                }
            }

            fields[String(field.formFieldId)] = {
                ...rest,
                configuration: normalisedConfiguration,
                formFieldTranslations,
                translationLookup: keyById(formFieldTranslations, (item) => item.languageId),
            } as IFormComponentTranslated;

            return field.formFieldId;
        })
        .sort((one, two) => fields[one].displayOrder - fields[two].displayOrder)
        .filter((fieldId: number) => {
            const fieldConfiguration = fields[fieldId].configuration;
            return !("parentFormFieldId" in fieldConfiguration && fieldConfiguration.parentFormFieldId !== null);
        });

    return { fields, fieldIds, childFieldsByParentId };
};

/**
 * Create a message value from submitted form translation labels and values
 * @param labels            Translation labels
 * @param values            Form values
 * @returns                 Array of messages as label: value or value
 */
export const pairLabelValue = (labels: string[], values: string[]) => {
    if (values.length > 1) {
        return values.map((value, index) => {
            if (typeof labels[index] === "string" && labels[index] !== "") {
                return `${labels[index]}: ${value}`;
            }
            return value;
        });
    }

    return values;
};

/**
 * Find translation labels
 * @param formComponents    Any form components sent
 * @param languageId        Current language
 * @returns                 List of labels for current language
 */
export const getTranslationLabels = (formComponents: IFormComponent[] | undefined, languageId: number) => {
    if (typeof formComponents !== "undefined") {
        // Obtain all labels from form components
        return formComponents.map((formComponent) => {
            const labelTranslation = formComponent.formFieldTranslations.find(
                (fieldTranslation) => fieldTranslation.languageId === languageId
            );

            return typeof labelTranslation !== "undefined" ? labelTranslation.formFieldTranslation : "";
        });
    }

    return [];
};
