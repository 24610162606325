import { Url } from "@edgetier/types";
import { AxiosError, AxiosRequestConfig } from "axios";
import { useQuery, UseQueryOptions } from "react-query";
import { useDispatch } from "react-redux";

import {
    IBackendInteractionSearchItem,
    IInteractionSearchItem,
} from "components/interaction-search/interaction-search.types";
import InteractionType from "constants/interaction-type";
import { toastOperations } from "redux/modules/toast";
import { getAllPaginatedItems } from "utilities/get-all-paginated-items";
import { formatInteractions } from "utilities/request-interactions";

/**
 * Search for all interactions in the given query.
 * @param url           Interactions search end point.
 * @param configuration Axios configuration that will contain search parameters.
 * @returns             Promise of interactions.
 */
export const searchQueryInteractions = async (url: string, configuration: AxiosRequestConfig) => {
    const response = await getAllPaginatedItems<IBackendInteractionSearchItem>(url, configuration);
    return formatInteractions(response);
};

/**
 * A reusable query for requesting interactions for a specific query ID.
 * @param queryId       The query ID of the interactions being requested.
 * @param configuration Optional configuration object for the query.
 */
const useQueryInteractions = (
    queryId: number,
    configuration: UseQueryOptions<IInteractionSearchItem[], AxiosError> = {}
) => {
    const dispatch = useDispatch();

    /**
     * Display errors in a toast.
     * @param serverError Backend error.
     */
    const onError = (serverError: AxiosError) => {
        dispatch(toastOperations.showServerErrorToast("Interaction Search Failed", serverError));
    };

    // Use all of the interaction type IDs in the InteractionType enum.
    const interactionTypeId = Object.values(InteractionType).filter((value) => typeof value === "number");
    const params = { queryId, interactionTypeId };
    return useQuery<IInteractionSearchItem[], AxiosError>(
        [Url.Interactions, params],
        () => searchQueryInteractions(Url.Interactions, { params }),
        { onError, ...configuration }
    );
};

export default useQueryInteractions;
