import { Fragment, FunctionComponent, memo } from "react";
import { Button } from "@edgetier/components";
import { faEnvelopeOpen } from "@fortawesome/free-solid-svg-icons";

import { Modal, ModalContainer } from "shared/modal";

import EmailHtml from "./email-html";
import { emailContent } from "./email-html-button.utilites";
import { IProps } from "./email-html-button.types";

import "./email-html-button.scss";

/**
 * A button that when clicked will open a modal containing the HTML content of an email.
 * @param props.bodyHtml HTML content of an email possibly in multiple languages.
 * @param props.bodyText Plain content of an email
 * @param props.subject  Subject line of email also maybe in multiple languages.
 * @returns              Button.
 */
const EmailHtmlButton: FunctionComponent<IProps> = ({ bodyHtml, bodyText, subject }) => {
    const emailBodyContent = emailContent(bodyHtml, bodyText);
    return (
        <ModalContainer>
            {({ showModal }) => (
                <Fragment>
                    <Modal size="wide">
                        <EmailHtml bodyHtml={emailBodyContent} subject={subject} />
                    </Modal>
                    <Button className="button--no-modal" icon={faEnvelopeOpen} onClick={showModal}>
                        Original Email
                    </Button>
                </Fragment>
            )}
        </ModalContainer>
    );
};

export default memo(EmailHtmlButton);
