import { formatEnumName } from "./format-enum-name";

/**
 * See if a value is a number including numbers in strings.
 * @param value Value (probably a string).
 * @returns     True if the value is a number.
 */
const isNumeric = (value: any) => {
    return !isNaN(parseFloat(value)) && isFinite(value);
};

/**
 * Take enum values and convert them into an array containing labels and values.
 * @param options The options that need formatting.
 */
export const formatEnumOptions = (
    options: { [index: string]: number } | { [index: number]: string },
    excludedOptions: (string | number)[] = []
) => {
    const formattedOptions = Object.entries(options)
        .filter(([key, value]) => (typeof value === "number" || !isNumeric(key)) && !excludedOptions.includes(value))
        .filter(([_, value], index, items) => items.map((item) => item[1]).lastIndexOf(value) === index)
        .sort(([labelOne], [labelTwo]) => labelOne.toLowerCase().localeCompare(labelTwo.toLowerCase()))
        .map(([label, value]) => ({ label: formatEnumName(label), value }));
    return formattedOptions;
};
