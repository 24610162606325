import React, { FunctionComponent, memo } from "react";

import { IProps } from "./email-list.types";

/**
 * Display a comma-separated list of emails.
 * @param props.emails An array of emails.
 * @param props.title  The title of the list e.g "to", "cc" etc.
 */
const EmailList: FunctionComponent<IProps> = ({ emails, title }) => (
    <div className="email-participants__recipients__list">
        <span className="email-participants__type">{title}:</span>
        <span className="email-participants__emails">{Array.isArray(emails) ? emails.join(", ") : emails}</span>
    </div>
);

export default memo(EmailList);
