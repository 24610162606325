import { FunctionComponent, memo } from "react";

import { ToggleTranslationButton, ToggleTranslationSection } from "shared/toggle-translation";

import { IProps } from "./email-html.types";
import { prepareHtml } from "./email-html.utilities";
import "./email-html.scss";

/**
 * Render an email from its raw HTML. This may be useful if the plain text version of an email is missing something. The
 * content is displayed in an iFrame so that all styling is reset.
 * @param props.bodyHtml HTML content of an email possibly in multiple languages.
 * @param props.subject  Subject line of email also maybe in multiple languages.
 * @returns              Subject and body of an email.
 */
const EmailHtml: FunctionComponent<IProps> = ({ bodyHtml, subject }) => {
    return (
        <div className="email-html">
            <ToggleTranslationSection translations={subject}>
                {({ translation }) => typeof translation === "string" && <h2>{translation}</h2>}
            </ToggleTranslationSection>

            <ToggleTranslationSection translations={bodyHtml}>
                {({ translation }) =>
                    typeof translation === "string" && <iframe srcDoc={prepareHtml(translation)} title="Email" />
                }
            </ToggleTranslationSection>

            {Object.keys(bodyHtml).length > 1 && <ToggleTranslationButton />}
        </div>
    );
};

export default memo(EmailHtml);
