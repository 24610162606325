import { Field, FieldProps } from "formik";
import { getIn } from "formik";

import ToggleTranslationContext from "../toggle-translation-context";

import InnerField from "./inner-field";
import { IProps } from "./toggle-translation-field.types";

/**
 * Wrapper around translation field component to provided Formik form values.
 * @param props Field props.
 * @returns     Translateable field.
 */
const ToggleTranslationField = ({ canTranslate, ...otherProps }: IProps) => (
    <ToggleTranslationContext.Consumer>
        {({ isTranslated, showServerError }) => (
            <Field>
                {({ form }: FieldProps) => (
                    <InnerField
                        {...otherProps}
                        isTranslated={canTranslate && isTranslated}
                        setFieldValue={form.setFieldValue}
                        showServerError={showServerError}
                        translations={getIn(form.values, otherProps.name)}
                    />
                )}
            </Field>
        )}
    </ToggleTranslationContext.Consumer>
);

export default ToggleTranslationField;
