import Modal, { ModalContent, ModalHeader, useModal } from "@edgetier/modal";
import RedactForm from "./redact-form";
import { EllipsisMenuContext, EllipsisMenuItem } from "@edgetier/client-components";
import { faEraser } from "@fortawesome/pro-solid-svg-icons";
import { FunctionComponent, memo, useContext } from "react";
import { IProps } from "./redact-action.types";
import { useQueryClient } from "react-query";
import "./redact-action.scss";

/**
 * Menu with button to redact content.
 * @param props.interactionUrl URL of the chat or email so the cache can be cleared.
 * @param props.size           Modal size.
 * @returns                    Ellipsis menu.
 */
const RedactAction: FunctionComponent<IProps> = ({ interactionUrl, size, ...redactFormProps }) => {
    const queryClient = useQueryClient();
    const { lockOpen, unlock } = useContext(EllipsisMenuContext);
    const { closeModal, modalProps, openModal } = useModal({ onCloseModal: unlock });

    /**
     * Clear the interaction from the cache and close the modal.
     */
    const onUpdate = () => {
        queryClient.resetQueries(interactionUrl, { exact: true });
        closeModal();
    };

    return (
        <>
            <Modal size={size} {...modalProps}>
                <ModalHeader>
                    <h2>Redact Content</h2>
                </ModalHeader>
                <ModalContent>
                    <RedactForm {...redactFormProps} onCancel={closeModal} onUpdate={onUpdate} />
                </ModalContent>
            </Modal>
            <EllipsisMenuItem
                closeAfterClick={false}
                icon={faEraser}
                onClick={() => {
                    lockOpen();
                    openModal();
                }}
            >
                Redact Content
            </EllipsisMenuItem>
        </>
    );
};

export default memo(RedactAction);
