import React, { FunctionComponent, memo } from "react";

import { IProps } from "./modal-footer.types";
import "./modal-footer.scss";

/**
 * The footer of the modal.
 * @param props.children Components to be placed in the footer of the modal.
 */
const ModalFooter: FunctionComponent<IProps> = ({ children }) => <div className="modal-footer">{children}</div>;

export default memo(ModalFooter);
