import React from "react";
import { connect } from "react-redux";

import { IApplicationState } from "redux/types";
import { Modal, ModalContainer } from "shared/modal";
import { modalOperations } from "redux/modules/modal";

import { IProps, IStateProps } from "./modal.types";

/**
 * Modal that can be displayed from a Redux action.
 */
export class StateModal extends React.PureComponent<IProps> {
    /**
     * Display a modal with a title and one or more paragraphs.
     */
    render(): React.ReactNode {
        const { canCancel, message, size, title } = this.props;
        return (
            message !== null &&
            title !== null && (
                <ModalContainer canClose={canCancel} isOpen={true} onHide={this.props.hideModal}>
                    {() => (
                        <Modal size={size}>
                            <React.Fragment>
                                <h2>{title}</h2>
                                {Array.isArray(message) ? (
                                    message.map((paragraph, index) => <p key={index}>{paragraph}</p>)
                                ) : typeof message === "string" ? (
                                    <p>{message}</p>
                                ) : (
                                    <React.Fragment>{message}</React.Fragment>
                                )}
                            </React.Fragment>
                        </Modal>
                    )}
                </ModalContainer>
            )
        );
    }
}

/**
 * Get the modal state from the store.
 * @param state Application state.
 * @returns     The modal state.
 */
export function mapStateToProps({ modal }: IApplicationState): IStateProps {
    return modal;
}

export default connect(mapStateToProps, modalOperations)(StateModal);
