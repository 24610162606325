import { Fragment, FunctionComponent, memo } from "react";
import { Field, FieldProps } from "formik";
import { FieldError } from "@edgetier/components";
import TextArea from "react-textarea-autosize";

import { IProps } from "./note-field.types";

/**
 * A textarea field for creating a note.
 * @param props.placeHolder An optional place holder for the field.
 */
const NoteField: FunctionComponent<IProps> = ({ autoFocus, placeHolder }) => (
    <div className="field">
        <label htmlFor="note">Note</label>
        <Field name="note">
            {({ field, form }: FieldProps) => (
                <Fragment>
                    <TextArea
                        autoComplete="off"
                        autoFocus={autoFocus}
                        id="note"
                        minRows={3}
                        maxRows={8}
                        name={field.name}
                        onChange={form.handleChange}
                        placeholder={placeHolder ?? "Add note\u2026"}
                        value={field.value}
                    />
                    <FieldError name={field.name} />
                </Fragment>
            )}
        </Field>
    </div>
);

export default memo(NoteField);
