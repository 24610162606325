import { IModalState } from "redux/modules/modal/modal.types";

import ModalType from "./modal-type";

const createAction = <T extends { type: ModalType }>(action: T) => action;

/**
 * Show the modal.
 * @param payload Title and content of the modal.
 * @returns       Show modal action.
 */
const showModal = (payload: IModalState) => createAction({ type: ModalType.Show, payload });

/**
 * Hide the modal.
 * @returns Hide modal action.
 */
const hideModal = () => createAction({ type: ModalType.Hide });

export default { showModal, hideModal };
