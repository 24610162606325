import { ServerErrorModal } from "shared/modal";
import ToggleTranslationContext from "../toggle-translation-context";
import ToggleTranslationProvider from "../toggle-translation-provider";
import { IProps } from "./toggle-translation.types";

/**
 * Wrapper for a toggle translation component. This allows users to switch between an original language and its
 * translation. It used for chat messages, emails bodies, email subject lines etc.
 * @param props Children components.
 * @returns     Wrapped children components.
 */
const ToggleTranslation = ({
    children,
    languageId,
    languageIds,
    languages,
    preferredLanguageId,
    translations,
}: IProps) => (
    <ServerErrorModal>
        {(showServerError) => (
            <ToggleTranslationProvider
                languages={languages}
                preferredLanguageId={preferredLanguageId}
                languageId={languageId}
                languageIds={
                    Array.isArray(languageIds)
                        ? languageIds
                        : Array.isArray(translations)
                        ? translations.flatMap(Object.keys).map((value) => parseInt(value, 10))
                        : []
                }
                showServerError={showServerError}
            >
                {typeof children !== "function" ? (
                    children
                ) : (
                    <ToggleTranslationContext.Consumer>
                        {(context) => (children as any)(context)}
                    </ToggleTranslationContext.Consumer>
                )}
            </ToggleTranslationProvider>
        )}
    </ServerErrorModal>
);

export default ToggleTranslation;
