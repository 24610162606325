import React, { useState, useEffect } from "react";
import urlJoin from "url-join";
import { Url } from "@edgetier/types";
import { SpinnerUntil } from "@edgetier/components";

import { IUser } from "redux/application.types";
import useSimpleQuery from "queries/use-simple-query";

import { IProps } from "./attachment-tooltip-content.types";

/**
 * Displays information about the attachment that has been deleted when the user hovers over the deleted attachment.
 * @param props.attachment The attachment which the tooltip references.
 */
const AttachmentTooltipContent = ({ attachment }: IProps) => {
    const [userName, setUserName] = useState<string>();

    const { data: user } = useSimpleQuery<IUser>(urlJoin(Url.Users, attachment.deletedByUserId!.toString()), {
        staleTime: 1000 * 60,
    });

    useEffect(() => {
        if (typeof user !== "undefined") {
            const userName = user.firstName + " " + user.surname;
            setUserName(userName);
        }
    }, [user]);

    /**
     * Shows the deletion note, if one is present, which explains why the attachment was deleted.
     * Also shows the username of the user that deleted the attachment.
     */
    return (
        <div className="attachments__tooltip">
            <SpinnerUntil<string> data={userName} isReady={typeof userName === "string"}>
                {(userName) => (
                    <div>
                        {attachment.deletionNote && (
                            <>
                                <h4>Deletion Note</h4>
                                <div>{attachment.deletionNote}</div>
                            </>
                        )}

                        <h4>Deleted By</h4>
                        <div>{userName}</div>
                    </div>
                )}
            </SpinnerUntil>
        </div>
    );
};

export default AttachmentTooltipContent;
