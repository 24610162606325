import { useState, useEffect } from "react";
import { IChatEvent, IMessage } from "@edgetier/types";
import { useSelector } from "react-redux";

import { connectSocket, getSocket } from "redux/modules/chat/socket";
import ChatEvent from "redux/modules/chat/chat-event";
import { IApplicationState } from "redux/types";

/**
 * A hook to establish a socket connection to an ongoing chat and listen for new messages.
 * @param chatId        The ID of the chat that you are joining as an observer.
 * @param initialEvents The events that have already happened before joining as an observer.
 * @returns An array of events that updates when a new chat arrives.
 */
const useChatObserver = (chatId: number, initialEvents: IChatEvent[]) => {
    const isAgentHandlingChat = useSelector<IApplicationState, boolean>(({ chat }) => {
        const chatIsActive = Object.values(chat.chats).reduce((activeChatFound, chat) => {
            return activeChatFound || chat.chatId === chatId;
        }, false);

        return chatIsActive;
    });

    const [observedEvents, setObservedEvents] = useState<IChatEvent[]>([]);

    const onMessage = (message: IMessage) => {
        setObservedEvents((events) => [...events, message]);
    };

    useEffect(() => {
        const options = { chatId };
        (async () => {
            const socket = await getSocket();
            if (!isAgentHandlingChat) {
                connectSocket();
                socket.emit(ChatEvent.JoinAsObserver, options);
                socket.on(ChatEvent.ChatMessage, onMessage);
            }
        })();

        return () => {
            (async () => {
                const socket = await getSocket();
                socket.emit(ChatEvent.LeaveAsObserver, options);
                socket.off(ChatEvent.ChatMessage, onMessage);
            })();
        };
    }, [chatId, isAgentHandlingChat]);

    return [...initialEvents, ...observedEvents];
};

export default useChatObserver;
