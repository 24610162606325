import TextArea from "react-textarea-autosize";
import { Field, FieldProps } from "formik";
import { FunctionComponent, memo } from "react";
import { IProps } from "./redact-form-field.types";
import { onKeyPress } from "./react-form-field.utilities";
import "./redact-form-field.scss";

/**
 * Text area to edit a message or email.
 * @param props.name Field name.
 * @returns          Text area and maybe a label.
 */
const RedactFormField: FunctionComponent<IProps> = ({ name }) => {
    return (
        <div className="field">
            <Field name={name}>
                {({ field, form }: FieldProps) => (
                    <TextArea
                        autoComplete="off"
                        id={name}
                        maxRows={7}
                        minRows={1}
                        name={field.name}
                        onBlur={form.handleBlur}
                        onKeyPress={onKeyPress}
                        onChange={form.handleChange}
                        value={field.value}
                    />
                )}
            </Field>
        </div>
    );
};

export default memo(RedactFormField);
