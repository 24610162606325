import { faHome } from "@fortawesome/free-solid-svg-icons/faHome";
import View from "constants/view";
import { NavigationLink } from "@edgetier/client-components";

/**
 * Link to the agent home page.
 * @returns A link to the page.
 */
const AgentHomeLink = () => <NavigationLink label="Home" icon={faHome} page={View.AgentHome} />;

export default AgentHomeLink;
