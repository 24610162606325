import React, { FunctionComponent, memo, useContext } from "react";

import { ModalContext } from "../modal-context";

import CloseButton from "./close-button";
import { IProps } from "./modal-header.types";
import "./modal-header.scss";

/**
 * The header of the modal which includes the button to close the modal.
 * @param props.children The components to be placed in the header of the modal.
 */
const ModalHeader: FunctionComponent<IProps> = ({ children }) => {
    const { canClose } = useContext(ModalContext);
    return (
        <div className="modal-header">
            <div className="modal-header__content">{children}</div>
            {canClose && <CloseButton />}
        </div>
    );
};

export default memo(ModalHeader);
