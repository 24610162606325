import { camelizeKeys } from "humps";

import { saveAuthenticationDetails } from "utilities/local-storage";

/**
 * Save the access token and user ID sent by the server.
 * @param headers Headers from the server than may contain an access token and user ID.
 */
export default function (headers: { [index: string]: string }): void {
    if (typeof headers !== "undefined") {
        const cleanHeaders = camelizeKeys(headers) as { [index: string]: string };
        if (typeof cleanHeaders.accessToken !== "undefined") {
            saveAuthenticationDetails(cleanHeaders.accessToken, cleanHeaders.uid);
        }
    }
}
