import React, { Fragment, FunctionComponent, memo, useMemo } from "react";
import { Arrow, DEFAULT_OPTIONS, useHover, useLayer } from "react-laag";
import classNames from "classnames";
import ResizeObserver from "resize-observer-polyfill";

import { IProps } from "./tooltip.types";
import { isDOMTypeElement } from "./tooltip.utilities";
import { doNothing } from "@edgetier/utilities";

/**
 * A tooltip that positions itself automatically and opens when the mouse enters the trigger.
 * @param props.children           The element that triggers the tooltip.
 * @param props.className          A classname that will be applied to the tooltip content.
 * @param props.content            The content of the tooltip.
 * @param props.delayEnter         A delay in milliseconds before the tooltip displays.
 * @param props.delayLeave         A delay in milliseconds before the tooltip disappears.
 * @param props.disableTooltip     Will disabled the tooltip when true.
 * @param props.isUnstyled         Boolean indicating if tooltip should be unstyled.
 * @param props.placement          An optional placement for suggesting where to position the menu.
 * @param props.possiblePlacements An optional array of possible positions that the tooltip can appear about the trigger.
 * @param props.useArrow           If true, an arrow will appear from the tooltip pointing to the trigger.
 */
const Tooltip: FunctionComponent<IProps> = ({
    children,
    className,
    container,
    content,
    delayEnter = 0,
    delayLeave = 0,
    disableTooltip = false,
    isUnstyled,
    placement = DEFAULT_OPTIONS.placement,
    possiblePlacements = DEFAULT_OPTIONS.possiblePlacements,
    triggerOffset,
    useArrow = false,
}) => {
    const [isOver, hoverProps] = useHover({ delayEnter, delayLeave });
    const defaultTriggerOffset = useMemo(() => (useArrow ? 12 : 5), [useArrow]);

    const { arrowProps, layerProps, renderLayer, triggerProps } = useLayer({
        auto: true,
        container,
        isOpen: !disableTooltip && isOver,
        placement,
        onParentClose: doNothing,
        possiblePlacements,
        ResizeObserver,
        triggerOffset: triggerOffset ?? defaultTriggerOffset,
    });

    const tooltip = (
        <div
            className={classNames(className, {
                tooltip__content: !isUnstyled,
            })}
            role="tooltip"
            {...layerProps}
        >
            {useArrow && (
                <div className="tooltip__arrow">
                    <Arrow {...arrowProps} size={6} role="img" />
                </div>
            )}
            {content}
        </div>
    );

    const propsObject = useMemo(() => {
        return disableTooltip ? {} : { ...triggerProps, ...hoverProps };
    }, [disableTooltip, hoverProps, triggerProps]);

    const trigger = isDOMTypeElement(children) ? (
        React.cloneElement(children, propsObject)
    ) : (
        <span {...propsObject}>{children}</span>
    );

    return (
        <Fragment>
            {renderLayer(!disableTooltip && isOver && tooltip)}
            {trigger}
        </Fragment>
    );
};

export default memo(Tooltip);
