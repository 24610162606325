import { FunctionComponent } from "react";
import { useSelector } from "react-redux";

import makeClass from "utilities/make-class";
import { IApplicationState } from "redux/types";
import "./loading-blocker.scss";
import { IProps } from "./loading-blocker.types";

/**
 * Render a container that may apply a class that will block the screen during loading.
 * @param props Props that will determine whether the block should display or not.
 * @returns     A container element with an optional CSS class.
 */
export const LoadingBlocker: FunctionComponent<IProps> = ({ children }) => {
    const isLoading = useSelector<IApplicationState, boolean>((state) => state.loadingBlocker.isLoading);
    const className = makeClass({ loadingBlocker: { blocked: isLoading } });
    return <div className={className}>{children}</div>;
};

export default LoadingBlocker;
