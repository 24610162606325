import { ITranslations } from "@edgetier/types";

type IPartialTranslations = { [index: number]: string | null };

/**
 * Format translations into a dictionary required by the backend. Chat messages, email subject lines, and email body
 * content can be translated between two languages when an agent is talking to a customer. The translation usually
 * happens on the backend, however, agents have the option of previewing and/or editing a translation before it is sent.
 * If they have previewed/edited the translation this is sent along with the original message in the agent's language.
 *
 * @param isTranslated Whether or not the user has translated the value.
 * @param translations Dictionary of language ID to translation value.
 * @returns            Translations dictionary.
 */
export function createTranslations(isTranslated: boolean, ...translations: IPartialTranslations[]): ITranslations {
    // If the value is not being translated, only the main language should be included.
    const validTranslations = isTranslated ? translations : [translations[0]];
    const preferredLanguageId = parseInt(Object.keys(translations[0])[0], 10);

    return validTranslations.reduce<ITranslations>((results, translation) => {
        const languageId = parseInt(Object.keys(translation)[0], 10);
        const value = Object.values(translation)[0];
        return {
            ...results,
            [languageId]: {
                fromLanguageId: preferredLanguageId,
                toLanguageId: languageId,
                isFirst: languageId !== preferredLanguageId,
                isOriginal: languageId === preferredLanguageId,
                isEdited: languageId !== preferredLanguageId,
                translation: typeof value !== "string" ? null : value.trim(),
            },
        };
    }, {});
}
