import urlJoin from "url-join";
import { AxiosRequestConfig } from "axios";
import { Url } from "@edgetier/types";

import axios from "utilities/axios";
import { IUser } from "redux/application.types";

/**
 * Request the user details of someone that deferred an email. Not all emails are deferred, so this function will
 * resolve with a null in that case.
 * @param userId        User ID of the agent that deferred an email, if any.
 * @param configuration Axios configuration for cancelling requests.
 * @returns             Promise resolving with a user's details or null.
 */
export async function getDeferralUser(userId: number | null, configuration: AxiosRequestConfig = {}) {
    if (typeof userId === "number") {
        try {
            const response = await axios.get<IUser>(urlJoin(Url.Users, userId.toString()), configuration);
            return response.data;
        } catch {
            return null;
        }
    } else {
        return null;
    }
}
