import { IChatState } from "redux/modules/chat/chat.types";

import actions from "./../chat-actions";

/**
 * Sign the agent in and out of chat.
 * @param state  Application state before changing the chat status.
 * @param action Action with a payload indicating if the agent should have chat enabled or not.
 * @returns      State with the new chat status.
 */
export default function (state: IChatState, action: ReturnType<typeof actions.toggleChat>): IChatState {
    return { ...state, enabled: action.payload };
}
