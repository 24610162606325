import { useState, useContext } from "react";
import urljoin from "url-join";
import { Formik, Form, Field, FieldProps } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { Url } from "@edgetier/types";
import classnames from "classnames";

import DeleteButton from "components/delete-button";
import AttachmentsContext from "shared/attachments/attachments-context";
import { getUserId } from "utilities/local-storage";

import { IProps } from "./delete-attachment-button.types";
import "./delete-attachment-button.scss";

/**
 * Renders a delete button next to delete an attachment which when clicked opens a modal with an optional note that the
 * user can use to explain why they are deleting the attachment.
 * @param props.attachment The attachment next to which the delete button will be rendered.
 */
const DeleteAttachmentButton = ({ attachment, disabled = false }: IProps) => {
    const [deletionNote, setDeletionNote] = useState("");

    // This function will update the display to show the user that the attachment has been deleted.
    const { deleteAttachment: deleteAttachmentInState } = useContext(AttachmentsContext);

    return (
        <DeleteButton
            className={classnames("delete-attachment-button button--no-modal", {
                "delete-attachment-button__disabled": disabled,
            })}
            disabled={disabled}
            url={disabled ? "" : urljoin(Url.Attachments, attachment.attachmentId.toString())}
            onDelete={() => deleteAttachmentInState(attachment, deletionNote, getUserId()!)}
            styleName={undefined}
            isUnstyled
        >
            {(deleteAttachment) => (
                <>
                    <h2>Delete Attachment</h2>
                    <p>Are you sure you want to delete "{attachment.attachment}"? </p>
                    <Formik<{ note: string }>
                        initialValues={{ note: "" }}
                        onSubmit={(values) => {
                            deleteAttachment({ deletionNote: values.note === "" ? null : values.note });
                            setDeletionNote(values.note);
                        }}
                    >
                        <Form>
                            <div className="field">
                                <label htmlFor="note">Note</label>
                                <Field name="note">
                                    {({ field }: FieldProps) => (
                                        <textarea
                                            {...field}
                                            autoFocus={true}
                                            placeholder="Reason for deletion&hellip;"
                                            name={field.name}
                                        />
                                    )}
                                </Field>
                            </div>
                            <button className="button button--negative" type="submit">
                                <FontAwesomeIcon icon={faTrash} />
                                Delete
                            </button>
                        </Form>
                    </Formik>
                </>
            )}
        </DeleteButton>
    );
};

export default DeleteAttachmentButton;
