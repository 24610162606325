enum HandlingType {
    Manual = 1,
    AutomaticWrapUp = 2,
    NoActionRequired = 3,
    Abandoned = 4,
    Unclaimed = 5,
    Expired = 6,
    System = 7,

    // Alias for unfriendly names.
    "Handled By Agent" = Manual,
}

export default HandlingType;
