import urlJoin from "url-join";
import { AxiosError } from "axios";
import { keyById } from "@edgetier/utilities";
import { IPaginated, Url } from "@edgetier/types";
import { parseISO } from "date-fns";
import { UseQueryOptions, useQuery } from "react-query";
import { useDispatch } from "react-redux";

import axios from "utilities/axios";
import { IBackendNote } from "redux/application.types";
import { toastOperations } from "redux/modules/toast";

import { IQueryNotesQueryCache } from "./use-query-notes.types";
import { DEFAULT_NOTE_LIMIT, ERROR_TITLE } from "./use-query-notes.constants";

/**
 * Get all notes for the current query from the backend.
 * @param url Start URL to get notes.
 */
const requestNotes = async (url: string) => {
    const configuration = { params: { limit: DEFAULT_NOTE_LIMIT } };
    const { data } = await axios.get<IPaginated<IBackendNote>>(url, configuration);
    const formattedNotes = data.items.map(({ dateTime, ...rest }) => ({ ...rest, dateTime: parseISO(dateTime) }));
    return keyById(formattedNotes, ({ noteId }) => noteId);
};

/**
 * Get notes for a given query.
 * @param queryId Notes belong to queries. Get the notes for this one.
 * @returns       React Query result.
 */
const useQueryNotes = (
    queryId: number,
    configuration: UseQueryOptions<IQueryNotesQueryCache, AxiosError<void>> = {}
) => {
    const dispatch = useDispatch();
    const notesUrl = urlJoin(Url.Query, queryId.toString(), Url.Notes);
    return useQuery<IQueryNotesQueryCache, AxiosError<void>>(notesUrl, () => requestNotes(notesUrl), {
        onError: (serverError: AxiosError<void>) => {
            dispatch(toastOperations.showServerErrorToast(ERROR_TITLE, serverError));
        },
        ...configuration,
    });
};

export default useQueryNotes;
