import ColumnContext from "../column-context";
import Heading from "../heading";
import React, { Children, Fragment, FunctionComponent, isValidElement } from "react";
import { IProps } from "./column.types";

/**
 * Table column definition. It renders differently depending on whether it's in the table body or header.
 * @returns Children.
 */
const Column: FunctionComponent<IProps> = ({ children }) => {
    const { isBody } = React.useContext(ColumnContext);
    return (
        <Fragment>
            {Children.toArray(children).filter(
                (child) =>
                    isValidElement(child) && ((!isBody && child.type === Heading) || (isBody && child.type !== Heading))
            )}
        </Fragment>
    );
};

export default Column;
